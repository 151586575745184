package com.taager.checkout.payment

data class PaymentForm(
    val currency: String,
    val amount: Long,
    val description: String,
    val storeName: String,
    val metadata: Metadata,
    val methods: List<String>,
) {
    data class Metadata(
        val orderLines: List<OrderLine>,
        val shippingCost: Double,
        val customer: Customer,
        val orderReceivedBy: String,
    ) {

        data class OrderLine(
            val sku: String,
            val quantity: Int,
            val totalPrice: Double,
        )

        data class Customer(
            val fullName: String,
            val address1: String,
            val provinceId: String,
            val zoneId: String,
            val districtId: String?,
            val phoneNumber: String,
            val alternativePhoneNumber: String?,
            val email: String?,
            val notes: String?,
        )
    }
}
