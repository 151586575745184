package com.taager.dukan.checkout.data.remote

import com.taager.dukan.checkout.data.remote.request.CalculateCostRequest
import com.taager.dukan.checkout.data.remote.response.CalculateCostResponse
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody

private const val CHECKOUT_PATH = "api/checkout"
private const val CALCULATE_COST_PATH = "$CHECKOUT_PATH/calculate-cost"

internal class CheckoutApi(
    private val client: HttpClient,
) {

    suspend fun calculateCost(request: CalculateCostRequest): CalculateCostResponse =
        client.post(CALCULATE_COST_PATH) {
            setBody(request)
        }.body()
}
