package com.taager.dukan.customer.data.local.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class CustomerModel(
    @SerialName("fullName")
    val fullName: String,
    @SerialName("phoneNumber")
    val phoneNumber: String,
    @SerialName("alternativePhoneNumber")
    val alternativePhoneNumber: String?,
    @SerialName("email")
    val email: String?,
    @SerialName("province")
    val province: ProvinceModel,
    @SerialName("city")
    val city: CityModel,
    @SerialName("district")
    val district: DistrictModel?,
    @SerialName("address")
    val address: String,
)
