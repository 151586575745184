package com.taager.dukan.feature.checkout

import com.taager.dukan.ui.theme.useTheme
import mui.material.Paper
import mui.material.PaperVariant
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import web.cssom.JustifyContent
import web.cssom.Percentage
import web.cssom.pct

internal val CheckoutLoading: FC<Props> = FC {
    val theme = useTheme()
    Stack {
        direction = responsive(Breakpoint.xs to StackDirection.column, Breakpoint.sm to StackDirection.row)
        spacing = responsive(2)
        Paper {
            sx {
                width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
                padding = theme.spacing(2)
            }
            variant = PaperVariant.outlined
            Stack {
                FieldSkeleton {
                    quantity = 2
                }
                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(1)
                    FieldSkeleton {
                        quantity = 2
                        width = 50.pct
                    }
                }
                FieldSkeleton {
                    quantity = 2
                }
                Skeleton {
                    sx {
                        marginTop = theme.spacing(1)
                    }
                    height = theme.spacing(10)
                    width = 100.pct
                    variant = SkeletonVariant.rounded
                }
            }
        }
        Stack {
            sx {
                width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
                justifyContent = JustifyContent.spaceBetween
            }
            Stack {
                Paper {
                    sx {
                        padding = theme.spacing(2)
                    }
                    variant = PaperVariant.outlined
                    @Suppress("UnusedPrivateMember")
                    for (i in 1..3) {
                        Stack {
                            direction = responsive(StackDirection.row)
                            spacing = responsive(1)
                            FieldSkeleton {
                                quantity = 2
                                width = 50.pct
                            }
                        }
                    }
                }
                FieldSkeleton {
                    sx {
                        marginTop = theme.spacing(1)
                    }
                    quantity = 1
                }
            }
            Skeleton {
                width = 100.pct
                variant = SkeletonVariant.rounded
            }
        }
    }
}

private external interface FieldSkeletonProps : PropsWithSx {
    var quantity: Int
    var width: Percentage?
}

private val FieldSkeleton: FC<FieldSkeletonProps> = FC { props ->
    @Suppress("UnusedPrivateMember")
    for (i in 1..props.quantity) {
        Skeleton {
            sx = props.sx
            width = props.width ?: 100.pct
            variant = SkeletonVariant.text
        }
    }
}
