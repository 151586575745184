package com.taager.dukan.customer.domain.interactor

import com.taager.dukan.customer.domain.CustomerRepository
import com.taager.dukan.customer.domain.entity.District
import com.taager.dukan.store.domain.interactor.GetStoreUseCase

class GetDistrictsUseCase(
    private val getStore: GetStoreUseCase,
    private val repository: CustomerRepository,
) {

    suspend operator fun invoke(cityId: String): List<District> =
        repository.getDistricts(
            country = getStore().country,
            cityId = cityId
        )
}
