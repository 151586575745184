package com.taager.dukan.product.data.remote.response

import com.taager.translator.TranslatableString
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class ProductDto(
    @SerialName("sku")
    val sku: String,
    @SerialName("name")
    val name: TranslatableString,
    @SerialName("description")
    val description: TranslatableString,
    @SerialName("images")
    val images: List<String>,
    @SerialName("availability")
    val availability: Availability,
    @SerialName("pricingOptions")
    val pricingOptions: List<PricingOption>,
) {

    @Serializable
    enum class Availability {
        @SerialName("in_stock")
        InStock,

        @SerialName("out_of_stock")
        OutOfStock,
    }

    @Serializable
    data class PricingOption(
        @SerialName("name")
        val name: TranslatableString,
        @SerialName("description")
        val description: TranslatableString? = null,
        @SerialName("quantity")
        val quantity: Int,
        @SerialName("totalPrice")
        val totalPrice: Double,
        @SerialName("isDefault")
        val isDefault: Boolean,
    )
}
