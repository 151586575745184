package com.taager.dukan.store.domain.tracking

import com.taager.dukan.store.domain.entity.Store
import com.taager.tracking.AppTracker

internal fun AppTracker.trackStore(
    store: Store,
) {
    setEventProperty(
        key = "store_name",
        value = store.name
    )
    setEventProperty(
        key = "is_express_checkout_enabled",
        value = store.isExpressCheckoutEnabled.toString()
    )
    store.integrations?.tiktokPixel?.let(::integrate)
    store.integrations?.gTag?.let(::integrate)
}

private fun AppTracker.integrate(tiktokPixel: Store.Integrations.Pixel.Tiktok) {
    if (tiktokPixel.ids.isNotEmpty()) {
        setEventProperty(
            key = "tiktok_pixel_ids",
            value = tiktokPixel.ids.joinToString(separator = ",")
        )
    }
}

private fun AppTracker.integrate(gTag: Store.Integrations.Pixel.GTag) {
    if (gTag.ids.isNotEmpty()) {
        setEventProperty(
            key = "gTag_pixel_ids",
            value = gTag.ids.joinToString(separator = ",")
        )
    }
}
