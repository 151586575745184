package com.taager.dukan.customer.data.local.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class CityModel(
    @SerialName("id")
    val id: String,
    @SerialName("name")
    val name: String,
    @SerialName("province")
    val province: ProvinceModel,
    @SerialName("districts")
    val districts: List<DistrictModel>,
)
