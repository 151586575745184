package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.InputField
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.feature.productdetails.mapper.initialLoadedState
import com.taager.dukan.feature.productdetails.tracking.trackBackClick
import com.taager.dukan.feature.productdetails.tracking.trackExitFullscreenClick
import com.taager.dukan.feature.productdetails.tracking.trackExitFullscreenKeyDown
import com.taager.dukan.feature.productdetails.tracking.trackFullscreenImageClick
import com.taager.dukan.feature.productdetails.tracking.trackMediaClick
import com.taager.dukan.feature.productdetails.tracking.trackOrderNowClick
import com.taager.dukan.feature.productdetails.tracking.trackPricingOptionClick
import com.taager.dukan.feature.productdetails.tracking.trackScreenView
import com.taager.dukan.feature.productdetails.tracking.trackViewContent
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.product.domain.interactor.GetProductUseCase
import com.taager.tracking.AppTracker
import kotlinx.coroutines.launch

class ProductDetailsPresenter(
    private val appTracker: AppTracker,
    private val getProduct: GetProductUseCase,
    private val expressCheckoutHandler: ExpressCheckoutHandler,
) : ViewStateHandler<ProductDetailsViewState, ProductDetailsSideEffect>(ProductDetailsViewState.Initial),
    ViewEventHandler<ProductDetailsViewEvent> {

    private lateinit var sku: String

    @Suppress("LongMethod", "CyclomaticComplexMethod")
    override fun onEvent(event: ProductDetailsViewEvent) {
        when (event) {
            is ProductDetailsViewEvent.Init -> {
                appTracker.trackScreenView(event.sku)
                onInit(event.sku)
            }

            is ProductDetailsViewEvent.MediaClick -> {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                val selectedMedia = loadedState.product.medias[event.index]
                appTracker.trackMediaClick(sku, media = selectedMedia)
                loadedState.onMediaClick(
                    index = event.index,
                    media = selectedMedia
                )
            }

            is ProductDetailsViewEvent.OrderNowClick -> {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                appTracker.trackOrderNowClick(sku, loadedState.product.selectedPricingOption.quantity)
                onOrderNowClick()
            }

            ProductDetailsViewEvent.BackClick -> {
                appTracker.trackBackClick(sku)
                tryEmitEffect(ProductDetailsSideEffect.GoToHome)
            }

            is ProductDetailsViewEvent.FullscreenImageClick -> {
                appTracker.trackFullscreenImageClick(sku, image = event.url)
                val loadedState = state.value as ProductDetailsViewState.Loaded
                updateState(newState = loadedState.copy(fullscreenImage = event.url))
            }

            ProductDetailsViewEvent.ExitFullscreenClick -> {
                appTracker.trackExitFullscreenClick(sku)
                val loadedState = state.value as ProductDetailsViewState.Loaded
                updateState(newState = loadedState.copy(fullscreenImage = null))
            }

            ProductDetailsViewEvent.ExitFullscreenKeyDown -> {
                appTracker.trackExitFullscreenKeyDown(sku)
                val loadedState = state.value as ProductDetailsViewState.Loaded
                updateState(newState = loadedState.copy(fullscreenImage = null))
            }

            is ProductDetailsViewEvent.UpdateCheckoutField -> {
                onUpdateCheckoutField(
                    inputField = event.inputField,
                    newValue = event.newValue
                )
            }

            is ProductDetailsViewEvent.QueryCities -> {
                expressCheckoutHandler.onQueryCities(query = event.query)
            }

            is ProductDetailsViewEvent.CaptchaResponse -> {
                expressCheckoutHandler.onCaptchaResponse(token = event.token)
            }

            ProductDetailsViewEvent.CaptchaError -> {
                expressCheckoutHandler.onCaptchaError()
                tryEmitEffect(ProductDetailsSideEffect.ShowInternetConnectionError)
            }

            ProductDetailsViewEvent.ProceedWithCheckout -> {
                onProceedWithCheckout()
            }

            ProductDetailsViewEvent.CheckoutFieldFocus -> {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                val quantity = loadedState.product.selectedPricingOption.quantity
                expressCheckoutHandler.onFieldFocus(quantity)
            }

            is ProductDetailsViewEvent.PricingOptionClick -> {
                val quantity = event.pricingOption.quantity
                appTracker.trackPricingOptionClick(sku, quantity)
                onPricingOptionSelect(quantity)
            }

            is ProductDetailsViewEvent.PaymentMethodClick -> {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                onPaymentMethodClick(loadedState, event.paymentMethod)
            }

            ProductDetailsViewEvent.PaymentFormCompleted -> {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                val quantity = loadedState.product.selectedPricingOption.quantity
                val paymentMethod = requireNotNull(loadedState.expressCheckout?.paymentMethod)
                expressCheckoutHandler.onAddPaymentInfo(
                    quantity = quantity,
                    paymentMethod = paymentMethod,
                )
            }

            ProductDetailsViewEvent.ClosePaymentForm -> {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                updateState(
                    newState = loadedState.copy(
                        expressCheckout = loadedState.expressCheckout?.copy(
                            paymentFormVisibility = PaymentFormVisibility.Hidden,
                        )
                    )
                )
            }
        }
    }

    private fun onInit(sku: String) {
        this.sku = sku
        scope.launch {
            try {
                val product = getProduct(sku)
                val expressCheckout = expressCheckoutInit(product)
                updateState(
                    newState = if (expressCheckout != null) {
                        initialLoadedState(
                            product = product,
                            expressCheckout = expressCheckout
                        )
                    } else {
                        initialLoadedState(
                            product = product
                        )
                    },
                )
                appTracker.trackViewContent(product)
            } catch (error: Throwable) {
                appTracker.trackError(error)
                updateState(
                    newState = ProductDetailsViewState.Error,
                )
            }
        }
    }

    private suspend fun expressCheckoutInit(product: Product): ProductDetailsViewState.ExpressCheckout? =
        expressCheckoutHandler.onInit(
            scope = scope,
            product = product,
            onStartQueryingCity = {
                val loadedState = state.value as ProductDetailsViewState.Loaded
                val expressCheckout = requireNotNull(loadedState.expressCheckout)
                updateState(
                    newState = loadedState.copy(
                        expressCheckout = expressCheckout.copy(
                            form = expressCheckout.form.copy(
                                city = expressCheckout.form.city.copy(
                                    isLoading = true,
                                    items = emptyList()
                                )
                            )
                        )
                    )
                )
            },
            onCityQueryResult = { cities ->
                val loadedState = state.value as ProductDetailsViewState.Loaded
                val expressCheckout = requireNotNull(loadedState.expressCheckout)
                updateState(
                    newState = loadedState.copy(
                        expressCheckout = expressCheckout.copy(
                            form = expressCheckout.form.copy(
                                city = expressCheckout.form.city.copy(
                                    isLoading = false,
                                    items = cities
                                )
                            )
                        )
                    )
                )
            },
            onCityQueryError = {
                tryEmitEffect(ProductDetailsSideEffect.ShowInternetConnectionError)
            },
        )

    private fun ProductDetailsViewState.Loaded.onMediaClick(
        index: Int,
        media: Product.Media,
    ) {
        updateState(
            newState = copy(
                selectedImageIndex = index,
                isFullscreenButtonVisible = media is Product.Media.Image,
            )
        )
    }

    private fun onUpdateCheckoutField(
        inputField: InputField<*>,
        newValue: Any?
    ) {
        val loadedState = state.value as ProductDetailsViewState.Loaded
        expressCheckoutHandler.onUpdateField(
            checkoutForm = requireNotNull(loadedState.expressCheckout).form,
            inputField = inputField,
            newValue = newValue,
            onUpdated = { updatedCheckoutForm ->
                with(state.value as ProductDetailsViewState.Loaded) {
                    val expressCheckout = requireNotNull(expressCheckout)
                    updateState(
                        newState = loadedState.copy(
                            expressCheckout = expressCheckout.copy(
                                form = updatedCheckoutForm,
                            ),
                        )
                    )
                }
            },
            onDistrictsUpdated = { districts, selectedValue ->
                with(state.value as ProductDetailsViewState.Loaded) {
                    val expressCheckout = requireNotNull(expressCheckout)
                    updateState(
                        newState = loadedState.copy(
                            expressCheckout = expressCheckout.copy(
                                form = expressCheckout.form.copy(
                                    district = expressCheckout.form.district?.copy(
                                        value = selectedValue,
                                        items = districts
                                    )
                                ),
                            ),
                        )
                    )
                }
            },
            onDistrictsError = {
                tryEmitEffect(ProductDetailsSideEffect.ShowInternetConnectionError)
            }
        )
    }

    private fun onOrderNowClick() {
        val loadedState = state.value as ProductDetailsViewState.Loaded
        val expressCheckout = loadedState.expressCheckout
        val quantity = loadedState.product.selectedPricingOption.quantity
        expressCheckoutHandler.onOrderNowClick(
            quantity = quantity,
            expressCheckout = expressCheckout,
            goToCheckout = {
                tryEmitEffect(
                    ProductDetailsSideEffect.GoToCheckout(
                        sku = sku,
                        quantity = quantity
                    )
                )
            },
            focusCheckoutField = { inputField ->
                tryEmitEffect(
                    ProductDetailsSideEffect.FocusCheckoutField(
                        inputField = inputField
                    )
                )
            },
            executeCaptcha = {
                tryEmitEffect(ProductDetailsSideEffect.ExecuteCaptcha)
            },
            showPaymentForm = { paymentFormVisibility ->
                updateState(
                    newState = loadedState.copy(
                        expressCheckout = expressCheckout?.copy(
                            paymentFormVisibility = paymentFormVisibility,
                        )
                    )
                )
            },
        )
    }

    private fun onProceedWithCheckout() {
        val loadedState = state.value as ProductDetailsViewState.Loaded
        val expressCheckout = requireNotNull(loadedState.expressCheckout)
        expressCheckoutHandler.onProceedWithCheckout(
            quantity = loadedState.product.selectedPricingOption.quantity,
            checkoutForm = expressCheckout.form,
            onLoading = {
                updateState(
                    newState = loadedState.copy(
                        expressCheckout = expressCheckout.copy(
                            isPlacingOrder = true,
                        )
                    )
                )
            },
            onSuccess = {
                tryEmitEffect(ProductDetailsSideEffect.GoToCheckoutSuccess)
            },
            onError = {
                updateState(
                    newState = loadedState.copy(
                        expressCheckout = expressCheckout.copy(
                            isPlacingOrder = false,
                        )
                    )
                )
                tryEmitEffect(ProductDetailsSideEffect.ShowPlaceOrderError)
            },
        )
    }

    private fun onPricingOptionSelect(quantity: Int) {
        val loadedState = state.value as ProductDetailsViewState.Loaded
        val product = loadedState.product
        updateState(
            newState = loadedState.copy(
                product = product.copy(
                    pricingOptions = product.pricingOptions.map { pricingOption ->
                        pricingOption.copy(isSelected = pricingOption.quantity == quantity)
                    }
                )
            )
        )
    }

    private fun onPaymentMethodClick(
        loadedState: ProductDetailsViewState.Loaded,
        paymentMethod: PaymentMethod
    ) {
        val quantity = loadedState.product.selectedPricingOption.quantity
        val expressCheckout = requireNotNull(loadedState.expressCheckout)
        expressCheckoutHandler.onPaymentMethodClick(paymentMethod, quantity)
        updateState(
            newState = loadedState.copy(
                expressCheckout = expressCheckout.copy(
                    paymentMethod = paymentMethod,
                    hasPaymentMethodError = false,
                )
            )
        )
    }
}
