package com.taager.dukan.store.data.local.mapper

import com.taager.country.CountryResolver
import com.taager.dukan.store.data.local.model.QuickLinkModel
import com.taager.dukan.store.data.local.model.StoreModel
import com.taager.dukan.store.domain.entity.QuickLink
import com.taager.dukan.store.domain.entity.Store
import com.taager.numbers.toMoney
import com.taager.translator.Translator

internal fun Store.transform(): StoreModel =
    StoreModel(
        name = name,
        countryIsoCode3 = country.isoCode3,
        contactInfo = contactInfo?.transform(),
        hasEmbeddedCheckoutEnabled = isExpressCheckoutEnabled,
        integrations = integrations?.transform(),
        payments = StoreModel.Payments(
            isPrepaidEnabled = payments.isPrepaidEnabled,
            codFee = payments.codFee?.value,
        ),
    )

private fun Store.ContactInfo.transform(): StoreModel.ContactInfo =
    StoreModel.ContactInfo(
        email = email,
        phoneNumber = phoneNumber,
    )

private fun Store.Integrations.transform(): StoreModel.Integrations =
    StoreModel.Integrations(
        tiktokPixel = tiktokPixel?.transform(),
        gTag = gTag?.transform(),
    )

private fun Store.Integrations.Pixel.Tiktok.transform(): StoreModel.Integrations.TiktokPixel =
    StoreModel.Integrations.TiktokPixel(
        ids = ids,
    )

private fun Store.Integrations.Pixel.GTag.transform(): StoreModel.Integrations.GTagPixel =
    StoreModel.Integrations.GTagPixel(
        ids = ids,
    )

internal fun StoreModel.transform(): Store {
    val country = CountryResolver.retrieveMultitenancyEnabledCountriesFromIsoCode3(countryIsoCode3)
    return Store(
        name = name,
        country = country,
        contactInfo = contactInfo?.transform(),
        isExpressCheckoutEnabled = hasEmbeddedCheckoutEnabled,
        integrations = integrations?.transform(),
        payments = Store.Payments(
            isPrepaidEnabled = payments.isPrepaidEnabled,
            codFee = payments.codFee?.toMoney(country.currency),
        ),
    )
}

private fun StoreModel.ContactInfo.transform(): Store.ContactInfo =
    Store.ContactInfo(
        email = email,
        phoneNumber = phoneNumber,
    )

private fun StoreModel.Integrations.transform(): Store.Integrations =
    Store.Integrations(
        tiktokPixel = tiktokPixel?.transform(),
        gTag = gTag?.transform(),
    )

private fun StoreModel.Integrations.TiktokPixel.transform(): Store.Integrations.Pixel.Tiktok =
    Store.Integrations.Pixel.Tiktok(
        ids = ids,
    )

private fun StoreModel.Integrations.GTagPixel.transform(): Store.Integrations.Pixel.GTag =
    Store.Integrations.Pixel.GTag(
        ids = ids,
    )

internal fun QuickLinkModel.transform(
    translator: Translator,
    email: String?
): QuickLink =
    QuickLink(
        id = id,
        title = translator.translate(title),
        headline = translator.translate(headline),
        content = translator.translate(content)
            .replace("{email}", email ?: ""),
    )
