package com.taager.firebase.remoteconfig

import com.taager.firebase.di.isProduction
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.minutes

internal val FetchTimeoutInMillis: Long =
    1.minutes.inWholeMilliseconds

internal val MinimumFetchIntervalInMillis: Long
    get() = if (isProduction) {
        5.hours.inWholeMilliseconds
    } else {
        15.minutes.inWholeMilliseconds
    }
