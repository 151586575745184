package com.taager.dukan.customer.data

import com.taager.country.model.Country
import com.taager.dukan.customer.data.local.CustomerCache
import com.taager.dukan.customer.data.local.ProvinceCache
import com.taager.dukan.customer.data.local.mapper.transform
import com.taager.dukan.customer.data.remote.CustomerApi
import com.taager.dukan.customer.data.remote.mapper.transform
import com.taager.dukan.customer.domain.CustomerRepository
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.Customer
import com.taager.dukan.customer.domain.entity.District
import com.taager.dukan.customer.domain.entity.Province

internal class CustomerRepositoryImpl(
    private val provinceCache: ProvinceCache,
    private val customerCache: CustomerCache,
    private val api: CustomerApi,
) : CustomerRepository {

    override suspend fun queryCities(country: Country, query: String, resultLimit: Int): List<City> =
        if (query.isBlank()) {
            emptyList()
        } else {
            getProvinces(country)
                .queryCities(query, resultLimit)
        }

    override suspend fun getDistricts(country: Country, cityId: String): List<District> =
        getProvinces(country)
            .map { province ->
                province.copy(
                    cities = province.cities.filter { city -> city.id == cityId }
                )
            }
            .flatMap { province ->
                province.cities.flatMap(City::districts)
            }

    override suspend fun getCustomer(): Customer? =
        customerCache.get()?.transform()

    override suspend fun saveCustomer(customer: Customer) {
        customerCache.set(customer.transform())
    }

    private suspend fun getProvinces(country: Country): List<Province> =
        getProvincesFromCache(country.isoCode3) ?: fetchProvincesFromApi().also { provinces ->
            provinceCache[country.isoCode3] = provinces.transform()
        }

    private fun getProvincesFromCache(country: String): List<Province>? =
        provinceCache[country]?.transform()

    private suspend fun fetchProvincesFromApi(): List<Province> =
        api.getProvinces().transform()

    private fun List<Province>.queryCities(
        query: String,
        resultLimit: Int
    ): List<City> =
        asSequence()
            .map(Province::cities)
            .flatten()
            .sortedBy(City::name)
            .filter { city ->
                val searchableString = "${city.name}${city.province.name}".normalize()
                // Split the query into parts, so we can normalize and search for each part
                query.split(/* delimiters to ignore */ " ", ",", "-")
                    .filter(String::isNotBlank)
                    .map(String::normalize)
                    .all { queryPart ->
                        searchableString.contains(queryPart, ignoreCase = true)
                    }
            }
            .take(resultLimit)
            .toList()
}
