package com.taager.dukan.feature.productdetails.mapper

import com.taager.dukan.feature.productdetails.ProductDetailsViewState
import com.taager.dukan.product.domain.entity.Product

internal fun initialLoadedState(product: Product): ProductDetailsViewState.Loaded {
    val productModel = product.transform()
    val selectedImageIndex = 0
    val selectedMedia = productModel.medias[selectedImageIndex]
    return ProductDetailsViewState.Loaded(
        product = productModel,
        selectedImageIndex = selectedImageIndex,
        isOrderNowButtonEnabled = productModel.isAvailable,
        isFullscreenButtonVisible = selectedMedia is Product.Media.Image,
        fullscreenImage = null,
        expressCheckout = null
    )
}

private fun Product.transform(): ProductDetailsViewState.Product =
    ProductDetailsViewState.Product(
        sku = sku,
        name = name,
        description = description,
        medias = medias,
        availability = availability.transform(),
        pricingOptions = pricingOptions.transform(defaultPricingOption),
    )

private fun Product.Availability.transform(): ProductDetailsViewState.Product.Availability =
    when (this) {
        Product.Availability.InStock -> ProductDetailsViewState.Product.Availability.Available
        Product.Availability.OutOfStock -> ProductDetailsViewState.Product.Availability.SoldOut
    }

private val ProductDetailsViewState.Product.isAvailable: Boolean
    get() = availability == ProductDetailsViewState.Product.Availability.Available

private fun List<Product.PricingOption>.transform(
    defaultPricingOption: Product.PricingOption,
): List<ProductDetailsViewState.Product.PricingOption> =
    map { pricingOption ->
        val price = pricingOption.price
        ProductDetailsViewState.Product.PricingOption(
            name = pricingOption.name,
            description = pricingOption.description,
            quantity = pricingOption.quantity,
            price = price.toString(),
            internalPrice = price,
            isSelected = pricingOption == defaultPricingOption,
        )
    }
