package com.taager.dukan.feature.checkout.payment

import com.taager.dukan.ui.theme.useTheme
import mui.material.Stack
import mui.material.StackDirection
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.img
import web.cssom.Border
import web.cssom.LineStyle
import web.cssom.div
import web.cssom.px

internal val SupportedPaymentMethods: FC<PropsWithSx> = FC { props ->
    val theme = useTheme()
    Stack {
        sx = props.sx
        direction = responsive(StackDirection.row)
        spacing = responsive(1)

        img {
            src = "assets/images/payment_method_moyasar.svg"
        }

        Stack {
            sx {
                border = Border(1.px, LineStyle.solid, theme.palette.divider)
                borderRadius = theme.spacing(1) / 2
                padding = theme.spacing(1) / 2
            }
            direction = responsive(StackDirection.row)
            spacing = responsive(1)

            img {
                src = "assets/images/payment_method_amex.svg"
            }
            img {
                src = "assets/images/payment_method_mada_borderless.svg"
            }
            img {
                src = "assets/images/payment_method_visa_borderless.svg"
            }
            img {
                src = "assets/images/payment_method_mastercard_borderless.svg"
            }
        }
    }
}
