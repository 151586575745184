package com.taager.dukan.ui.component

import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.Button
import mui.material.ButtonColor
import mui.material.Size
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.create
import web.cssom.AlignSelf
import web.cssom.WhiteSpace

internal val AlertPanel: FC<AlertPanelProps> = FC { props ->
    Alert {
        severity = props.color
        AlertTitle {
            +props.title
        }
        Typography {
            sx {
                whiteSpace = WhiteSpace.preLine
            }
            variant = TypographyVariant.body2
            +props.message
        }
        props.actionText?.let { actionText ->
            action = Button.create {
                sx {
                    alignSelf = AlignSelf.center
                }
                color = ButtonColor.inherit
                size = Size.small
                +actionText
                onClick = { props.onActionClick?.invoke() }
            }
        }
    }
}

internal external interface AlertPanelProps : Props {
    var color: AlertColor
    var title: String
    var message: String
    var actionText: String?
    var onActionClick: (() -> Unit)?
}
