package com.taager.dukan.ui.component

import com.taager.checkout.field.InputField
import com.taager.checkout.validation.hasError
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.material.Divider
import mui.material.FormControlVariant
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputBaseProps
import mui.material.Orientation
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.material.Typography
import mui.system.responsive
import mui.system.sx
import react.FC
import react.ReactNode
import react.create
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.onChange
import web.cssom.Display
import web.cssom.FontWeight
import web.html.HTMLInputElement
import web.html.InputType

private const val MULTILINE_ROWS = 3

private val FormEvent<*>.value: String
    get() = unsafeCast<ChangeEvent<HTMLInputElement>>().target.value

internal val TextInput: FC<BaseInputProps> = FC { props ->
    val field = props.inputField
    TextField {
        sx = props.sx
        inputRef = props.inputRef
        variant = FormControlVariant.outlined
        id = field.id
        label = ReactNode(props.label)
        type = when (field.type) {
            InputField.Type.Phone -> InputType.tel
            InputField.Type.Text -> InputType.text
            InputField.Type.Email -> InputType.email
        }
        field.prefix?.let { prefix ->
            val theme = useTheme()
            asDynamic().InputProps = jso<InputBaseProps> {
                startAdornment = InputAdornment.create {
                    val adornmentColor = theme.palette.text.disabled
                    position = InputAdornmentPosition.start
                    Stack {
                        sx {
                            display = Display.flex
                        }
                        direction = responsive(StackDirection.row)

                        +Typography.create {
                            sx {
                                fontWeight = FontWeight.medium
                                color = adornmentColor
                                // Fix margin for RTL
                                marginInlineEnd = theme.spacing(1)
                            }
                            +prefix
                        }
                        Divider {
                            sx {
                                borderColor = adornmentColor
                            }
                            flexItem = true
                            orientation = Orientation.vertical
                        }
                    }
                }
            }
        }
        value = field.value
        placeholder = props.placeholder ?: field.placeholder
        required = field.isRequired
        multiline = field.multiline
        rows = if (field.multiline) {
            MULTILINE_ROWS
        } else {
            undefined
        }
        error = field.hasError
        onChange = { formEvent ->
            props.onChange(field, formEvent.value)
        }
        onFocus = {
            props.onFocus?.invoke(field)
        }
    }
}
