package com.taager.tracking.gtm

// Recommended events for GTag
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10793566741321053170-EU&client_type=gtag
internal sealed interface GoogleTagManagerEvent {
    val event: String
    val properties: Map<String, Any>

    data class Item(
        val id: String,
        val name: String,
        val price: Double,
        val quantity: Int,
    ) {

        val properties: Map<String, Any> = mapOf(
            "item_id" to id,
            "item_name" to name,
            "price" to price,
            "quantity" to quantity,
        )
    }

    data class ViewItemList(
        val items: List<Item>,
    ) : GoogleTagManagerEvent {
        override val event: String = "view_item_list"
        override val properties: Map<String, Any> = mapOf(
            "items" to items.map(Item::properties),
        )
    }

    data class ViewItem(
        val currency: String,
        val value: Double,
        val items: List<Item>,
    ) : GoogleTagManagerEvent {
        override val event: String = "view_item"
        override val properties: Map<String, Any> = mapOf(
            "currency" to currency,
            "value" to value,
            "items" to items.map(Item::properties),
        )
    }

    data class BeginCheckout(
        val currency: String,
        val value: Double,
        val items: List<Item>,
    ) : GoogleTagManagerEvent {
        override val event: String = "begin_checkout"
        override val properties: Map<String, Any> = mapOf(
            "currency" to currency,
            "value" to value,
            "items" to items.map(Item::properties),
        )
    }

    data class AddPaymentInfo(
        val currency: String,
        val value: Double,
        val paymentType: String,
        val items: List<Item>,
    ) : GoogleTagManagerEvent {
        override val event: String = "add_payment_info"
        override val properties: Map<String, Any> = mapOf(
            "currency" to currency,
            "value" to value,
            "payment_type" to paymentType,
            "items" to items.map(Item::properties),
        )
    }

    data class Purchase(
        val currency: String,
        val value: Double,
        val transactionId: String,
        val items: List<Item>,
    ) : GoogleTagManagerEvent {
        override val event: String = "purchase"
        override val properties: Map<String, Any> = mapOf(
            "currency" to currency,
            "value" to value,
            "transaction_id" to transactionId,
            "items" to items.map(Item::properties),
        )
    }
}
