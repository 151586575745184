package com.taager.api.di

import com.taager.api.SlugProvider
import com.taager.dukan.preferences.domain.interactor.GetPreferencesUseCase
import com.taager.experience.api.configuration.LanguageProvider
import com.taager.experience.api.configuration.model.ApiDomain
import com.taager.experience.api.di.ApiInjector
import com.taager.experience.api.exception.ApiExceptionMonitor
import com.taager.experience.data.exception.ApiException
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseErrorTrackingEvent
import io.ktor.client.HttpClient
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.request.header
import io.ktor.http.HttpHeaders
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.bindSingleton
import org.kodein.di.instance

object ApiDI {
    val module = DI.Module("API DI") {
        import(InternalApiDI.module)

        bindSingleton {
            instance<ApiInjector>()
                .client
                .withExtraHeaders(slugProvider = instance())
        }
        bindProvider {
            LanguageProvider(
                getPreferences = instance()
            )
        }
        bindProvider {
            ApiExceptionMonitor(
                appTracker = instance()
            )
        }
        bindProvider {
            ApiInjector(
                appInfo = instance(),
                apiDomain = ApiDomain.Dukan,
                // Instantiating here because we cannot pass external interface
                // for reified type parameter when using `instance()`
                languageProvider = LanguageProvider(getPreferences = instance()),
                apiExceptionMonitor = ApiExceptionMonitor(appTracker = instance()),
            )
        }
    }
}

private val HttpHeaders.Slug: String
    get() = "slug"

private fun HttpClient.withExtraHeaders(slugProvider: SlugProvider): HttpClient =
    config {
        defaultRequest {
            header(HttpHeaders.Slug, slugProvider.provideSlug())
        }
    }

private class LanguageProvider(
    private val getPreferences: GetPreferencesUseCase
) : LanguageProvider {
    override fun provideLanguage(): String =
        getPreferences().language.id
}

private class ApiExceptionMonitor(
    private val appTracker: AppTracker
) : ApiExceptionMonitor {
    override fun onException(cause: ApiException) {
        appTracker.trackApiException(cause)
        // TODO Report non-fatal events to the crashlytics or any error manager
    }
}

private fun AppTracker.trackApiException(cause: ApiException) {
    trackEvent(
        BaseErrorTrackingEvent(message = "[API] $cause")
    )
}
