package com.taager.firebase.remoteconfig

import com.taager.firebase.external.FirebaseRemoteConfig
import com.taager.firebase.external.RemoteConfigValue
import com.taager.firebase.external.fetchAndActivate
import com.taager.firebase.external.getValue
import kotlin.js.Promise

/** This interface is needed because we can't inject external interfaces in Kodein as reified types **/
internal interface InjectableFirebaseRemoteConfig : FirebaseRemoteConfig

/** These are just convenient functions */

internal fun InjectableFirebaseRemoteConfig.fetchAndActivate(): Promise<Boolean> =
    fetchAndActivate(this)

internal fun InjectableFirebaseRemoteConfig.getValue(key: String): RemoteConfigValue =
    getValue(this, key)
