package com.taager.dukan.feature.checkout.payment

sealed class PostPaymentViewState {

    companion object {
        val Initial: PostPaymentViewState = Loading
    }

    object Loading : PostPaymentViewState()

    data class Error(
        val contactEmail: String?
    ) : PostPaymentViewState()
}
