package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.InputField

sealed class ProductDetailsSideEffect {
    data class GoToCheckout(val sku: String, val quantity: Number) : ProductDetailsSideEffect()
    object GoToHome : ProductDetailsSideEffect()
    object ShowInternetConnectionError : ProductDetailsSideEffect()
    object ShowPlaceOrderError : ProductDetailsSideEffect()
    object ExecuteCaptcha : ProductDetailsSideEffect()
    data class FocusCheckoutField(val inputField: InputField<*>) : ProductDetailsSideEffect()
    object GoToCheckoutSuccess : ProductDetailsSideEffect()
}
