package com.taager.dukan.customer.data.local

import com.taager.dukan.customer.data.local.model.CustomerModel
import com.taager.experience.cache.LocalCacheStrategy
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache

internal class CustomerCache(
    override val memoryCache: MemoryCache<Key, CustomerModel>,
    override val diskCache: DiskCache<Key, CustomerModel>
) : LocalCacheStrategy<CustomerCache.Key, CustomerModel> {

    data class Key(
        val type: String = "Customer",
    )

    fun get(): CustomerModel? =
        super.get(key = Key())

    fun set(customer: CustomerModel) {
        super.set(
            key = Key(),
            content = customer
        )
    }
}
