package com.taager.featuremanager

import com.taager.firebase.remoteconfig.InjectableFirebaseRemoteConfig
import com.taager.firebase.remoteconfig.fetchAndActivate
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseErrorTrackingEvent

internal class FeatureManagerImpl(
    private val remoteConfig: InjectableFirebaseRemoteConfig,
    private val appTracker: AppTracker,
) : FeatureManager() {

    override fun initialize() {
        remoteConfig.fetchAndActivate()
            .then { attributesGotUpdated() }
            .catch { cause ->
                appTracker.trackEvent(
                    BaseErrorTrackingEvent(message = "[FeatureManager] $cause")
                )
            }
    }
}
