package com.taager.featuremanager

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow

abstract class FeatureManager {

    /**
     * We just to want to emit an event so consumers can act whenever the attributes got updated.
     * Boolean is just to avoid using Unit but the actual value is not used.
     */
    private val _onAttributesUpdate = MutableSharedFlow<Boolean>(
        // We need a buffer to temporarily store values when using tryEmit() because it is not a suspend function
        extraBufferCapacity = 1,
        replay = 1,
    )
    val onAttributesUpdate: Flow<Boolean> = _onAttributesUpdate

    abstract fun initialize()

    protected fun attributesGotUpdated() {
        _onAttributesUpdate.tryEmit(true)
    }
}
