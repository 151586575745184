package com.taager.dukan.feature.checkout

import com.taager.checkout.field.CheckoutForm
import com.taager.checkout.field.InputField
import com.taager.checkout.field.listenToCityQuery
import com.taager.checkout.field.transform
import com.taager.checkout.field.updateFieldValue
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.checkout.payment.getAvailablePaymentMethods
import com.taager.checkout.payment.toVisiblePaymentForm
import com.taager.checkout.validation.isValid
import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.checkout.domain.interactor.CalculateCheckoutUseCase
import com.taager.dukan.customer.domain.interactor.GetCustomerUseCase
import com.taager.dukan.customer.domain.interactor.GetDistrictsUseCase
import com.taager.dukan.customer.domain.interactor.QueryCitiesUseCase
import com.taager.dukan.customer.domain.interactor.SaveCustomerUseCase
import com.taager.dukan.feature.checkout.CheckoutViewState.OrderDetails
import com.taager.dukan.feature.checkout.mapper.initialLoadedState
import com.taager.dukan.feature.checkout.mapper.toCustomer
import com.taager.dukan.feature.checkout.mapper.transform
import com.taager.dukan.feature.checkout.tracking.trackAddPaymentInfo
import com.taager.dukan.feature.checkout.tracking.trackBackClick
import com.taager.dukan.feature.checkout.tracking.trackCaptchaError
import com.taager.dukan.feature.checkout.tracking.trackCaptchaResponse
import com.taager.dukan.feature.checkout.tracking.trackCheckoutSuccess
import com.taager.dukan.feature.checkout.tracking.trackInitiateCheckout
import com.taager.dukan.feature.checkout.tracking.trackPaymentMethodClick
import com.taager.dukan.feature.checkout.tracking.trackPlaceOrderClick
import com.taager.dukan.feature.checkout.tracking.trackScreenView
import com.taager.dukan.order.domain.interactor.PlaceOrderUseCase
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.product.domain.interactor.GetProductsBySkusUseCase
import com.taager.dukan.store.domain.interactor.GetStoreUseCase
import com.taager.tracking.AppTracker
import com.taager.translator.Translator
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.launch

@Suppress("LongParameterList")
class CheckoutPresenter(
    private val appTracker: AppTracker,
    private val translator: Translator,
    private val getStore: GetStoreUseCase,
    private val queryCities: QueryCitiesUseCase,
    private val getDistricts: GetDistrictsUseCase,
    private val getProductsBySkus: GetProductsBySkusUseCase,
    private val calculateCheckout: CalculateCheckoutUseCase,
    private val placeOrder: PlaceOrderUseCase,
    private val getCustomer: GetCustomerUseCase,
    private val saveCustomer: SaveCustomerUseCase,
) : ViewStateHandler<CheckoutViewState, CheckoutSideEffect>(CheckoutViewState.Initial),
    ViewEventHandler<CheckoutViewEvent> {

    private lateinit var storeName: String
    private var productQuantities: Map<Product, Int> = emptyMap()
    private var captchaToken: String? = null
    private var contactEmail: String? = null
    private val cityQueryFlow = MutableStateFlow<String?>(value = null)

    @Suppress("LongMethod")
    override fun onEvent(event: CheckoutViewEvent) {
        when (event) {
            is CheckoutViewEvent.Init -> {
                val skuQuantities = mapOf(event.sku to (event.quantity ?: 1))
                appTracker.trackScreenView(skuQuantities)
                onInit(skuQuantities)
            }

            is CheckoutViewEvent.UpdateField -> {
                onUpdateField(
                    inputField = event.inputField,
                    newValue = event.newValue
                )
            }

            is CheckoutViewEvent.QueryCities -> {
                onQueryCities(query = event.query)
            }

            is CheckoutViewEvent.PlaceOrderClick -> {
                appTracker.trackPlaceOrderClick(productQuantities)
                val loadedState = state.value as CheckoutViewState.Loaded
                loadedState.placeOrder()
            }

            is CheckoutViewEvent.PaymentMethodClick -> {
                appTracker.trackPaymentMethodClick(
                    productQuantities = productQuantities,
                    paymentMethod = event.paymentMethod
                )
                onPaymentMethodClick(event)
            }

            CheckoutViewEvent.BackClick -> {
                appTracker.trackBackClick(productQuantities)
                tryEmitEffect(CheckoutSideEffect.GoToProductDetails)
            }

            is CheckoutViewEvent.CaptchaResponse -> {
                captchaToken = event.token
                appTracker.trackCaptchaResponse(
                    productQuantities = productQuantities,
                    captchaToken = event.token
                )
            }

            CheckoutViewEvent.CaptchaError -> {
                appTracker.trackCaptchaError(productQuantities)
                tryEmitEffect(CheckoutSideEffect.ShowInternetConnectionError)
            }

            CheckoutViewEvent.ClosePaymentForm -> {
                val loadedState = state.value as CheckoutViewState.Loaded
                updateState(
                    newState = loadedState.copy(
                        paymentFormVisibility = PaymentFormVisibility.Hidden,
                    )
                )
            }

            CheckoutViewEvent.PaymentFormCompleted -> {
                val loadedState = state.value as CheckoutViewState.Loaded
                appTracker.trackAddPaymentInfo(
                    products = loadedState.orderDetails.products,
                    total = loadedState.orderDetails.internalTotal,
                    paymentMethod = requireNotNull(loadedState.paymentMethod)
                )
            }
        }
    }

    private fun onQueryCities(query: String) {
        cityQueryFlow.value = query
    }

    private fun onPaymentMethodClick(event: CheckoutViewEvent.PaymentMethodClick) {
        val loadedState = state.value as CheckoutViewState.Loaded
        val updatedState = loadedState.copy(
            paymentMethod = event.paymentMethod,
        ).run {
            copy(isPlaceOrderButtonEnabled = isCheckoutInputValid())
        }
        updateState(newState = updatedState)
        updateCheckout(city = loadedState.form.city.value)
    }

    private fun CheckoutViewState.Loaded.placeOrder() {
        updateState(
            newState = copy(
                isPlacingOrder = true,
            )
        )
        scope.launch {
            try {
                saveCustomer(toCustomer())
                when (paymentMethod) {
                    PaymentMethod.CashOnDelivery -> handleCashOnDeliveryFlow()
                    PaymentMethod.PayByCard -> handlePayByCardFlow()
                }
            } catch (error: Throwable) {
                appTracker.trackError(error)
                updateState(
                    newState = copy(
                        isPlacingOrder = false,
                    )
                )
                emitEffect(CheckoutSideEffect.ShowPlaceOrderError)
            }
        }
    }

    private suspend fun CheckoutViewState.Loaded.handleCashOnDeliveryFlow() {
        placeOrder(placeableOrder = transform(captchaToken))
        appTracker.trackCheckoutSuccess(
            products = orderDetails.products,
            total = orderDetails.internalTotal,
        )
        emitEffect(CheckoutSideEffect.GoToCheckoutSuccess)
    }

    private fun CheckoutViewState.Loaded.handlePayByCardFlow() {
        updateState(
            newState = copy(
                isPlacingOrder = false,
                paymentFormVisibility = form.toVisiblePaymentForm(
                    storeName = storeName,
                    productQuantities = productQuantities,
                )
            )
        )
    }

    private fun onInit(skuQuantities: Map<String, Int>) {
        cityQueryFlow
            .listenToCityQuery(::onCityQueryUpdate)
            .launchIn(scope)

        scope.launch {
            try {
                val store = getStore()
                storeName = store.name
                contactEmail = store.contactInfo?.email
                productQuantities = getProductsBySkus(skus = skuQuantities.keys.toList())
                    .zip(skuQuantities.values)
                    .toMap()
                val orderDetailsProducts = productQuantities.transform()
                val availablePaymentMethods = getAvailablePaymentMethods(
                    payments = store.payments
                )
                val selectedPaymentMethod = availablePaymentMethods.first()
                val checkout = calculateCheckout(
                    provinceId = null,
                    products = orderDetailsProducts.transform(),
                    paymentMethod = selectedPaymentMethod
                )
                val customer = getCustomer()
                val customerCityId = customer?.city?.id
                val customerCityName = customer?.city?.name
                val cities = queryCities(query = customerCityName ?: "").transform()
                val districts = getDistrictOrEmpty(cityId = customerCityId)
                val loadedState = initialLoadedState(
                    translator = translator,
                    store = store,
                    cities = cities,
                    districts = districts,
                    customer = customer,
                    orderDetailsProducts = orderDetailsProducts,
                    checkout = checkout,
                    availablePaymentMethods = availablePaymentMethods,
                    selectedPaymentMethod = selectedPaymentMethod,
                )

                updateState(
                    newState = loadedState.copy(
                        isPlaceOrderButtonEnabled = loadedState.isCheckoutInputValid()
                    )
                )

                appTracker.trackInitiateCheckout(
                    products = loadedState.orderDetails.products,
                    total = loadedState.orderDetails.internalTotal
                )
            } catch (_: Product.NotFoundError) {
                emitEffect(CheckoutSideEffect.GoToHome)
            } catch (error: Throwable) {
                appTracker.trackError(error)
                updateState(
                    newState = CheckoutViewState.Error
                )
            }
        }
    }

    private fun onCityQueryUpdate(query: String) {
        val loadedState = state.value as? CheckoutViewState.Loaded ?: return
        updateState(
            newState = loadedState.copy(
                form = loadedState.form.copy(
                    city = loadedState.form.city.copy(
                        isLoading = true,
                        items = emptyList()
                    )
                )
            )
        )
        scope.launch {
            try {
                val cities = queryCities(query).transform()
                with(state.value as CheckoutViewState.Loaded) {
                    updateState(
                        newState = copy(
                            form = form.copy(
                                city = form.city.copy(
                                    isLoading = false,
                                    items = cities
                                )
                            )
                        )
                    )
                }
            } catch (error: Throwable) {
                appTracker.trackError(error)
                emitEffect(CheckoutSideEffect.ShowInternetConnectionError)
            }
        }
    }

    private fun onUpdateField(
        inputField: InputField<*>,
        newValue: Any?
    ) {
        val loadedState = state.value as CheckoutViewState.Loaded
        val updatedFormState = loadedState.copy(
            form = loadedState.form.updateFieldValue(inputField, newValue),
        )
        updateState(
            newState = updatedFormState.copy(
                isPlaceOrderButtonEnabled = updatedFormState.isCheckoutInputValid()
            )
        )
        if (inputField is InputField.City && loadedState.form.hasDistrict) {
            val selectedCity = newValue as CheckoutForm.City?
            updateDistricts(selectedCity)
            updateCheckout(selectedCity)
        }
    }

    private fun updateDistricts(city: CheckoutForm.City?) {
        scope.launch {
            try {
                val districts = getDistrictOrEmpty(cityId = city?.id)
                with(state.value as CheckoutViewState.Loaded) {
                    val updatedFormState = copy(
                        form = form.copy(
                            district = form.district?.copy(
                                value = if (districts.count() == 1) districts.first() else null,
                                items = districts
                            )
                        )
                    )
                    updateState(
                        newState = updatedFormState.copy(
                            isPlaceOrderButtonEnabled = updatedFormState.isCheckoutInputValid()
                        )
                    )
                }
            } catch (error: Throwable) {
                appTracker.trackError(error)
                emitEffect(CheckoutSideEffect.ShowInternetConnectionError)
            }
        }
    }

    private fun updateCheckout(city: CheckoutForm.City?) {
        if (city == null) return
        scope.launch {
            with(state.value as CheckoutViewState.Loaded) {
                updateState(
                    newState = copy(
                        orderDetails = orderDetails.copy(
                            shippingCost = OrderDetails.Calculation.Loading,
                            codFee = OrderDetails.Calculation.Loading,
                            total = OrderDetails.Calculation.Loading,
                        )
                    )
                )
                try {
                    val checkout = calculateCheckout(
                        provinceId = city.province.id,
                        products = orderDetails.products.transform(),
                        paymentMethod = paymentMethod
                    )
                    updateState(
                        newState = copy(
                            orderDetails = orderDetails.transform(checkout)
                        )
                    )
                } catch (error: Throwable) {
                    appTracker.trackError(error)
                    updateState(
                        newState = copy(
                            orderDetails = orderDetails.copy(
                                shippingCost = OrderDetails.Calculation.Error,
                                codFee = OrderDetails.Calculation.Error,
                                total = OrderDetails.Calculation.Error
                            )
                        )
                    )
                }
            }
        }
    }

    private suspend fun getDistrictOrEmpty(cityId: String?): List<CheckoutForm.District> =
        if (cityId != null) {
            getDistricts(cityId = cityId).transform()
        } else {
            emptyList()
        }
}

private fun CheckoutViewState.Loaded.isCheckoutInputValid(): Boolean =
    form.isValid
