package com.taager.dukan.preferences.domain.interactor

import com.taager.dukan.preferences.domain.PreferencesRepository
import com.taager.dukan.preferences.domain.entity.Preferences

class GetPreferencesUseCase(
    private val repository: PreferencesRepository,
) {

    operator fun invoke(): Preferences =
        repository.getPreferences()
}
