package com.taager.dukan.product.data.remote.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class GetProductsResponse(
    @SerialName("products")
    val products: List<ProductDto>
)
