package com.taager.tracking.tiktok

internal sealed interface TikTokEvent {
    val event: String
    val properties: Map<String, Any>

    sealed class ViewContent(
        open val id: String,
    ) : TikTokEvent {
        override val event: String = "ViewContent"

        data class Product(
            override val id: String,
            val name: String,
            val price: Double,
            val currency: String,
            val value: Double,
        ) : ViewContent(id) {

            override val properties: Map<String, Any> = mapOf(
                "content_type" to "product",
                "content_id" to id,
                "content_name" to name,
                "price" to price,
                "currency" to currency,
                "value" to value,
            )
        }
    }

    object InitiateCheckout : TikTokEvent {
        override val event: String = "InitiateCheckout"
        override val properties: Map<String, Any> = emptyMap()
    }

    object AddPaymentInfo : TikTokEvent {
        override val event: String = "AddPaymentInfo"
        override val properties: Map<String, Any> = emptyMap()
    }

    abstract class Checkout(
        products: List<Product>,
        currency: String,
        value: Double,
    ) : TikTokEvent {
        override val properties: Map<String, Any> = mapOf(
            "contents" to products.map { product ->
                mapOf(
                    "content_type" to "product",
                    "content_id" to product.id,
                    "content_name" to product.name,
                    "price" to product.price,
                    "quantity" to product.quantity,
                )
            },
            "currency" to currency,
            "value" to value,
        )

        data class Product(
            val id: String,
            val name: String,
            val price: Double,
            val quantity: Int,
        )
    }

    data class CompletePayment(
        val products: List<Product>,
        val currency: String,
        val value: Double,
    ) : Checkout(products, currency, value) {
        override val event: String = "CompletePayment"
    }

    data class PlaceAnOrder(
        val products: List<Product>,
        val currency: String,
        val value: Double,
    ) : Checkout(products, currency, value) {
        override val event: String = "PlaceAnOrder"
    }
}
