package com.taager.dukan.feature.productdetails.di

import com.taager.dukan.feature.productdetails.ExpressCheckoutHandler
import com.taager.dukan.feature.productdetails.ProductDetailsPresenter
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object ProductDetailsDI {
    val module = DI.Module("Product Details DI") {
        bindProvider {
            ExpressCheckoutHandler(
                appTracker = instance(),
                translator = instance(),
                getStore = instance(),
                queryCities = instance(),
                getDistricts = instance(),
                placeOrder = instance(),
                getCustomer = instance(),
                saveCustomer = instance(),
            )
        }
        bindProvider {
            ProductDetailsPresenter(
                appTracker = instance(),
                getProduct = instance(),
                expressCheckoutHandler = instance(),
            )
        }
    }
}
