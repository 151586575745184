package com.taager.dukan.product.domain.entity

import com.taager.numbers.Money

data class Product(
    val sku: String,
    val name: String,
    val description: String,
    val medias: List<Media>,
    val availability: Availability,
    val pricingOptions: List<PricingOption>,
) {
    val defaultPricingOption: PricingOption
        get() = pricingOptions.firstOrNull(PricingOption::isDefault)
            ?: pricingOptions.first()

    fun pricingOptionForQuantity(quantity: Int): PricingOption =
        pricingOptions.first { pricingOption -> pricingOption.quantity == quantity }

    enum class Availability {
        InStock,
        OutOfStock,
    }

    sealed class Media(open val url: String) {

        data class Image(
            override val url: String,
            val small: String,
            val medium: String,
            val large: String,
        ) : Media(url)

        data class Video(override val url: String) : Media(url)
    }

    data class PricingOption(
        val name: String,
        val description: String?,
        val quantity: Int,
        val price: Money,
        val isDefault: Boolean,
    )

    object NotFoundError : IllegalStateException()
}
