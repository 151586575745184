package com.taager.dukan.feature.checkout.di

import com.taager.dukan.feature.checkout.CheckoutPresenter
import com.taager.dukan.feature.checkout.payment.PostPaymentPresenter
import com.taager.dukan.feature.checkout.success.CheckoutSuccessPresenter
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object CheckoutDI {
    val module = DI.Module("Checkout DI") {
        bindProvider {
            CheckoutPresenter(
                appTracker = instance(),
                translator = instance(),
                getStore = instance(),
                queryCities = instance(),
                getDistricts = instance(),
                getProductsBySkus = instance(),
                calculateCheckout = instance(),
                placeOrder = instance(),
                getCustomer = instance(),
                saveCustomer = instance(),
            )
        }
        bindProvider {
            PostPaymentPresenter(
                appTracker = instance(),
                getStore = instance(),
                getProduct = instance(),
                placePaidOrder = instance(),
            )
        }
        bindProvider {
            CheckoutSuccessPresenter(
                appTracker = instance(),
                getStore = instance(),
            )
        }
    }
}
