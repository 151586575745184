package com.taager.dukan.preferences.domain.interactor

import com.taager.dukan.preferences.domain.PreferencesRepository
import com.taager.dukan.preferences.domain.entity.Preferences

class SwitchLanguageUseCase(
    private val repository: PreferencesRepository,
) {

    operator fun invoke() {
        val preferences = repository.getPreferences()
        val newLanguage = when (preferences.language) {
            Preferences.Language.Arabic -> Preferences.Language.English
            Preferences.Language.English -> Preferences.Language.Arabic
        }
        repository.setPreferences(preferences.copy(language = newLanguage))
    }
}
