package com.taager.dukan.preferences.data.local.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PreferencesModel(
    @SerialName("language")
    val language: String,
    @SerialName("colorMode")
    val colorMode: String,
)
