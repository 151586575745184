package com.taager.dukan.ui.component

import com.taager.checkout.validation.hasError
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.SelectVariant
import react.ForwardRef
import react.ForwardRefExoticComponent
import react.ReactNode

internal val SelectInput: ForwardRefExoticComponent<BaseInputProps> = ForwardRef { props ->
    val field = props.inputField
    val inputLabelId = "${field.id}-label"
    val items = requireNotNull(field.items)
    FormControl {
        sx = props.sx
        InputLabel {
            id = inputLabelId
            required = field.isRequired
            +props.label
        }
        Select {
            inputRef = props.inputRef
            variant = SelectVariant.outlined
            id = field.id
            labelId = inputLabelId
            label = ReactNode(props.label)
            // Avoid this warning: `value` prop on `input` should not be null.
            // Consider using an empty string to clear the component...
            value = field.value ?: ""
            renderValue = { item -> ReactNode(item?.toString() ?: "") }
            placeholder = props.placeholder ?: field.placeholder
            required = field.isRequired
            error = field.hasError
            if (items.isEmpty() && props.emptyText != null) {
                // This item is only used when the inputField has an empty list of items and an emptyText is provided
                MenuItem {
                    value = null
                    +props.emptyText
                }
            } else {
                items.forEach { item ->
                    MenuItem {
                        value = item
                        +item.toString()
                    }
                }
            }
            onChange = { event, _ ->
                props.onChange(field, event.target.value)
            }
            onFocus = {
                props.onFocus?.invoke(field)
            }
        }
    }
}
