package com.taager.dukan.ui.theme

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.PaletteOptions
import mui.material.styles.SimplePaletteColorOptions
import web.cssom.Color
import web.cssom.rgb

internal val LightPalette: PaletteOptions = jso {
    mode = PaletteMode.light
    primary = jso<SimplePaletteColorOptions> {
        main = Color("#222222")
        light = Color("#F6F6F7")
    }
    secondary = jso<SimplePaletteColorOptions> {
        main = Color("#298CB6")
    }
    info = jso<SimplePaletteColorOptions> {
        main = Color("#298CB6")
    }
    success = jso<SimplePaletteColorOptions> {
        main = Color("#36A64A")
    }
    error = jso<SimplePaletteColorOptions> {
        main = Color("#CE0021")
    }
    warning = jso<SimplePaletteColorOptions> {
        main = Color("#E17605")
    }
    text = jso {
        primary = Color("#222222")
        secondary = Color("#707685")
    }
    action = jso {
        disabled = Color("#FFFFFF")
        // #222222 converted to rgb to apply opacity
        disabledBackground = rgb(red = 34, green = 34, blue = 34, alpha = 0.5)
        disabledOpacity = 0.5
    }
}

internal val DarkPalette: PaletteOptions = jso {
    mode = PaletteMode.dark
    primary = jso<SimplePaletteColorOptions> {
        main = Color("#222222")
        light = Color("#1E1E1E")
    }
    secondary = jso<SimplePaletteColorOptions> {
        main = Color("#298CB6")
    }
    info = jso<SimplePaletteColorOptions> {
        main = Color("#298CB6")
    }
    success = jso<SimplePaletteColorOptions> {
        main = Color("#52A636")
    }
    error = jso<SimplePaletteColorOptions> {
        main = Color("#FF0029")
    }
    warning = jso<SimplePaletteColorOptions> {
        main = Color("#F49206")
    }
    text = jso {
        primary = Color("#FFFFFF")
        secondary = Color("#737979")
    }
    action = jso {
        disabled = Color("#FFFFFF")
        // #222222 converted to rgb to apply opacity
        disabledBackground = rgb(red = 34, green = 34, blue = 34, alpha = 0.5)
        disabledOpacity = 0.5
    }
}
