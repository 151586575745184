package com.taager.api.di

import com.taager.api.SlugProvider
import com.taager.api.SlugProviderImpl
import org.kodein.di.DI
import org.kodein.di.bindProvider

internal actual object InternalApiDI {
    actual val module = DI.Module("Internal API DI") {
        bindProvider<SlugProvider> {
            SlugProviderImpl()
        }
    }
}
