package com.taager.dukan.feature.checkout.payment

import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.feature.checkout.payment.tracking.trackCompletePayment
import com.taager.dukan.feature.checkout.payment.tracking.trackContactEmailClick
import com.taager.dukan.feature.checkout.payment.tracking.trackScreenView
import com.taager.dukan.order.domain.interactor.PlacePaidOrderUseCase
import com.taager.dukan.product.domain.interactor.GetProductUseCase
import com.taager.dukan.store.domain.interactor.GetStoreUseCase
import com.taager.tracking.AppTracker
import kotlinx.coroutines.launch
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

class PostPaymentPresenter(
    private val appTracker: AppTracker,
    private val getStore: GetStoreUseCase,
    private val getProduct: GetProductUseCase,
    private val placePaidOrder: PlacePaidOrderUseCase,
) : ViewStateHandler<PostPaymentViewState, PostPaymentSideEffect>(PostPaymentViewState.Initial),
    ViewEventHandler<PostPaymentViewEvent> {

    override fun onEvent(event: PostPaymentViewEvent) {
        when (event) {
            is PostPaymentViewEvent.Init -> {
                onInit(
                    paymentId = event.paymentId,
                    sku = event.sku,
                    encodedQuantity = event.encodedQuantity,
                )
            }

            PostPaymentViewEvent.BackToHomeClick -> {
                tryEmitEffect(PostPaymentSideEffect.GoToHome)
            }

            PostPaymentViewEvent.BackToProductClick -> {
                tryEmitEffect(PostPaymentSideEffect.GoToProductDetails)
            }

            is PostPaymentViewEvent.ContactEmailClick -> {
                appTracker.trackContactEmailClick(email = event.email)
                tryEmitEffect(PostPaymentSideEffect.OpenMailTo(email = event.email))
            }
        }
    }

    private fun onInit(
        paymentId: String?,
        sku: String,
        encodedQuantity: String?,
    ) {
        val quantity = encodedQuantity.toQuantity()
        appTracker.trackScreenView(paymentId, sku, quantity)

        if (paymentId == null) {
            tryEmitEffect(PostPaymentSideEffect.GoToProductDetails)
        } else {
            scope.launch {
                val store = getStore()
                val contactEmail = store.contactInfo?.email
                val product = getProduct(sku)
                // Currently this quantity is only used for tracking purposes,
                // so we can fall back to one if it's not a valid number
                appTracker.trackCompletePayment(paymentId, product, quantity ?: 1)

                try {
                    placePaidOrder(paymentId)
                    emitEffect(PostPaymentSideEffect.GoToCheckoutSuccess)
                } catch (error: Throwable) {
                    appTracker.trackError(error)
                    updateState(
                        newState = PostPaymentViewState.Error(
                            contactEmail = contactEmail
                        )
                    )
                }
            }
        }
    }
}

@OptIn(ExperimentalEncodingApi::class)
private fun String?.toQuantity(): Int? =
    try {
        Base64
            .decode(requireNotNull(this))
            .decodeToString()
            .toInt()
    } catch (_: Throwable) {
        null
    }
