package com.taager.dukan.checkout.data.di

import com.taager.dukan.checkout.data.CheckoutRepositoryImpl
import com.taager.dukan.checkout.data.remote.CheckoutApi
import com.taager.dukan.checkout.domain.CheckoutRepository
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object CheckoutDataDI {
    val module = DI.Module("Checkout Data DI") {
        bindProvider {
            CheckoutApi(
                client = instance()
            )
        }
        bindProvider<CheckoutRepository> {
            CheckoutRepositoryImpl(
                api = instance(),
            )
        }
    }
}
