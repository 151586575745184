package com.taager.dukan.customer.data.local.mapper

import com.taager.dukan.customer.data.local.model.CustomerModel
import com.taager.dukan.customer.domain.entity.Customer

internal fun CustomerModel.transform(): Customer =
    Customer(
        fullName = fullName,
        phoneNumber = phoneNumber,
        alternativePhoneNumber = alternativePhoneNumber,
        email = email,
        province = province.transform(),
        city = city.transform(),
        district = district?.transform(),
        address = address,
    )

internal fun Customer.transform(): CustomerModel =
    CustomerModel(
        fullName = fullName,
        phoneNumber = phoneNumber,
        alternativePhoneNumber = alternativePhoneNumber,
        email = email,
        province = province.transform(),
        city = city.transform(),
        district = district?.transform(),
        address = address,
    )
