package com.taager.dukan.product.domain.interactor

import com.taager.dukan.product.domain.ProductRepository
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.store.domain.interactor.GetStoreUseCase

class GetProductUseCase(
    private val getStore: GetStoreUseCase,
    private val repository: ProductRepository,
) {

    suspend operator fun invoke(sku: String): Product =
        repository.getProduct(
            currency = getStore().country.currency,
            sku = sku
        )
}
