package com.taager.dukan.preferences.data.local.mapper

import com.taager.dukan.preferences.data.local.model.PreferencesModel
import com.taager.dukan.preferences.domain.entity.Preferences

internal fun Preferences.transform(): PreferencesModel =
    PreferencesModel(
        language = language.name,
        colorMode = colorMode.name,
    )

internal fun PreferencesModel.transform(): Preferences =
    Preferences(
        language = Preferences.Language.valueOf(language),
        colorMode = Preferences.ColorMode.valueOf(colorMode),
    )
