package com.taager.dukan.feature.attributes

import com.taager.dukan.di.mainDI
import com.taager.featuremanager.FeatureAttributes
import com.taager.featuremanager.FeatureManager
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.datetime.Clock
import org.kodein.di.direct
import org.kodein.di.instance
import react.FC
import react.PropsWithChildren
import react.createRequiredContext
import react.useEffectOnce
import react.useMemo
import react.useRequiredContext
import react.useState

private val FeatureAttributesContext = createRequiredContext<FeatureAttributes>()

internal val FeatureAttributesProvider: FC<PropsWithChildren> = FC { props ->
    // updateTrigger is used to trigger getting a new instance of the FeatureAttributes,
    // so the UI can be redrawn when new attributes are updated.
    var updateTrigger by useState(initialValue = Clock.System.now())
    val featureAttributes: FeatureAttributes = useMemo(updateTrigger) {
        mainDI().direct.instance()
    }
    FeatureAttributesContext(featureAttributes) {
        +props.children
    }
    val featureManager: FeatureManager by mainDI().instance()
    useEffectOnce {
        featureManager.onAttributesUpdate
            .onEach { updateTrigger = Clock.System.now() }
            .launchIn(MainScope())
    }
}

internal fun useFeatureAttributes(): FeatureAttributes =
    useRequiredContext(FeatureAttributesContext)
