package com.taager.dukan.order.data.remote.mapper

import com.taager.dukan.order.data.remote.request.PlaceOrderRequest
import com.taager.dukan.order.data.remote.request.PlacePaidOrderRequest
import com.taager.dukan.order.domain.entity.PlaceableOrder

internal fun PlaceableOrder.transform(): PlaceOrderRequest =
    PlaceOrderRequest(
        fullName = fullName,
        address = address,
        phoneNumber = phoneNumber,
        alternativePhoneNumber = alternativePhoneNumber,
        email = email,
        notes = notes,
        provinceId = provinceId,
        cityId = cityId,
        districtId = districtId,
        products = products.map(PlaceableOrder.Products::transform),
        captchaToken = captchaToken,
    )

private fun PlaceableOrder.Products.transform(): PlaceOrderRequest.Products =
    PlaceOrderRequest.Products(
        sku = sku,
        quantity = quantity,
    )

internal fun transform(paymentId: String): PlacePaidOrderRequest =
    PlacePaidOrderRequest(
        paymentId = paymentId,
    )
