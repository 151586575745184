package com.taager.tracking.event

class ScreenViewTrackingEvent(
    screen: Screen,
    extras: Map<String, Any> = emptyMap()
) :
    BaseTrackingEvent(
        screen,
        event = "screen_view",
        extras
    )
