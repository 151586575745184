package com.taager.dukan

import com.taager.dukan.di.mainDI
import com.taager.dukan.initializer.Initializer
import org.kodein.di.instance
import react.create
import react.dom.client.createRoot
import web.dom.document
import web.html.HTML.div
import kotlin.js.Promise

private val initializers: Set<Initializer> by mainDI().instance()

fun main() {
    Promise.all(initializers.map(Initializer::initialize).toTypedArray())
        .then {
            val container = document.createElement(div)
                .also(document.body::appendChild)
            createRoot(container).render(App.create())
        }
}
