package com.taager.dukan.feature.checkout.success

import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.feature.checkout.success.tracking.trackContactEmailClick
import com.taager.dukan.feature.checkout.success.tracking.trackScreenView
import com.taager.dukan.store.domain.interactor.GetStoreUseCase
import com.taager.tracking.AppTracker
import kotlinx.coroutines.launch

class CheckoutSuccessPresenter(
    private val appTracker: AppTracker,
    private val getStore: GetStoreUseCase,
) : ViewStateHandler<CheckoutSuccessViewState, CheckoutSuccessSideEffect>(CheckoutSuccessViewState.Initial),
    ViewEventHandler<CheckoutSuccessViewEvent> {

    override fun onEvent(event: CheckoutSuccessViewEvent) {
        when (event) {
            is CheckoutSuccessViewEvent.Init -> {
                onInit(key = event.key)
            }

            CheckoutSuccessViewEvent.BackToHomeClick -> {
                tryEmitEffect(CheckoutSuccessSideEffect.GoToHome)
            }

            is CheckoutSuccessViewEvent.ContactEmailClick -> {
                appTracker.trackContactEmailClick(email = event.email)
                tryEmitEffect(CheckoutSuccessSideEffect.OpenMailTo(email = event.email))
            }
        }
    }

    private fun onInit(key: String?) {
        if (isRoutedByTheUser(key)) {
            tryEmitEffect(CheckoutSuccessSideEffect.GoToHome)
            return
        }
        appTracker.trackScreenView()
        scope.launch {
            val store = getStore()
            val contactEmail = store.contactInfo?.email
            updateState(
                newState = CheckoutSuccessViewState.Loaded(contactEmail = contactEmail)
            )
        }
    }
}

private fun isRoutedByTheUser(key: String?): Boolean =
    key == "default"
