package com.taager.numbers

import kotlin.math.abs

internal fun Number.format(): String =
    if (abs(toDouble() - toInt()) == 0.0) {
        format(digits = 0)
    } else {
        format(digits = 2)
    }

expect fun Number.format(digits: Int): String
