package com.taager.checkout.field

import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.onEach

private const val CITY_QUERY_TYPE_DEBOUNCE_IN_MILLIS = 400L

@OptIn(FlowPreview::class)
fun Flow<String?>.listenToCityQuery(onCityQueryUpdate: (String) -> Unit): Flow<String?> =
    filterNotNull() // Skip any initial null value
        .debounce(CITY_QUERY_TYPE_DEBOUNCE_IN_MILLIS)
        .distinctUntilChanged()
        .onEach(onCityQueryUpdate)
