package com.taager.dukan.feature.productdetails

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.icon.ProductDetailsBuyIcon
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.system.sx
import react.FC
import react.Props
import react.create
import web.cssom.pct
import web.html.ButtonType

internal val ProductDetailsOrderNowButton: FC<ProductDetailsOrderNowButtonProps> = FC { props ->
    Button {
        sx {
            width = 100.pct
        }
        type = ButtonType.submit
        color = ButtonColor.success
        variant = ButtonVariant.contained
        startIcon = ProductDetailsBuyIcon.create()
        disabled = !props.isEnabled
        +"product_details_order_now_button".i18n()
        onClick = { props.onClick() }
    }
}

internal external interface ProductDetailsOrderNowButtonProps : Props {
    var isEnabled: Boolean
    var onClick: () -> Unit
}
