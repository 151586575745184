package com.taager.dukan.di

import com.taager.api.di.ApiDI
import com.taager.dukan.appVersion
import com.taager.dukan.checkout.data.di.CheckoutDataDI
import com.taager.dukan.checkout.domain.di.CheckoutDomainDI
import com.taager.dukan.customer.data.di.CustomerDataDI
import com.taager.dukan.customer.domain.di.CustomerDomainDI
import com.taager.dukan.feature.checkout.di.CheckoutDI
import com.taager.dukan.feature.home.di.HomeDI
import com.taager.dukan.feature.productdetails.di.ProductDetailsDI
import com.taager.dukan.feature.productlist.di.ProductListDI
import com.taager.dukan.feature.quicklink.di.QuickLinkDI
import com.taager.dukan.initializer.di.InitializerDI
import com.taager.dukan.isDevelopment
import com.taager.dukan.order.data.di.OrderDataDI
import com.taager.dukan.order.domain.di.OrderDomainDI
import com.taager.dukan.preferences.data.di.PreferencesDataDI
import com.taager.dukan.preferences.domain.di.PreferencesDomainDI
import com.taager.dukan.product.data.di.ProductDataDI
import com.taager.dukan.product.domain.di.ProductDomainDI
import com.taager.dukan.store.data.di.StoreDataDI
import com.taager.dukan.store.domain.di.StoreDomainDI
import com.taager.experience.api.configuration.model.AppInfo
import com.taager.firebase.di.FirebaseDI
import com.taager.tracking.di.TrackingDI
import com.taager.translator.di.TranslatorDI
import org.kodein.di.DI
import org.kodein.di.bindProvider

object MainDI {
    val module = DI.Module("Main DI") {
        import(InitializerDI.module)
        import(TrackingDI.module)
        import(ApiDI.module)
        import(TranslatorDI.module)
        import(FirebaseDI.module)

        import(StoreDomainDI.module)
        import(StoreDataDI.module)

        import(PreferencesDomainDI.module)
        import(PreferencesDataDI.module)

        import(ProductDomainDI.module)
        import(ProductDataDI.module)

        import(CheckoutDomainDI.module)
        import(CheckoutDataDI.module)

        import(CustomerDomainDI.module)
        import(CustomerDataDI.module)

        import(HomeDI.module)
        import(QuickLinkDI.module)
        import(ProductListDI.module)
        import(ProductDetailsDI.module)
        import(CheckoutDI.module)

        import(OrderDomainDI.module)
        import(OrderDataDI.module)

        bindAppInfo()
    }
}

private fun DI.Builder.bindAppInfo() {
    bindProvider {
        AppInfo(
            isDevelopmentMode = isDevelopment,
            version = appVersion,
        )
    }
}
