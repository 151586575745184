package com.taager.checkout.field

import com.taager.country.model.Country
import com.taager.country.model.KSA

interface CheckoutForm {
    val fullName: InputField.FullName
    val city: InputField.City
    val hasDistrict: Boolean
    val district: InputField.District?
    val address: InputField.Address
    val phoneNumber: InputField.PhoneNumber
    val alternativePhoneNumber: InputField.AlternativePhoneNumber?
    val email: InputField.Email?
    val notes: InputField.Notes?

    data class Province(
        val id: String,
        val name: String,
    ) {
        override fun toString(): String = name
    }

    data class City(
        val id: String,
        val name: String,
        val province: Province
    ) {
        override fun toString(): String =
            "$name, ${province.name}"
    }

    data class District(
        val id: String,
        val name: String,
    ) {
        override fun toString(): String = name
    }
}

fun hasDistricts(country: Country): Boolean {
    return when (country) {
        is KSA -> true
        else -> false
    }
}
