package com.taager.dukan.customer.data.di

import com.taager.dukan.customer.data.CustomerRepositoryImpl
import com.taager.dukan.customer.data.local.CustomerCache
import com.taager.dukan.customer.data.local.ProvinceCache
import com.taager.dukan.customer.data.local.model.CustomerModel
import com.taager.dukan.customer.data.local.model.ProvinceModel
import com.taager.dukan.customer.data.remote.CustomerApi
import com.taager.dukan.customer.domain.CustomerRepository
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache
import kotlinx.serialization.builtins.ListSerializer
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import kotlin.time.Duration.Companion.hours

object CustomerDataDI {
    val module = DI.Module("Customer Data DI") {
        bindProvider {
            CustomerApi(
                client = instance(),
            )
        }
        bindProvider {
            MemoryCache<ProvinceCache.Key, List<ProvinceModel>>(
                maxAgeInMilliseconds = 3.hours.inWholeMilliseconds,
            )
        }
        bindProvider {
            DiskCache<ProvinceCache.Key, List<ProvinceModel>>(
                maxAgeInMilliseconds = 3.hours.inWholeMilliseconds,
                serializer = ListSerializer(ProvinceModel.serializer()),
            )
        }
        bindSingleton {
            ProvinceCache(
                memoryCache = instance(),
                diskCache = instance(),
            )
        }
        bindProvider {
            MemoryCache<CustomerCache.Key, CustomerModel>()
        }
        bindProvider {
            DiskCache<CustomerCache.Key, CustomerModel>(
                serializer = CustomerModel.serializer(),
            )
        }
        bindSingleton {
            CustomerCache(
                memoryCache = instance(),
                diskCache = instance(),
            )
        }
        bindProvider<CustomerRepository> {
            CustomerRepositoryImpl(
                provinceCache = instance(),
                customerCache = instance(),
                api = instance(),
            )
        }
    }
}
