package com.taager.dukan.store.data.remote

import com.taager.dukan.store.data.remote.response.GetInfoResponse
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get

private const val INFO_PATH = "api/info"

internal class StoreApi(
    private val client: HttpClient,
) {

    suspend fun getInfo(): GetInfoResponse =
        client.get(INFO_PATH)
            .body()
}
