package com.taager.dukan.navigation

import web.window.WindowTarget
import web.window.window

internal fun openMailTo(email: String) {
    window.open(
        url = "mailto:$email",
        target = WindowTarget._blank
    )
}
