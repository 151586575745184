package com.taager.dukan.ui.theme

import com.taager.dukan.language.useLanguage
import js.core.jso
import mui.material.CssBaseline
import mui.material.styles.PaletteOptions
import mui.material.styles.Theme
import mui.material.styles.ThemeProvider
import mui.material.styles.createTheme
import mui.system.Direction
import react.FC
import react.PropsWithChildren
import react.createRequiredContext
import react.useMemo
import react.useRequiredContext

private val ThemeContext = createRequiredContext<Theme>()

internal val Themed = FC<PropsWithChildren> { props ->
    val language by useLanguage()
    val direction = useMemo(language) {
        language.direction.transform()
    }
    val colorPalette = useColorPalette()
    val theme = useMemo(colorPalette) {
        createTheme(direction, colorPalette)
    }
    ThemeContext(theme) {
        ThemeProvider {
            this.theme = theme
            CssBaseline()
            +props.children
        }
    }
}

internal fun useTheme(): Theme =
    useRequiredContext(ThemeContext)

private fun String.transform(): Direction =
    when (this) {
        "rtl" -> Direction.rtl
        else -> Direction.ltr
    }

private fun createTheme(direction: Direction, colorPalette: PaletteOptions): Theme =
    createTheme(
        jso {
            this.direction = direction
            palette = colorPalette
            typography = Typography
            components = jso {
                MuiCssBaseline = jso {
                    styleOverrides = CssBaselineOverrides
                }
            }
        }
    )
