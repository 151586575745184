package com.taager.dukan.feature.productdetails

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.theme.useTheme
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.Theme
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.StateInstance
import react.useState
import web.cssom.Color
import web.cssom.FontWeight

internal val ProductDetailsAvailability: FC<ProductDetailsAvailabilityProps> = FC { props ->
    val theme = useTheme()
    val availabilityColors by useAvailabilityColors(theme)
    val availabilityTexts by useAvailabilityTexts()
    Stack {
        sx {
            marginTop = theme.spacing(1)
        }
        direction = responsive(StackDirection.row)
        spacing = responsive(1)

        Typography {
            sx {
                color = theme.palette.text.secondary
            }
            variant = TypographyVariant.body1

            +"product_availability".i18n()
        }
        Typography {
            sx {
                fontWeight = FontWeight.bold
                color = availabilityColors.getValue(props.availability)
            }
            variant = TypographyVariant.body1

            +availabilityTexts.getValue(props.availability).i18n()
        }
    }
}

internal external interface ProductDetailsAvailabilityProps : Props {
    var availability: ProductDetailsViewState.Product.Availability
}

private fun useAvailabilityTexts(): StateInstance<Map<ProductDetailsViewState.Product.Availability, String>> =
    useState {
        mapOf(
            ProductDetailsViewState.Product.Availability.Available to "product_availability_available",
            ProductDetailsViewState.Product.Availability.SoldOut to "product_availability_sold_out",
        )
    }

private fun useAvailabilityColors(
    theme: Theme
): StateInstance<Map<ProductDetailsViewState.Product.Availability, Color>> =
    useState {
        mapOf(
            ProductDetailsViewState.Product.Availability.Available to theme.palette.success.main,
            ProductDetailsViewState.Product.Availability.SoldOut to theme.palette.text.secondary,
        )
    }
