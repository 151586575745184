package com.taager.dukan.customer.data

/**
 * Adapted from
 * [stackoverflow](https://stackoverflow.com/questions/20757780/how-to-perform-search-on-arabic-text-in-java)
 */

private val DiacriticsToIgnoreRegex = buildString {

    append("[")

    // Remove honorific sign
    append("\\u0610") // ARABIC SIGN SALLALLAHOU ALAYHE WA SALLAM
    append("\\u0611") // ARABIC SIGN ALAYHE ASSALLAM
    append("\\u0612") // ARABIC SIGN RAHMATULLAH ALAYHE
    append("\\u0613") // ARABIC SIGN RADI ALLAHOU ANHU
    append("\\u0614") // ARABIC SIGN TAKHALLUS

    // Remove koranic anotation
    append("\\u0615") // ARABIC SMALL HIGH TAH
    append("\\u0616") // ARABIC SMALL HIGH LIGATURE ALEF WITH LAM WITH YEH
    append("\\u0617") // ARABIC SMALL HIGH ZAIN
    append("\\u0618") // ARABIC SMALL FATHA
    append("\\u0619") // ARABIC SMALL DAMMA
    append("\\u061A") // ARABIC SMALL KASRA
    append("\\u06D6") // ARABIC SMALL HIGH LIGATURE SAD WITH LAM WITH ALEF MAKSURA
    append("\\u06D7") // ARABIC SMALL HIGH LIGATURE QAF WITH LAM WITH ALEF MAKSURA
    append("\\u06D8") // ARABIC SMALL HIGH MEEM INITIAL FORM
    append("\\u06D9") // ARABIC SMALL HIGH LAM ALEF
    append("\\u06DA") // ARABIC SMALL HIGH JEEM
    append("\\u06DB") // ARABIC SMALL HIGH THREE DOTS
    append("\\u06DC") // ARABIC SMALL HIGH SEEN
    append("\\u06DD") // ARABIC END OF AYAH
    append("\\u06DE") // ARABIC START OF RUB EL HIZB
    append("\\u06DF") // ARABIC SMALL HIGH ROUNDED ZERO
    append("\\u06E0") // ARABIC SMALL HIGH UPRIGHT RECTANGULAR ZERO
    append("\\u06E1") // ARABIC SMALL HIGH DOTLESS HEAD OF KHAH
    append("\\u06E2") // ARABIC SMALL HIGH MEEM ISOLATED FORM
    append("\\u06E3") // ARABIC SMALL LOW SEEN
    append("\\u06E4") // ARABIC SMALL HIGH MADDA
    append("\\u06E5") // ARABIC SMALL WAW
    append("\\u06E6") // ARABIC SMALL YEH
    append("\\u06E7") // ARABIC SMALL HIGH YEH
    append("\\u06E8") // ARABIC SMALL HIGH NOON
    append("\\u06E9") // ARABIC PLACE OF SAJDAH
    append("\\u06EA") // ARABIC EMPTY CENTRE LOW STOP
    append("\\u06EB") // ARABIC EMPTY CENTRE HIGH STOP
    append("\\u06EC") // ARABIC ROUNDED HIGH STOP WITH FILLED CENTRE
    append("\\u06ED") // ARABIC SMALL LOW MEEM

    // Remove tatweel
    append("\\u0640")

    // Remove tashkeel
    append("\\u064B") // ARABIC FATHATAN
    append("\\u064C") // ARABIC DAMMATAN
    append("\\u064D") // ARABIC KASRATAN
    append("\\u064E") // ARABIC FATHA
    append("\\u064F") // ARABIC DAMMA
    append("\\u0650") // ARABIC KASRA
    append("\\u0651") // ARABIC SHADDA
    append("\\u0652") // ARABIC SUKUN
    append("\\u0653") // ARABIC MADDAH ABOVE
    append("\\u0654") // ARABIC HAMZA ABOVE
    append("\\u0655") // ARABIC HAMZA BELOW
    append("\\u0656") // ARABIC SUBSCRIPT ALEF
    append("\\u0657") // ARABIC INVERTED DAMMA
    append("\\u0658") // ARABIC MARK NOON GHUNNA
    append("\\u0659") // ARABIC ZWARAKAY
    append("\\u065A") // ARABIC VOWEL SIGN SMALL V ABOVE
    append("\\u065B") // ARABIC VOWEL SIGN INVERTED SMALL V ABOVE
    append("\\u065C") // ARABIC VOWEL SIGN DOT BELOW
    append("\\u065D") // ARABIC REVERSED DAMMA
    append("\\u065E") // ARABIC FATHA WITH TWO DOTS
    append("\\u065F") // ARABIC WAVY HAMZA BELOW
    append("\\u0670") // ARABIC LETTER SUPERSCRIPT ALEF

    append("]*")
}.toRegex()

internal fun String.normalize(): String =
    replace(DiacriticsToIgnoreRegex, "")
        // Replace Waw Hamza Above by Waw
        .replace("\u0624", "\u0648")
        // Replace Ta Marbuta by Ha
        .replace("\u0629", "\u0647")
        // Replace Ya
        // and Ya Hamza Above by Alif Maksura
        .replace("\u064A", "\u0649")
        .replace("\u0626", "\u0649")
        // Replace Alifs with Hamza Above/Below
        // and with Madda Above by Alif
        .replace("\u0622", "\u0627")
        .replace("\u0623", "\u0627")
        .replace("\u0625", "\u0627")
