package com.taager.dukan.navigation

import com.taager.dukan.feature.checkout.CheckoutScreen
import com.taager.dukan.feature.checkout.payment.PostPaymentScreen
import com.taager.dukan.feature.checkout.success.CheckoutSuccessScreen
import com.taager.dukan.feature.home.HomeScreen
import com.taager.dukan.feature.main.StoreNotFound
import com.taager.dukan.feature.productdetails.ProductDetailsScreenWithExpressCheckoutContext
import com.taager.dukan.feature.productlist.ProductListScreen
import com.taager.dukan.feature.quicklink.QuickLinkScreen
import io.ktor.http.HttpStatusCode
import js.core.jso
import kotlinx.browser.window
import react.FC
import react.Props
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import remix.run.router.Action
import remix.run.router.LoaderFunctionArgs
import remix.run.router.RouterState
import remix.run.router.redirect
import web.http.Response

internal val Router = FC<Props> {
    RouterProvider {
        router = BrowserRouter.apply {
            subscribe(::onRouterStateChange)
        }
    }
}

private fun onRouterStateChange(state: RouterState) {
    if (state.historyAction == Action.Push) {
        window.scrollTo(x = 0.0, y = 0.0)
    }
}

private val BrowserRouter = createBrowserRouter(
    routes = arrayOf(
        jso {
            index = true
            Component = HomeScreen
            loader = HomeLoader
        },
        jso {
            path = "/"
            Component = HomeScreen
            children = arrayOf(
                jso {
                    path = "products"
                    Component = ProductListScreen
                },
                jso {
                    path = "products/:sku"
                    Component = ProductDetailsScreenWithExpressCheckoutContext
                },
                jso {
                    path = "products/:sku/success"
                    Component = CheckoutSuccessScreen
                },
                jso {
                    path = "products/:sku/post_payment"
                    Component = PostPaymentScreen
                },
                jso {
                    path = "products/:sku/checkout"
                    Component = CheckoutScreen
                },
                jso {
                    path = "products/:sku/checkout/success"
                    Component = CheckoutSuccessScreen
                },
                jso {
                    path = "products/:sku/checkout/post_payment"
                    Component = PostPaymentScreen
                },
                jso {
                    path = "about_us"
                    Component = QuickLinkScreen
                },
                jso {
                    path = "shipping_policy"
                    Component = QuickLinkScreen
                },
                jso {
                    path = "contact_us"
                    Component = QuickLinkScreen
                },
                jso {
                    path = "payment_methods"
                    Component = QuickLinkScreen
                },
                jso {
                    path = "return_policy"
                    Component = QuickLinkScreen
                },
                jso {
                    path = "privacy_policy"
                    Component = QuickLinkScreen
                },
                jso {
                    path = "*"
                    loader = HomeLoader
                }
            )
        },
        jso {
            path = "store_not_found"
            Component = StoreNotFound
        },
        jso {
            path = "*"
            loader = HomeLoader
        }
    ),
)

private val HomeLoader: (LoaderFunctionArgs) -> Response
    get() = { redirect("products", HttpStatusCode.Found.value) }
