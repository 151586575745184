package com.taager.dukan.feature.checkout

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.external.ReCaptchaComponent
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.system.sx
import react.FC
import react.Props
import react.RefObject
import web.cssom.pct
import web.html.ButtonType

internal val CheckoutPlaceOrderButton: FC<CheckoutPlaceOrderButtonProps> = FC { props ->
    Button {
        sx {
            width = 100.pct
        }
        type = ButtonType.submit
        color = ButtonColor.success
        variant = ButtonVariant.contained
        disabled = !props.isEnabled
        +"checkout_place_order_button".i18n()
        onClick = {
            props.recaptchaRef.current
                ?.executeAsync()
                ?.then { props.onRecaptchaSuccess() }
                ?.catch { /* Already handled by the presenter */ }
        }
    }
}

internal external interface CheckoutPlaceOrderButtonProps : Props {
    var isEnabled: Boolean
    var recaptchaRef: RefObject<ReCaptchaComponent>
    var onRecaptchaSuccess: () -> Unit
}
