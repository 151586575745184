package com.taager.dukan.store.data.local

import com.taager.dukan.store.data.local.model.QuickLinkModel
import com.taager.dukan.store.data.local.model.QuickLinkModels

internal class QuickLinkCache {

    operator fun get(id: String): QuickLinkModel =
        QuickLinkModels.getValue(id)
}
