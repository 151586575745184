package com.taager.dukan.ui.component

import com.taager.dukan.ui.rtl.isRTL
import js.core.jso
import mui.material.Snackbar
import mui.material.SnackbarOriginHorizontal
import mui.material.SnackbarOriginVertical
import react.FC
import react.dom.html.ReactHTML.div

private const val AUTO_HIDE_DURATION = 3000

internal external interface AlertSnackbarProps : AlertPanelProps {
    var open: Boolean
    var onClose: () -> Unit
}

internal val AlertSnackbar: FC<AlertSnackbarProps> = FC { props ->
    Snackbar {
        key = props.message
        autoHideDuration = AUTO_HIDE_DURATION
        anchorOrigin = jso {
            vertical = SnackbarOriginVertical.top
            horizontal = if (isRTL) {
                SnackbarOriginHorizontal.left
            } else {
                SnackbarOriginHorizontal.right
            }
        }
        div {
            AlertPanel {
                color = props.color
                title = props.title
                message = props.message
                actionText = props.actionText
                onActionClick = props.onActionClick
            }
        }
        open = props.open
        onClose = { _, _ ->
            props.onClose()
        }
    }
}
