package com.taager.firebase.di

import com.taager.featuremanager.FeatureAttributes
import com.taager.featuremanager.FeatureAttributesImpl
import com.taager.featuremanager.FeatureManager
import com.taager.featuremanager.FeatureManagerImpl
import com.taager.firebase.external.getPerformance
import com.taager.firebase.external.getRemoteConfig
import com.taager.firebase.external.initializeApp
import com.taager.firebase.remoteconfig.asJson
import com.taager.firebase.remoteconfig.setUpRemoteConfigSettings
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import kotlin.js.Json

private val env = js("ENV")
private val FirebaseAppSettings: Json = JSON.parse(env.FIREBASE_APP_SETTINGS.unsafeCast<String>())
internal actual val isProduction = env.PRODUCTION.unsafeCast<Boolean>()

internal actual object InternalFirebaseDI {

    actual val module = DI.Module("Internal Firebase DI") {
        bindSingleton {
            initializeApp(
                firebaseAppSettings = FirebaseAppSettings
            ).also(::getPerformance)
        }
        bindSingleton {
            getRemoteConfig(
                app = instance()
            ).apply {
                settings.setUpRemoteConfigSettings()
                defaultConfig = FeatureAttributes.Default.asJson()
            }
        }
        bindSingleton<FeatureManager> {
            FeatureManagerImpl(
                remoteConfig = instance(),
                appTracker = instance(),
            )
        }
        bindProvider<FeatureAttributes> {
            FeatureAttributesImpl(
                remoteConfig = instance()
            )
        }
    }
}
