package com.taager.dukan.customer.data.local

import com.taager.dukan.customer.data.local.model.ProvinceModel
import com.taager.experience.cache.LocalCacheStrategy
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache

internal class ProvinceCache(
    override val memoryCache: MemoryCache<Key, List<ProvinceModel>>,
    override val diskCache: DiskCache<Key, List<ProvinceModel>>
) : LocalCacheStrategy<ProvinceCache.Key, List<ProvinceModel>> {

    data class Key(
        val type: String = "Province",
        val country: String,
    )

    operator fun get(country: String): List<ProvinceModel>? =
        super.get(key = Key(country = country))

    operator fun set(country: String, cities: List<ProvinceModel>) {
        super.set(
            key = Key(country = country),
            content = cities
        )
    }
}
