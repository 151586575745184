package com.taager.dukan.language

import com.taager.dukan.di.mainDI
import com.taager.dukan.preferences.domain.entity.Preferences
import com.taager.dukan.preferences.domain.interactor.GetPreferencesUseCase
import com.taager.tracking.AppTracker
import org.kodein.di.instance
import react.FC
import react.PropsWithChildren
import react.StateInstance
import react.createRequiredContext
import react.useEffect
import react.useRequiredContext
import react.useState
import web.dom.document

private typealias LanguageState = StateInstance<Preferences.Language>

private val LanguageContext = createRequiredContext<LanguageState>()

internal val LanguageProvider: FC<PropsWithChildren> = FC { props ->
    val getPreferences: GetPreferencesUseCase by mainDI().instance()
    val appTracker: AppTracker by mainDI().instance()
    val state = useState(initialValue = getPreferences().language)
    val (language) = state
    LanguageContext(state) {
        +props.children
    }
    useEffect(language) {
        appTracker.trackLanguage(language)
        onLanguageChange(language)
    }
}

internal fun useLanguage(): LanguageState =
    useRequiredContext(LanguageContext)

private fun onLanguageChange(
    language: Preferences.Language,
) {
    document.dir = language.direction
    document.documentElement.lang = language.id
}

private fun AppTracker.trackLanguage(language: Preferences.Language) {
    setEventProperty("language", language.id)
}
