package com.taager.dukan.customer.data.remote.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class GetProvincesResponse(
    @SerialName("provinces")
    val provinces: List<Province>,
) {
    @Serializable
    data class Province(
        @SerialName("id")
        val id: String,
        @SerialName("name")
        val name: String,
        @SerialName("zones")
        val zones: List<Zone>,
    ) {
        @Serializable
        data class Zone(
            @SerialName("id")
            val id: String,
            @SerialName("name")
            val name: String,
            @SerialName("districts")
            val districts: List<District>,
        ) {
            @Serializable
            data class District(
                @SerialName("id")
                val id: String,
                @SerialName("name")
                val name: String,
            )
        }
    }
}
