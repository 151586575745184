package com.taager.dukan.ui.component

import com.taager.checkout.field.InputField
import react.FC
import react.PropsWithChildren
import react.asStringOrNull

internal val Linkify: FC<LinkifyProps> = FC { props ->
    val content = props.children?.asStringOrNull() ?: ""

    val parts = content.split(InputField.Email.Regex)
    val matches = InputField.Email.Regex.findAll(content).toList()
    parts.forEachIndexed { index, part ->
        if (matches.size > index) {
            +part
            val matchValue = matches[index].value
            TextButtonLink {
                +matchValue
                onClick = { props.onClick(matchValue) }
            }
        } else {
            +part
        }
    }
}

internal external interface LinkifyProps : PropsWithChildren {
    var onClick: (link: String) -> Unit
}
