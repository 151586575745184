package com.taager.dukan.ui.hooks

import react.useCallback
import react.useEffect
import web.dom.document
import web.events.EventHandler
import web.events.EventType
import web.keyboard.Key
import web.uievents.KEY_DOWN
import web.uievents.KeyboardEvent

// Approach from https://keyholesoftware.com/2022/07/13/cancel-a-react-modal-with-escape-key-or-external-click/
internal fun onEscapeKey(onEscapeKeyDown: () -> Unit) {
    val handler: EventHandler<KeyboardEvent> = useCallback(onEscapeKeyDown) { event ->
        if (event.code === Key.Escape) {
            onEscapeKeyDown()
        }
    }
    useEffect(handler) {
        document.addEventListener(
            type = KeyboardEvent.KEY_DOWN,
            callback = handler,
        )
        cleanup {
            document.removeEventListener(
                type = KeyboardEvent.KEY_DOWN,
                callback = handler,
            )
        }
    }
}

internal fun onScroll(
    onScroll: () -> Unit
) {
    val handler: EventHandler<*> = useCallback(onScroll) {
        onScroll()
    }
    useEffect(handler) {
        document.addEventListener(
            type = EventType("scroll"),
            callback = handler,
        )
        cleanup {
            document.removeEventListener(
                type = EventType("scroll"),
                callback = handler,
            )
        }
    }
}
