package com.taager.dukan.feature.checkout.tracking

import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.feature.checkout.CheckoutViewState
import com.taager.dukan.product.domain.entity.Product
import com.taager.numbers.Money
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

private object CheckoutScreen : Screen(name = "checkout")

internal fun AppTracker.trackScreenView(
    skuQuantities: Map<String, Int>,
) {
    trackEvent(
        ScreenViewTrackingEvent(
            screen = CheckoutScreen,
            extras = mapOf("products" to skuQuantities)
        )
    )
}

internal fun AppTracker.trackBackClick(
    productQuantities: Map<Product, Int>,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "back_click",
            extras = mapOf("products" to productQuantities.toSkuQuantities())
        )
    )
}

internal fun AppTracker.trackPaymentMethodClick(
    productQuantities: Map<Product, Int>,
    paymentMethod: PaymentMethod,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "payment_method_click",
            extras = mapOf(
                "products" to productQuantities.toSkuQuantities(),
                "payment_method" to paymentMethod.name.lowercase()
            )
        )
    )
}

internal fun AppTracker.trackPlaceOrderClick(
    productQuantities: Map<Product, Int>,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "place_order_click",
            extras = mapOf("products" to productQuantities.toSkuQuantities())
        )
    )
}

internal fun AppTracker.trackInitiateCheckout(
    products: List<CheckoutViewState.OrderDetails.Product>,
    total: Money,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "initiate_checkout",
            extras = mapOf(
                "products" to products.map { product ->
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to product.internalPrice.value,
                        "quantity" to product.quantity,
                    )
                },
                "currency" to products.first().internalPrice.currency.iso4217Code,
                "total" to total.value,
            )
        )
    )
}

internal fun AppTracker.trackCheckoutSuccess(
    products: List<CheckoutViewState.OrderDetails.Product>,
    total: Money
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "success",
            extras = mapOf(
                "products" to products.map { product ->
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to product.internalPrice.value,
                        "quantity" to product.quantity,
                    )
                },
                "currency" to products.first().internalPrice.currency.iso4217Code,
                "total" to total.value
            ),
        )
    )
}

internal fun AppTracker.trackCaptchaResponse(
    productQuantities: Map<Product, Int>,
    captchaToken: String?,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "captcha_response",
            extras = mapOf(
                "products" to productQuantities.toSkuQuantities(),
                "captcha_token" to (captchaToken ?: "null")
            )
        )
    )
}

internal fun AppTracker.trackCaptchaError(
    productQuantities: Map<Product, Int>,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "captcha_error",
            extras = mapOf(
                "products" to productQuantities.toSkuQuantities(),
            )
        )
    )
}

internal fun AppTracker.trackAddPaymentInfo(
    products: List<CheckoutViewState.OrderDetails.Product>,
    total: Money,
    paymentMethod: PaymentMethod,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutScreen,
            event = "add_payment_info",
            extras = mapOf(
                "products" to products.map { product ->
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to product.internalPrice.value,
                        "quantity" to product.quantity,
                    )
                },
                "currency" to products.first().internalPrice.currency.iso4217Code,
                "total" to total.value,
                "payment_method" to paymentMethod.toString()
            )
        )
    )
}

private fun Map<Product, Int>.toSkuQuantities(): Map<String, Int> =
    mapKeys { (product) -> product.sku }
