package com.taager.dukan.preferences.domain.di

import com.taager.dukan.preferences.domain.interactor.GetPreferencesUseCase
import com.taager.dukan.preferences.domain.interactor.SwitchColorModeUseCase
import com.taager.dukan.preferences.domain.interactor.SwitchLanguageUseCase
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object PreferencesDomainDI {
    val module = DI.Module("Preferences Domain DI") {
        bindProvider {
            GetPreferencesUseCase(
                repository = instance(),
            )
        }
        bindProvider {
            SwitchLanguageUseCase(
                repository = instance(),
            )
        }
        bindProvider {
            SwitchColorModeUseCase(
                repository = instance(),
            )
        }
    }
}
