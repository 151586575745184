package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.CheckoutForm
import com.taager.checkout.field.ExpressCheckoutForm
import com.taager.checkout.field.InputField
import com.taager.checkout.field.listenToCityQuery
import com.taager.checkout.field.transform
import com.taager.checkout.field.updateFieldValue
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.checkout.payment.toVisiblePaymentForm
import com.taager.checkout.validation.invalidFields
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.customer.domain.interactor.GetCustomerUseCase
import com.taager.dukan.customer.domain.interactor.GetDistrictsUseCase
import com.taager.dukan.customer.domain.interactor.QueryCitiesUseCase
import com.taager.dukan.customer.domain.interactor.SaveCustomerUseCase
import com.taager.dukan.feature.productdetails.ProductDetailsViewState.ExpressCheckout
import com.taager.dukan.feature.productdetails.mapper.initialExpressCheckout
import com.taager.dukan.feature.productdetails.mapper.toCustomer
import com.taager.dukan.feature.productdetails.mapper.transform
import com.taager.dukan.feature.productdetails.tracking.trackAddPaymentInfo
import com.taager.dukan.feature.productdetails.tracking.trackCaptchaError
import com.taager.dukan.feature.productdetails.tracking.trackCaptchaResponse
import com.taager.dukan.feature.productdetails.tracking.trackCheckoutSuccess
import com.taager.dukan.feature.productdetails.tracking.trackInitiateCheckout
import com.taager.dukan.feature.productdetails.tracking.trackPaymentMethodClick
import com.taager.dukan.feature.productdetails.tracking.trackPlaceOrderClick
import com.taager.dukan.order.domain.interactor.PlaceOrderUseCase
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.store.domain.interactor.GetStoreUseCase
import com.taager.tracking.AppTracker
import com.taager.translator.Translator
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.FlowPreview
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.launch

@Suppress("LongParameterList", "TooManyFunctions")
class ExpressCheckoutHandler(
    private val appTracker: AppTracker,
    private val translator: Translator,
    private val getStore: GetStoreUseCase,
    private val queryCities: QueryCitiesUseCase,
    private val getDistricts: GetDistrictsUseCase,
    private val placeOrder: PlaceOrderUseCase,
    private val getCustomer: GetCustomerUseCase,
    private val saveCustomer: SaveCustomerUseCase,
) {

    private lateinit var scope: CoroutineScope
    private lateinit var product: Product
    private lateinit var storeName: String
    private var captchaToken: String? = null
    private var isCheckoutInitiated: Boolean = false
    private val cityQueryFlow = MutableStateFlow<String?>(value = null)

    @OptIn(FlowPreview::class)
    suspend fun onInit(
        scope: CoroutineScope,
        product: Product,
        onStartQueryingCity: () -> Unit,
        onCityQueryResult: (cities: List<CheckoutForm.City>) -> Unit,
        onCityQueryError: (cause: Throwable) -> Unit,
    ): ExpressCheckout? {
        this.scope = scope
        this.product = product

        val store = getStore()
        if (!store.isExpressCheckoutEnabled) {
            return null
        }

        cityQueryFlow.listenToCityQuery { query ->
            onCityQueryUpdate(
                query = query,
                onStartQuerying = onStartQueryingCity,
                onQueryResult = onCityQueryResult,
                onQueryError = onCityQueryError
            )
        }.launchIn(scope)

        storeName = store.name

        val customer = getCustomer()
        val customerCityId = customer?.city?.id
        val customerCityName = customer?.city?.name
        val cities = queryCities(query = customerCityName ?: "").transform()
        val districts = getDistrictOrEmpty(cityId = customerCityId)

        return initialExpressCheckout(
            translator = translator,
            store = store,
            cities = cities,
            districts = districts,
            customer = customer,
        )
    }

    fun onUpdateField(
        checkoutForm: ExpressCheckoutForm,
        inputField: InputField<*>,
        newValue: Any?,
        onUpdated: (updatedCheckoutForm: ExpressCheckoutForm) -> Unit,
        onDistrictsUpdated: (districts: List<CheckoutForm.District>, selectedValue: CheckoutForm.District?) -> Unit,
        onDistrictsError: (cause: Throwable) -> Unit,
    ) {
        val updatedCheckoutForm = checkoutForm.updateFieldValue(inputField, newValue)
        onUpdated(updatedCheckoutForm)
        if (inputField is InputField.City && checkoutForm.hasDistrict) {
            val selectedCity = newValue as CheckoutForm.City?
            scope.launch {
                try {
                    val districts = getDistrictOrEmpty(cityId = selectedCity?.id)
                    val selectedValue = if (districts.count() == 1) districts.first() else null
                    onDistrictsUpdated(districts, selectedValue)
                } catch (cause: Throwable) {
                    onDistrictsError(cause)
                }
            }
        }
    }

    fun onQueryCities(query: String) {
        cityQueryFlow.value = query
    }

    fun onCaptchaResponse(token: String?) {
        captchaToken = token
        appTracker.trackCaptchaResponse(product.sku, captchaToken = token)
    }

    fun onCaptchaError() {
        appTracker.trackCaptchaError(product.sku)
    }

    fun onProceedWithCheckout(
        quantity: Int,
        checkoutForm: ExpressCheckoutForm,
        onLoading: () -> Unit,
        onSuccess: () -> Unit,
        onError: () -> Unit
    ) {
        appTracker.trackPlaceOrderClick(product.sku, quantity)
        onLoading()
        placeOrder(
            quantity,
            checkoutForm,
            onSuccess,
            onError
        )
    }

    private fun placeOrder(
        quantity: Int,
        checkoutForm: ExpressCheckoutForm,
        onSuccess: () -> Unit,
        onError: () -> Unit
    ) {
        scope.launch {
            try {
                val placeableOrder = transform(product, quantity, checkoutForm, captchaToken)
                placeOrder(placeableOrder)
                appTracker.trackCheckoutSuccess(product, quantity)
                onSuccess()
            } catch (error: Throwable) {
                appTracker.trackError(error)
                onError()
            }
        }
    }

    fun onFieldFocus(quantity: Int) {
        maybeInitiateCheckout(quantity)
    }

    private fun maybeInitiateCheckout(quantity: Int) {
        if (!isCheckoutInitiated) {
            appTracker.trackInitiateCheckout(
                product = product,
                quantity = quantity,
            )
            isCheckoutInitiated = true
        }
    }

    fun onPaymentMethodClick(
        paymentMethod: PaymentMethod,
        quantity: Int
    ) {
        maybeInitiateCheckout(quantity)
        appTracker.trackPaymentMethodClick(
            sku = product.sku,
            quantity = quantity,
            paymentMethod = paymentMethod
        )
    }

    @Suppress("LongParameterList")
    fun onOrderNowClick(
        quantity: Int,
        expressCheckout: ExpressCheckout?,
        goToCheckout: () -> Unit,
        focusCheckoutField: (inputField: InputField<*>) -> Unit,
        executeCaptcha: () -> Unit,
        showPaymentForm: (PaymentFormVisibility) -> Unit,
    ) {
        val checkoutForm = expressCheckout?.form
        if (checkoutForm == null) {
            goToCheckout()
        } else {
            val invalidFields = checkoutForm.invalidFields
            when {
                invalidFields.isNotEmpty() -> focusCheckoutField(invalidFields.first())
                else -> {
                    scope.launch {
                        saveCustomer(customer = checkoutForm.toCustomer())

                        when (expressCheckout.paymentMethod) {
                            PaymentMethod.CashOnDelivery -> executeCaptcha()
                            PaymentMethod.PayByCard -> {
                                showPaymentForm(
                                    checkoutForm.toVisiblePaymentForm(
                                        storeName = storeName,
                                        productQuantities = mapOf(product to quantity),
                                    )
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    fun onAddPaymentInfo(
        quantity: Int,
        paymentMethod: PaymentMethod
    ) {
        appTracker.trackAddPaymentInfo(
            product = product,
            quantity = quantity,
            paymentMethod = paymentMethod,
        )
    }

    private suspend fun getDistrictOrEmpty(cityId: String?): List<CheckoutForm.District> =
        if (cityId != null) {
            getDistricts(cityId = cityId).transform()
        } else {
            emptyList()
        }

    private fun onCityQueryUpdate(
        query: String,
        onStartQuerying: () -> Unit,
        onQueryResult: (cities: List<CheckoutForm.City>) -> Unit,
        onQueryError: (cause: Throwable) -> Unit
    ) {
        onStartQuerying()
        scope.launch {
            try {
                val cities = queryCities(query).transform()
                onQueryResult(cities)
            } catch (cause: Throwable) {
                onQueryError(cause)
            }
        }
    }
}
