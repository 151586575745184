package com.taager.dukan.ui.theme

internal const val CssBaselineOverrides = """
@font-face {
    font-family: 'Taager';
    font-weight: 400;
    font-style: normal;
    src: url('assets/fonts/taager/taager-regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Taager';
    font-weight: 500;
    font-style: normal;
    src: url('assets/fonts/taager/taager-medium.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Taager';
    font-weight: 700;
    font-style: normal;
    src: url('assets/fonts/taager/taager-bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Taager';
    font-weight: 800;
    font-style: normal;
    src: url('assets/fonts/taager/taager-extrabold.ttf') format('truetype');
    font-display: swap;
}
"""
