package com.taager.dukan.feature.checkout.payment

sealed interface PostPaymentViewEvent {
    data class Init(
        val sku: String,
        val encodedQuantity: String?,
        val paymentId: String?
    ) : PostPaymentViewEvent
    object BackToHomeClick : PostPaymentViewEvent
    object BackToProductClick : PostPaymentViewEvent
    data class ContactEmailClick(val email: String) : PostPaymentViewEvent
}
