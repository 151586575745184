package com.taager.api

import kotlinx.browser.window

internal class SlugProviderImpl : SlugProvider {
    override fun provideSlug(): String =
        window.location.hostname
            .split(".")
            .first()
}
