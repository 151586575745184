package com.taager.dukan.feature.home

import com.taager.dukan.language.useLanguage
import com.taager.dukan.preferences.domain.entity.Preferences
import com.taager.dukan.ui.icon.HomeLanguageIcon
import mui.material.ListItemIcon
import mui.material.MenuItem
import react.FC
import react.Props

internal val LanguageSwitcher: FC<LanguageSwitcherProps> = FC { props ->
    val language by useLanguage()
    MenuItem {
        ListItemIcon { HomeLanguageIcon() }
        +when (language) {
            Preferences.Language.English -> {
                "العربية"
            }

            Preferences.Language.Arabic -> {
                "English"
            }
        }
        onClick = { props.onClick() }
    }
}

internal external interface LanguageSwitcherProps : Props {
    var onClick: () -> Unit
}
