package com.taager.dukan.ui.component

import com.taager.dukan.ui.hooks.onScroll
import com.taager.dukan.ui.theme.useTheme
import mui.material.Paper
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.RefObject
import react.useEffectOnce
import react.useState
import web.cssom.Position
import web.cssom.integer
import web.cssom.pct
import web.cssom.px
import web.dom.Element
import web.window.window

private const val STICKY_ELEVATION = 4

internal val StickyBottomBar: FC<StickyBottomBarProps> = FC { props ->
    var isSticky by useState(false)
    useEffectOnce {
        isSticky = props.anchorRef.shouldStick()
    }
    onScroll {
        isSticky = props.anchorRef.shouldStick()
    }
    val theme = useTheme()
    Paper {
        sx {
            width = 100.pct
            if (isSticky) {
                position = Position.fixed
                left = 0.px
                right = 0.px
                bottom = 0.px
                padding = theme.spacing(2)
                zIndex = integer(theme.zIndex.appBar.toInt())
            } else {
                position = undefined
                left = undefined
                right = undefined
                bottom = undefined
                padding = undefined
                zIndex = undefined
            }
        }
        square = true
        elevation = if (isSticky) {
            STICKY_ELEVATION
        } else {
            undefined
        }
        children = props.children
    }
}

internal external interface StickyBottomBarProps : PropsWithChildren {
    var anchorRef: RefObject<Element>
}

private fun RefObject<Element>.shouldStick(): Boolean {
    val anchorRect = current?.getBoundingClientRect()
    val anchorTop = anchorRect?.top ?: 0.0
    return anchorTop > window.innerHeight
}
