package com.taager.dukan.customer.data.local.mapper

import com.taager.dukan.customer.data.local.model.CityModel
import com.taager.dukan.customer.data.local.model.DistrictModel
import com.taager.dukan.customer.data.local.model.ProvinceModel
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.District
import com.taager.dukan.customer.domain.entity.Province

internal fun City.transform(): CityModel =
    CityModel(
        id = id,
        name = name,
        province = ProvinceModel(
            id = province.id,
            name = province.name,
            cities = emptyList(), // Cities are not needed here
        ),
        districts = districts.map(District::transform),
    )

internal fun CityModel.transform(): City =
    City(
        id = id,
        name = name,
        province = Province(
            id = province.id,
            name = province.name,
            cities = emptyList(), // Cities are not needed here
        ),
        districts = districts.map(DistrictModel::transform),
    )
