package com.taager.dukan.order.domain.di

import com.taager.dukan.order.domain.interactor.PlaceOrderUseCase
import com.taager.dukan.order.domain.interactor.PlacePaidOrderUseCase
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object OrderDomainDI {
    val module = DI.Module("Order Domain DI") {
        bindProvider {
            PlaceOrderUseCase(
                repository = instance()
            )
        }
        bindProvider {
            PlacePaidOrderUseCase(
                repository = instance()
            )
        }
    }
}
