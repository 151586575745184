package com.taager.dukan.ui.component

import mui.material.CircularProgress
import mui.material.CircularProgressColor
import mui.material.Modal
import mui.material.ModalProps
import mui.system.sx
import react.FC
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.JustifyContent

internal val LoadingModal: FC<ModalProps> = FC { props ->
    Modal {
        sx {
            display = Display.flex
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
        }
        open = props.open
        CircularProgress {
            color = CircularProgressColor.inherit
        }
    }
}
