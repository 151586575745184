package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.InputField
import com.taager.dukan.checkout.domain.entity.PaymentMethod

sealed interface ProductDetailsViewEvent {
    data class Init(val sku: String) : ProductDetailsViewEvent
    data class MediaClick(val index: Int) : ProductDetailsViewEvent
    object OrderNowClick : ProductDetailsViewEvent
    object BackClick : ProductDetailsViewEvent
    data class FullscreenImageClick(val url: String) : ProductDetailsViewEvent
    object ExitFullscreenClick : ProductDetailsViewEvent
    object ExitFullscreenKeyDown : ProductDetailsViewEvent

    // Express checkout events

    data class UpdateCheckoutField(
        val inputField: InputField<*>,
        val newValue: Any?
    ) : ProductDetailsViewEvent

    data class QueryCities(val query: String) : ProductDetailsViewEvent
    data class CaptchaResponse(val token: String?) : ProductDetailsViewEvent
    object CaptchaError : ProductDetailsViewEvent
    object ProceedWithCheckout : ProductDetailsViewEvent
    object CheckoutFieldFocus : ProductDetailsViewEvent
    data class PricingOptionClick(
        val pricingOption: ProductDetailsViewState.Product.PricingOption
    ) : ProductDetailsViewEvent
    data class PaymentMethodClick(val paymentMethod: PaymentMethod) : ProductDetailsViewEvent
    object PaymentFormCompleted : ProductDetailsViewEvent
    object ClosePaymentForm : ProductDetailsViewEvent
}
