package com.taager.dukan.store.data.local.model

import com.taager.translator.TranslatableString
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class QuickLinkModel(
    @SerialName("id")
    val id: String,
    @SerialName("title")
    val title: TranslatableString,
    @SerialName("headline")
    val headline: TranslatableString,
    @SerialName("content")
    val content: TranslatableString,
)
