package com.taager.dukan.ui.component

import com.taager.dukan.ui.theme.useTheme
import mui.icons.material.KeyboardBackspaceRounded
import mui.material.Button
import mui.material.ButtonVariant.Companion.text
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.create
import web.cssom.Length

internal val BackButton = FC<BackButtonProps> { props ->
    val theme = useTheme()
    Button {
        sx {
            width = Length.fitContent
            color = theme.palette.text.secondary
        }
        variant = text
        startIcon = KeyboardBackspaceRounded.create {
            sx {
                flipOnRTL()
            }
        }
        +props.text
        onClick = { props.onClick() }
    }
}

internal external interface BackButtonProps : PropsWithSx {
    var text: String
    var onClick: () -> Unit
}
