package com.taager.dukan.feature.home

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.rtl.isRTL
import com.taager.dukan.ui.theme.isLight
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.icons.material.DarkModeOutlined
import mui.icons.material.LightModeOutlined
import mui.material.ListItemIcon
import mui.material.Menu
import mui.material.MenuItem
import mui.material.PopoverReference
import react.FC
import react.Props
import web.dom.Element

internal val MoreMenu: FC<MoreMenuProps> = FC { props ->
    val anchor = props.anchor
    val theme = useTheme()
    Menu {
        open = anchor != null
        onClose = { props.onClose() }

        anchorReference = PopoverReference.anchorEl
        anchorOrigin = jso {
            vertical = "bottom"
            horizontal = if (isRTL) {
                "left"
            } else {
                "right"
            }
        }
        anchorEl = if (anchor != null) {
            { anchor }
        } else {
            undefined
        }

        MenuItem {
            ListItemIcon {
                if (theme.isLight) {
                    DarkModeOutlined()
                } else {
                    LightModeOutlined()
                }
            }
            +if (theme.isLight) {
                "home_color_mode_dark".i18n()
            } else {
                "home_color_mode_light".i18n()
            }
            onClick = { props.onSwitchColorModeClick() }
        }
        LanguageSwitcher {
            onClick = { props.onSwitchLanguageClick() }
        }
    }
}

internal external interface MoreMenuProps : Props {
    var anchor: Element?
    var onClose: () -> Unit
    var onSwitchLanguageClick: () -> Unit
    var onSwitchColorModeClick: () -> Unit
}
