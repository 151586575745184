package com.taager.checkout.validation

import com.taager.checkout.field.InputField

val InputField<*>.hasError: Boolean
    get() = value.isStringNotBlank() && !isValid

internal val InputField<*>?.isValid: Boolean
    get() = when (this) {
        is InputField.FullName -> value.length > 2
        is InputField.PhoneNumber -> regex.matches(value)
        is InputField.AlternativePhoneNumber -> value.isBlank() || regex.matches(value)
        is InputField.Email -> value.isBlank() || regex.matches(value)
        is InputField.City -> value != null
        is InputField.District -> value != null
        is InputField.Address -> value.isNotBlank()
        is InputField.Notes -> true
        else -> true
    }

private fun Any?.isStringNotBlank(): Boolean =
    (this as? String)?.isNotBlank() == true
