package com.taager.dukan.customer.domain.di

import com.taager.dukan.customer.domain.interactor.GetCustomerUseCase
import com.taager.dukan.customer.domain.interactor.GetDistrictsUseCase
import com.taager.dukan.customer.domain.interactor.QueryCitiesUseCase
import com.taager.dukan.customer.domain.interactor.SaveCustomerUseCase
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object CustomerDomainDI {
    val module = DI.Module("Customer Domain DI") {
        bindProvider {
            QueryCitiesUseCase(
                getStore = instance(),
                repository = instance(),
            )
        }
        bindProvider {
            GetDistrictsUseCase(
                getStore = instance(),
                repository = instance(),
            )
        }
        bindProvider {
            GetCustomerUseCase(
                repository = instance(),
            )
        }
        bindProvider {
            SaveCustomerUseCase(
                repository = instance(),
            )
        }
    }
}
