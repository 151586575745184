package com.taager.dukan.ui.component

import com.taager.dukan.feature.productlist.ProductListViewState
import com.taager.dukan.i18n.i18n
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.ui.css.CssClass
import com.taager.dukan.ui.theme.isMobile
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.material.Card
import mui.material.CardActionArea
import mui.material.CardContent
import mui.material.CardMedia
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.Stack
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.Breakpoint
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.video
import web.cssom.Display
import web.cssom.FontWeight.Companion.bold
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.Overflow
import web.cssom.TextOverflow
import web.cssom.TextTransform
import web.cssom.number
import web.cssom.pct

internal val ProductCard = FC<ProductCardProps> { props ->
    val theme = useTheme()
    Card {
        sx {
            width = 100.pct
        }
        CardActionArea {
            CardMedia {
                sx {
                    width = 100.pct
                    aspectRatio = number(1.0)
                }
                val media = props.media
                component = when (media) {
                    is Product.Media.Image -> img
                    is Product.Media.Video -> video
                }
                image = when (media) {
                    is Product.Media.Image -> if (theme.isMobile) {
                        media.medium
                    } else {
                        media.large
                    }

                    is Product.Media.Video -> media.url
                }
                asDynamic().alt = "" // Media is described by the text element below
            }
            CardContent {
                Stack {
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                    }
                    spacing = responsive(1)

                    Typography {
                        sx {
                            height = responsive(
                                Breakpoint.xs to theme.spacing(5),
                                Breakpoint.sm to theme.spacing(6)
                            )
                            overflow = Overflow.hidden
                            textOverflow = TextOverflow.ellipsis
                        }
                        variant = TypographyVariant.body2
                        +props.name
                    }
                    Stack {
                        sx {
                            height = responsive(
                                Breakpoint.xs to theme.spacing(8),
                                Breakpoint.sm to theme.spacing(10)
                            )
                        }
                        if (props.isSoldOut) {
                            Typography {
                                sx {
                                    minWidth = Length.fitContent
                                    color = theme.palette.text.secondary
                                    fontWeight = bold
                                    textTransform = TextTransform.uppercase
                                }
                                variant = TypographyVariant.body1
                                +"product_availability_sold_out".i18n()
                            }
                        } else {
                            Typography {
                                sx {
                                    color = theme.palette.text.secondary
                                }
                                className = CssClass.PreformattedTextSingleLine
                                variant = TypographyVariant.caption

                                dangerouslySetInnerHTML = jso {
                                    __html = props.pricing.name
                                }
                            }
                            Typography {
                                sx {
                                    fontWeight = bold
                                }
                                variant = TypographyVariant.body1
                                +props.pricing.price
                            }
                            Typography {
                                sx {
                                    color = theme.palette.text.secondary
                                }
                                className = CssClass.PreformattedTextSingleLine
                                variant = TypographyVariant.caption

                                dangerouslySetInnerHTML = jso {
                                    __html = props.pricing.description ?: "<br/>"
                                }
                            }
                        }
                    }
                }
            }
            FakeOutlinedButton {
                sx {
                    display = Display.block
                    width = 100.pct
                }
                +"products_card_view_product_button".i18n()
            }
        }
        onClick = { props.onClick() }
    }
}

internal external interface ProductCardProps : PropsWithSx {
    var name: String
    var pricing: ProductListViewState.Product.Pricing
    var media: Product.Media
    var isSoldOut: Boolean
    var onClick: () -> Unit
}

internal val ProductCardSkeleton = FC<Props> {
    Stack {
        sx {
            width = 100.pct
        }
        spacing = responsive(2)

        Skeleton {
            sx {
                aspectRatio = number(1.0)
            }
            height = 100.pct
            variant = SkeletonVariant.rounded
        }

        Skeleton {
            variant = SkeletonVariant.text
        }

        Skeleton {
            variant = SkeletonVariant.rectangular
        }
    }
}
