package com.taager.dukan.ui.component

import com.taager.dukan.ui.rtl.isRTL
import web.cssom.BorderRadius
import web.cssom.Display
import web.cssom.Globals
import web.cssom.Length
import web.cssom.LengthProperty
import web.cssom.Overflow
import web.cssom.StandardLonghandProperties
import web.cssom.StandardProperties
import web.cssom.TextOverflow
import web.cssom.WhiteSpace
import web.cssom.scalex

internal inline var StandardLonghandProperties.paddingHorizontal: LengthProperty
    get() = error("Write-only property")
    set(value) {
        paddingLeft = value
        paddingRight = value
    }
internal inline var StandardLonghandProperties.paddingVertical: LengthProperty
    get() = error("Write-only property")
    set(value) {
        paddingTop = value
        paddingBottom = value
    }

internal inline var StandardLonghandProperties.marginInlineHorizontal: LengthProperty
    get() = error("Write-only property")
    set(value) {
        marginInlineStart = value
        marginInlineEnd = value
    }

internal inline var StandardLonghandProperties.marginHorizontal: LengthProperty
    get() = error("Write-only property")
    set(value) {
        marginLeft = value
        marginRight = value
    }

internal inline var StandardLonghandProperties.marginVertical: LengthProperty
    get() = error("Write-only property")
    set(value) {
        marginTop = value
        marginBottom = value
    }

internal inline val BorderRadius.px: Length
    get() = "${this}px".unsafeCast<Length>()

internal fun StandardLonghandProperties.fitToParent() {
    display = Display.flex
    height = Globals.inherit
    width = Globals.inherit
}

internal fun StandardLonghandProperties.flipOnRTL() {
    if (isRTL) {
        transform = scalex(-1)
    }
}

internal fun StandardProperties.singleLine() {
    textOverflow = TextOverflow.ellipsis
    overflow = Overflow.hidden
    whiteSpace = WhiteSpace.nowrap
}
