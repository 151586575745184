package com.taager.dukan.initializer

import com.taager.featuremanager.FeatureManager
import kotlin.js.Promise

internal class FeatureManagerInitializer(
    private val featureManager: FeatureManager,
) : Initializer {
    override fun initialize(): Promise<Boolean> {
        featureManager.initialize()
        return Promise.resolve(true)
    }
}
