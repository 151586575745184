package com.taager.tracking.tiktok

import com.taager.tracking.event.TrackingEvent

internal fun TrackingEvent.transform(): TikTokEvent? =
    when (name) {
        "product_details_view_content" -> {
            TikTokEvent.ViewContent.Product(
                id = properties["sku"] as String,
                name = properties["product_name"] as String,
                price = properties["product_price"] as Double,
                currency = properties["currency"] as String,
                value = properties["total"] as Double,
            )
        }

        "checkout_initiate_checkout",
        "product_details_initiate_checkout" -> TikTokEvent.InitiateCheckout

        "checkout_add_payment_info",
        "product_details_add_payment_info" -> TikTokEvent.AddPaymentInfo

        "post_payment_complete_payment" -> TikTokEvent.CompletePayment(
            products = properties["products"].asCheckoutProducts(),
            currency = properties["currency"] as String,
            value = properties["total"] as Double,
        )

        "checkout_success",
        "product_details_checkout_success" -> TikTokEvent.PlaceAnOrder(
            products = properties["products"].asCheckoutProducts(),
            currency = properties["currency"] as String,
            value = properties["total"] as Double,
        )

        else -> null
    }

// We want to fail fast if the types are not correctly mapped
@Suppress("UNCHECKED_CAST")
private fun Any?.asCheckoutProducts(): List<TikTokEvent.Checkout.Product> =
    (this as List<Map<String, *>>).map { product ->
        TikTokEvent.Checkout.Product(
            id = product["sku"] as String,
            name = product["product_name"] as String,
            price = product["product_price"] as Double,
            quantity = product["quantity"] as Int,
        )
    }
