package com.taager.dukan.order.domain.interactor

import com.taager.dukan.order.domain.OrderRepository
import com.taager.dukan.order.domain.entity.PlaceableOrder

class PlaceOrderUseCase(
    private val repository: OrderRepository
) {

    suspend operator fun invoke(placeableOrder: PlaceableOrder) {
        repository.placeOrder(placeableOrder)
    }
}
