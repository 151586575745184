package com.taager.dukan.feature.checkout.success

import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.i18n.i18n
import com.taager.dukan.navigation.openMailTo
import com.taager.dukan.ui.component.BackButton
import com.taager.dukan.ui.component.TextButtonLink
import com.taager.dukan.ui.component.paddingHorizontal
import com.taager.dukan.ui.component.paddingVertical
import com.taager.dukan.ui.theme.extraBold
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Stack
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.router.dom.useSearchParams
import react.router.useLocation
import react.router.useNavigate
import react.useEffect
import web.cssom.AlignItems
import web.cssom.FontWeight
import web.cssom.WhiteSpace
import web.cssom.pct

internal val CheckoutSuccessScreen: FC<Props> = FC {
    mainDI().composePresenter<CheckoutSuccessPresenter, CheckoutSuccessViewState> { presenter, viewState ->

        val location = useLocation()
        val key = location.key

        val (searchParams) = useSearchParams()
        val hasPrepaid = searchParams.has("p")

        useEffect(key) {
            presenter.onEvent(CheckoutSuccessViewEvent.Init(key))
        }

        Content {
            isPrepaid = hasPrepaid
            state = viewState
            onEvent = presenter::onEvent
        }

        val navigate = useNavigate()

        presenter.onSideEffect { effect ->
            when (effect) {
                CheckoutSuccessSideEffect.GoToHome ->
                    navigate(
                        to = "/",
                        options = jso {
                            replace = true
                        }
                    )

                is CheckoutSuccessSideEffect.OpenMailTo ->
                    openMailTo(effect.email)
            }
        }
    }
}

private external interface ContentProps :
    ViewStateProps<CheckoutSuccessViewState>,
    ViewEventProps<CheckoutSuccessViewEvent> {
    var isPrepaid: Boolean
}

private val Content: FC<ContentProps> = FC { props ->

    BackButton {
        text = "home_back_button".i18n()
        onClick = { props.onEvent(CheckoutSuccessViewEvent.BackToHomeClick) }
    }

    // Moving the calling of i18n strings out of the `when` statement
    // to avoid this warning:
    // > React detected a change in the order of Hooks called by null.
    // > This will lead to bugs and errors if not fixed.
    val successMessage = "checkout_success_message".i18n()
    val prepaidSuccessMessage = "checkout_paid_success_message".i18n()

    when (val state = props.state) {
        CheckoutSuccessViewState.Loading -> Unit

        is CheckoutSuccessViewState.Loaded ->
            Loaded {
                message = if (props.isPrepaid) {
                    prepaidSuccessMessage
                } else {
                    successMessage
                }
                email = state.contactEmail
                onEmailClick = { email ->
                    props.onEvent(CheckoutSuccessViewEvent.ContactEmailClick(email))
                }
            }
    }
}

private external interface SuccessProps : Props {
    var message: String
    var email: String?
    var onEmailClick: (email: String) -> Unit
}

private val Loaded: FC<SuccessProps> = FC { props ->

    val theme = useTheme()
    Stack {
        sx {
            paddingVertical = theme.spacing(3)
            alignItems = AlignItems.center
        }
        spacing = responsive(2)

        img {
            src = "assets/images/checkout_success_image.svg"
        }
        Typography {
            sx {
                fontWeight = FontWeight.extraBold
            }
            variant = TypographyVariant.h3
            align = TypographyAlign.center
            +"checkout_success_headline".i18n()
        }
        Box {
            sx {
                backgroundColor = theme.palette.primary.light
                paddingVertical = theme.spacing(1)
                paddingHorizontal = theme.spacing(2)
            }
            Typography {
                sx {
                    whiteSpace = WhiteSpace.preLine
                    fontWeight = FontWeight.medium
                    color = theme.palette.text.secondary
                }
                variant = TypographyVariant.body2
                align = TypographyAlign.center
                +props.message
            }
        }
        props.email?.let { email ->
            Stack {
                sx {
                    alignItems = AlignItems.center
                }

                Typography {
                    sx {
                        fontWeight = FontWeight.medium
                        color = theme.palette.text.secondary
                    }
                    variant = TypographyVariant.body2
                    align = TypographyAlign.center
                    +"checkout_success_contact".i18n()
                }
                TextButtonLink {
                    +email
                    onClick = { props.onEmailClick(email) }
                }
            }
        }
        val navigate = useNavigate()
        Button {
            sx {
                width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
            }
            variant = ButtonVariant.contained
            +"home_back_button".i18n()
            onClick = {
                navigate(
                    to = "/",
                    options = jso {
                        replace = true
                    }
                )
            }
        }
    }
}
