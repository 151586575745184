package com.taager.dukan.feature.home

import com.taager.dukan.preferences.domain.entity.Preferences

sealed class HomeSideEffect {
    object GoToStoreNotFound : HomeSideEffect()
    data class GoToQuickLink(val id: String) : HomeSideEffect()
    object GoToHome : HomeSideEffect()
    data class RefreshLanguage(val language: Preferences.Language) : HomeSideEffect()
    data class RefreshColorMode(val colorMode: Preferences.ColorMode) : HomeSideEffect()
}
