package com.taager.tracking.di

import com.taager.tracking.AppTracker
import com.taager.tracking.AppTrackerImpl
import com.taager.tracking.Tracker
import org.kodein.di.DI
import org.kodein.di.bindSet
import org.kodein.di.bindSingleton
import org.kodein.di.instance

object TrackingDI {
    val module = DI.Module("Tracking DI") {
        bindSet<Tracker>()
        import(InternalTrackingDI.module)
        bindSingleton<AppTracker> {
            AppTrackerImpl(
                trackers = instance()
            )
        }
    }
}
