package com.taager.dukan.customer.data.remote

import com.taager.dukan.customer.data.remote.response.GetProvincesResponse
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get

private const val GET_PROVINCES_PATH = "api/provinces"

internal class CustomerApi(
    private val client: HttpClient,
) {

    suspend fun getProvinces(): GetProvincesResponse =
        client.get(GET_PROVINCES_PATH)
            .body()
}
