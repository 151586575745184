package com.taager.tracking

import com.taager.tracking.event.TrackingEvent

/**
 * Proxy class that wraps all trackers
 */
internal class AppTrackerImpl(
    private val trackers: Set<Tracker>
) : AppTracker {

    override fun initialize() {
        trackers.forEach(Tracker::initialize)
    }

    override fun trackEvent(event: TrackingEvent) {
        trackers.forEach { tracker ->
            tracker.trackEvent(event)
        }
    }

    override fun trackError(throwable: Throwable) {
        trackers.forEach { tracker ->
            tracker.trackError(throwable)
        }
    }

    override fun identifyUser(userId: String) {
        trackers.forEach { tracker ->
            tracker.identifyUser(userId)
        }
    }

    override fun setUserProperty(key: String, value: String) {
        trackers.forEach { tracker ->
            tracker.setUserProperty(key, value)
        }
    }

    override fun clearUserProperty(key: String) {
        trackers.forEach { tracker ->
            tracker.clearUserProperty(key)
        }
    }

    override fun setEventProperty(key: String, value: String) {
        trackers.forEach { tracker ->
            tracker.setEventProperty(key, value)
        }
    }

    override fun clearEventProperty(key: String) {
        trackers.forEach { tracker ->
            tracker.clearEventProperty(key)
        }
    }

    override fun reset() {
        trackers.forEach(Tracker::reset)
    }
}
