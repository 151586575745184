package com.taager.dukan.ui.rtl

import com.taager.dukan.language.useLanguage
import com.taager.dukan.ui.rtl.external.Prefixer
import com.taager.dukan.ui.rtl.external.RtlPlugin
import emotion.cache.createCache
import emotion.react.CacheProvider
import emotion.utils.EmotionCache
import js.core.jso
import mui.system.Direction
import react.FC
import react.PropsWithChildren
import web.dom.document

internal val isRTL: Boolean
    get() = document.dir == "rtl"

internal val isLTR: Boolean
    get() = !isRTL

private val CacheRtl: EmotionCache = createCache(
    jso {
        key = "rtl"
        stylisPlugins = arrayOf(Prefixer, RtlPlugin)
    }
)

internal val SupportRTL: FC<PropsWithChildren> = FC { props ->
    val language by useLanguage()
    if (language.direction == Direction.rtl.toString()) {
        CacheProvider {
            value = CacheRtl
            +props.children
        }
    } else {
        +props.children
    }
}
