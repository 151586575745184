package com.taager.dukan.initializer.di

import com.taager.dukan.initializer.AppTrackerInitializer
import com.taager.dukan.initializer.FeatureManagerInitializer
import com.taager.dukan.initializer.Initializer
import org.kodein.di.DI
import org.kodein.di.bindSet
import org.kodein.di.instance
import org.kodein.di.provider

internal object InitializerDI {
    val module = DI.Module("Initializer DI") {
        bindSet<Initializer> {
            add {
                provider {
                    AppTrackerInitializer(
                        appTracker = instance(),
                        slugProvider = instance(),
                    )
                }
            }
            add {
                provider {
                    FeatureManagerInitializer(
                        featureManager = instance(),
                    )
                }
            }
        }
    }
}
