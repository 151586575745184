package com.taager.checkout.validation

import com.taager.checkout.field.DefaultCheckoutForm
import com.taager.checkout.field.ExpressCheckoutForm
import com.taager.checkout.field.InputField

val DefaultCheckoutForm.isValid: Boolean
    get() = fullName.isValid &&
        phoneNumber.isValid &&
        alternativePhoneNumber.isValid &&
        email.isValid &&
        city.isValid &&
        (hasDistrict.not() || district.isValid) &&
        address.isValid &&
        notes.isValid

val ExpressCheckoutForm.invalidFields: List<InputField<*>>
    get() = listOfNotNull(
        fullName,
        phoneNumber,
        city,
        address,
        if (hasDistrict) district else null,
    ).filterNot(InputField<*>::isValid)
