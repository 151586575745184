package com.taager.dukan.customer.domain.entity

data class Customer(
    val fullName: String,
    val phoneNumber: String,
    val alternativePhoneNumber: String?,
    val email: String?,
    val province: Province,
    val city: City,
    val district: District?,
    val address: String,
)
