package com.taager.dukan.customer.data.remote.mapper

import com.taager.dukan.customer.data.remote.response.GetProvincesResponse
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.District
import com.taager.dukan.customer.domain.entity.Province

internal fun GetProvincesResponse.transform(): List<Province> =
    provinces.map { province ->
        Province(
            id = province.id,
            name = province.name,
            cities = province.zones.map { zone ->
                City(
                    id = zone.id,
                    name = zone.name,
                    province = Province(
                        id = province.id,
                        name = province.name,
                        cities = emptyList(), // Cities are not needed here
                    ),
                    districts = zone.districts.map { district ->
                        District(
                            id = district.id,
                            name = district.name,
                        )
                    }.sortedBy(District::name)
                )
            }.sortedBy(City::name)
        )
    }.sortedBy(Province::name)
