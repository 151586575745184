package com.taager.dukan.feature.productdetails

import com.taager.dukan.ui.component.paddingVertical
import com.taager.dukan.ui.theme.useTheme
import mui.material.Box
import mui.material.Divider
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useState
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import kotlin.random.Random

private const val IMAGE_LIST_COUNT = 3
private const val TEXT_COUNT = 5

internal val ProductDetailsLoading: FC<Props> = FC {
    val theme = useTheme()
    Stack {
        sx {
            paddingVertical = theme.spacing(2)
        }
        direction = responsive(Breakpoint.xs to StackDirection.column, Breakpoint.sm to StackDirection.row)
        spacing = responsive(2)

        Stack {
            sx {
                width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
            }
            direction = responsive(Breakpoint.xs to StackDirection.columnReverse, Breakpoint.sm to StackDirection.row)
            spacing = responsive(2)

            Stack {
                sx {
                    minHeight = 1.px
                }
                direction = responsive(Breakpoint.xs to StackDirection.row, Breakpoint.sm to StackDirection.column)
                spacing = responsive(2)

                @Suppress("UnusedPrivateMember")
                for (i in 1..IMAGE_LIST_COUNT) {
                    Box {
                        sx {
                            width = theme.spacing(10)
                            aspectRatio = number(1.0)
                        }
                        Skeleton {
                            height = 100.pct
                            variant = SkeletonVariant.rounded
                        }
                    }
                }
            }
            Box {
                sx {
                    width = 100.pct
                    aspectRatio = number(1.0)
                }
                Skeleton {
                    height = 100.pct
                    variant = SkeletonVariant.rounded
                }
            }
        }

        Stack {
            sx {
                width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
            }
            spacing = responsive(1)

            @Suppress("UnusedPrivateMember")
            for (i in 1..TEXT_COUNT) {
                val width by useState { Random.nextInt(from = 25, until = 75) }
                Skeleton {
                    sx {
                        this.width = width.pct
                    }
                    variant = SkeletonVariant.text
                }
            }
            Divider()
            Skeleton {
                sx {
                    width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
                }
                variant = SkeletonVariant.rounded
            }
        }
    }
}
