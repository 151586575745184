package com.taager.checkout.payment

import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.store.domain.entity.Store

fun getAvailablePaymentMethods(
    payments: Store.Payments,
): List<PaymentMethod> =
    buildList {
        if (payments.isPrepaidEnabled) {
            add(PaymentMethod.PayByCard)
        }
        add(PaymentMethod.CashOnDelivery)
    }
