package com.taager.dukan.checkout.data.remote.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class CalculateCostRequest(
    @SerialName("province")
    val province: String?,
    @SerialName("products")
    val products: List<Product>,
    @SerialName("paymentMethod")
    val paymentMethod: PaymentMethod,
) {
    @Serializable
    data class Product(
        @SerialName("sku")
        val sku: String,
        @SerialName("quantity")
        val quantity: Int,
    )

    @Serializable
    enum class PaymentMethod {

        @SerialName("cod")
        CashOnDelivery,

        @SerialName("prepaid")
        Prepaid,
    }
}
