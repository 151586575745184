package com.taager.dukan.ui.component

import com.taager.dukan.ui.theme.useTheme
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Link
import mui.material.LinkUnderline
import react.FC
import react.PropsWithChildren
import react.asStringOrNull
import react.dom.html.AnchorHTMLAttributes
import web.html.HTMLAnchorElement

internal val TextButtonLink: FC<LinkTextButtonProps> = FC { props ->
    val content = props.children?.asStringOrNull() ?: ""
    val theme = useTheme()
    Link {
        component = Button
        underline = LinkUnderline.none
        color = theme.palette.info.main
        variant = ButtonVariant.text
        +content
        this.onClick = props.onClick
    }
}

internal external interface LinkTextButtonProps :
    AnchorHTMLAttributes<HTMLAnchorElement>,
    PropsWithChildren
