package com.taager.dukan.customer.domain.interactor

import com.taager.dukan.customer.domain.CustomerRepository
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.store.domain.interactor.GetStoreUseCase

private const val MAX_RESULT_LIMIT = Int.MAX_VALUE

class QueryCitiesUseCase(
    private val getStore: GetStoreUseCase,
    private val repository: CustomerRepository,
) {

    suspend operator fun invoke(query: String): List<City> =
        repository.queryCities(
            country = getStore().country,
            query = query,
            resultLimit = MAX_RESULT_LIMIT
        )
}
