package com.taager.dukan.feature.productlist

import com.taager.dukan.product.domain.entity.Product.Media
import com.taager.numbers.Money

sealed class ProductListViewState {
    object Loading : ProductListViewState()

    data class Loaded(val products: List<Product>) : ProductListViewState()

    object Empty : ProductListViewState()

    data class Product(
        val sku: String,
        val name: String,
        val pricing: Pricing,
        val media: Media,
        val isSoldOut: Boolean,
    ) {

        data class Pricing(
            val name: String,
            val price: String,
            val description: String?,
            internal val internalPrice: Money,
            internal val internalQuantity: Int,
        )
    }

    object Error : ProductListViewState()
    companion object {
        val Initial: ProductListViewState = Loading
    }
}
