package com.taager.translator

import com.taager.country.model.Country
import com.taager.dukan.preferences.domain.entity.Preferences
import com.taager.dukan.preferences.domain.interactor.GetPreferencesUseCase

class Translator(
    private val getPreferences: GetPreferencesUseCase
) {

    fun translate(translatableString: TranslatableString): String =
        when (getPreferences().language) {
            Preferences.Language.Arabic -> translatableString.ar
            Preferences.Language.English -> translatableString.en
        }

    fun translate(country: Country): String =
        when (getPreferences().language) {
            Preferences.Language.Arabic -> country.arabicName
            Preferences.Language.English -> country.englishName
        }
}
