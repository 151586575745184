package com.taager.dukan.feature.productlist

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.FakeOutlinedButton
import com.taager.dukan.ui.theme.isDark
import com.taager.dukan.ui.theme.useTheme
import mui.material.ImageListItem
import mui.material.Stack
import mui.material.StackDirection.Companion.row
import mui.material.Typography
import mui.material.styles.TypographyVariant.Companion.h2
import mui.material.styles.TypographyVariant.Companion.h3
import mui.system.Breakpoint.Companion.sm
import mui.system.Breakpoint.Companion.xs
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import web.cssom.AlignItems
import web.cssom.BackgroundRepeat.Companion.noRepeat
import web.cssom.BackgroundSize.Companion.cover
import web.cssom.BlendMode
import web.cssom.FontWeight.Companion.bold
import web.cssom.ObjectFit.Companion.fill
import web.cssom.pct
import web.cssom.px
import web.cssom.url

internal val ProductListBanner: FC<Props> = FC {
    val theme = useTheme()
    Stack {
        sx {
            backgroundImage = responsive(
                xs to url("assets/images/product_list_banner_bg_xs.svg"),
                sm to url("assets/images/product_list_banner_bg.svg"),
            )
            backgroundRepeat = noRepeat
            backgroundSize = cover
            if (theme.isDark) {
                backgroundColor = theme.palette.text.secondary
                backgroundBlendMode = BlendMode.difference
            }
            alignItems = AlignItems.center
            width = 100.pct
            padding = theme.spacing(2)
            borderRadius = 10.px
        }
        direction = responsive(row)
        spacing = responsive(1)

        Stack {
            Typography {
                variant = h3
                +"product_list_banner_text1".i18n()
            }
            Typography {
                sx {
                    fontWeight = bold
                }
                variant = h2
                +"product_list_banner_text2".i18n()
            }
            FakeOutlinedButton {
                sx {
                    maxWidth = theme.spacing(30)
                    margin = theme.spacing(2)
                }
                +"product_list_banner_text3".i18n()
            }
        }
        ImageListItem {
            sx {
                width = 255.px
                objectFit = fill
            }
            img {
                src = "assets/images/product_list_banner_image.png"
                alt = "" // Decorative element
            }
        }
    }
}
