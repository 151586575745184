package com.taager.dukan.feature.productdetails

import com.taager.dukan.ui.component.external.TransformComponent
import com.taager.dukan.ui.component.external.TransformWrapper
import com.taager.dukan.ui.theme.useTheme
import emotion.react.css
import js.core.jso
import mui.icons.material.ZoomInMapRounded
import mui.material.Box
import mui.material.Fab
import mui.material.FabColor
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import web.cssom.AlignItems
import web.cssom.AlignSelf
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.JustifySelf
import web.cssom.ObjectFit
import web.cssom.Position
import web.cssom.pct
import kotlin.math.roundToInt

internal val ZoomableImage: FC<ZoomableImageProps> = FC { props ->
    val theme = useTheme()
    Box {
        sx {
            width = 100.pct
            height = 100.pct
            display = Display.grid
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            position = Position.relative
            cursor = if (props.zooming) {
                Cursor.grab
            } else {
                undefined
            }
        }
        TransformWrapper {
            sx {
                width = 100.pct
                height = 100.pct
            }
            centerOnInit = true
            initialScale = 1f
            doubleClick = jso { mode = "reset" }
            panning = jso {
                disabled = !props.zooming
            }
            onZoomStop = { event ->
                val isZooming = event.state.scale.roundToInt() > 1f
                props.onZoom(isZooming)
            }
            TransformComponent {
                img {
                    css {
                        objectFit = ObjectFit.contain
                        width = 100.pct
                        height = 100.pct
                    }
                    src = props.image
                }
            }
        }
        Fab {
            sx {
                alignSelf = AlignSelf.end
                justifySelf = JustifySelf.end
                position = Position.absolute
                margin = theme.spacing(2)
            }
            color = FabColor.primary
            ZoomInMapRounded()
            onClick = { props.onExitClick() }
        }
    }
}

internal external interface ZoomableImageProps : Props {
    var image: String
    var zooming: Boolean
    var onZoom: (isZooming: Boolean) -> Unit
    var onExitClick: () -> Unit
}
