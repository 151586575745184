package com.taager.dukan.feature.checkout.mapper

import com.taager.checkout.field.CheckoutForm
import com.taager.checkout.field.DefaultCheckoutForm
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.dukan.checkout.domain.entity.Checkout
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.Customer
import com.taager.dukan.customer.domain.entity.District
import com.taager.dukan.customer.domain.entity.Province
import com.taager.dukan.feature.checkout.CheckoutViewState
import com.taager.dukan.feature.checkout.CheckoutViewState.OrderDetails
import com.taager.dukan.order.domain.entity.PlaceableOrder
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.store.domain.entity.Store
import com.taager.numbers.Money
import com.taager.translator.Translator

@Suppress("LongParameterList")
internal fun initialLoadedState(
    translator: Translator,
    store: Store,
    cities: List<CheckoutForm.City>,
    districts: List<CheckoutForm.District>,
    customer: Customer?,
    orderDetailsProducts: List<OrderDetails.Product>,
    checkout: Checkout,
    availablePaymentMethods: List<PaymentMethod>,
    selectedPaymentMethod: PaymentMethod,
): CheckoutViewState.Loaded {
    val country = store.country
    return CheckoutViewState.Loaded(
        deliverTo = translator.translate(country),
        form = DefaultCheckoutForm.initial(
            country = country,
            cities = cities,
            districts = districts,
            customer = customer,
        ),
        orderDetails = OrderDetails(
            products = orderDetailsProducts,
            subtotal = OrderDetails.Calculation.Loaded(
                result = checkout.itemsSubTotal.toString()
            ),
            shippingCost = checkout.shippingCost.transform(),
            codFee = checkout.codFee.transform(),
            total = OrderDetails.Calculation.Loaded(
                result = checkout.totalCost.toString()
            ),
            internalTotal = checkout.totalCost,
        ),
        paymentMethod = selectedPaymentMethod,
        hasPaymentMethodError = false,
        paymentFormVisibility = PaymentFormVisibility.Hidden,
        isPlacingOrder = false,
        isPlaceOrderButtonEnabled = false,
        availablePaymentMethods = availablePaymentMethods,
    )
}

internal fun Map<Product, Int>.transform(): List<OrderDetails.Product> =
    map { (product, quantity) ->
        val pricingOption = product.pricingOptionForQuantity(quantity)
        val price = pricingOption.price
        OrderDetails.Product(
            sku = product.sku,
            media = product.medias.first(),
            name = product.name,
            priceName = pricingOption.name,
            priceContent = price.toString(),
            internalPrice = price,
            quantity = quantity,
            availability = product.availability.transform(),
        )
    }

internal fun List<OrderDetails.Product>.transform(): List<Checkout.Product> =
    map { product ->
        Checkout.Product(
            sku = product.sku,
            price = product.internalPrice,
            quantity = product.quantity,
        )
    }

internal fun OrderDetails.transform(
    checkout: Checkout,
): OrderDetails =
    copy(
        subtotal = OrderDetails.Calculation.Loaded(
            result = checkout.itemsSubTotal.toString()
        ),
        shippingCost = checkout.shippingCost.transform(),
        codFee = checkout.codFee.transform(),
        total = OrderDetails.Calculation.Loaded(
            result = checkout.totalCost.toString()
        )
    )

internal fun CheckoutViewState.Loaded.transform(captchaToken: String?): PlaceableOrder {
    val selectedCity = requireNotNull(form.city.value)
    val selectedDistrict = form.district?.value
    return PlaceableOrder(
        fullName = form.fullName.value,
        address = form.address.value,
        phoneNumber = form.phoneNumber.value,
        alternativePhoneNumber = form.alternativePhoneNumber.value.takeIf(String::isNotBlank),
        email = form.email.value.takeIf(String::isNotBlank),
        products = orderDetails.products.map(OrderDetails.Product::transform),
        notes = form.notes.value.takeIf(String::isNotBlank),
        cityId = selectedCity.id,
        provinceId = selectedCity.province.id,
        districtId = selectedDistrict?.id,
        captchaToken = requireNotNull(captchaToken),
    )
}

private fun OrderDetails.Product.transform(): PlaceableOrder.Products =
    PlaceableOrder.Products(
        sku = sku,
        quantity = quantity,
    )

private fun Money?.transform(): OrderDetails.Calculation =
    let { money ->
        when {
            money == null -> OrderDetails.Calculation.Idle
            money.value == 0.0 -> OrderDetails.Calculation.Loaded(result = null)
            else -> OrderDetails.Calculation.Loaded(
                result = money.toString()
            )
        }
    }

private fun Product.Availability.transform(): OrderDetails.Product.Availability =
    when (this) {
        Product.Availability.InStock -> OrderDetails.Product.Availability.Available
        Product.Availability.OutOfStock -> OrderDetails.Product.Availability.SoldOut
    }

internal fun CheckoutViewState.Loaded.toCustomer(): Customer {
    val selectedCity = requireNotNull(form.city.value)
    val selectedDistrict = form.district?.value
    val province = Province(
        id = selectedCity.province.id,
        name = selectedCity.province.name,
        cities = emptyList(),
    )
    return Customer(
        fullName = form.fullName.value,
        phoneNumber = form.phoneNumber.value,
        alternativePhoneNumber = form.alternativePhoneNumber.value.takeIf(String::isNotBlank),
        email = form.email.value.takeIf(String::isNotBlank),
        province = province,
        city = City(
            id = selectedCity.id,
            name = selectedCity.name,
            province = province,
            districts = emptyList(),
        ),
        district = selectedDistrict?.let {
            District(
                id = selectedDistrict.id,
                name = selectedDistrict.name,
            )
        },
        address = form.address.value,
    )
}
