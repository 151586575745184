package com.taager.dukan.feature.productlist.di

import com.taager.dukan.feature.productlist.ProductListPresenter
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object ProductListDI {
    val module = DI.Module("Product List DI") {
        bindProvider {
            ProductListPresenter(
                appTracker = instance(),
                getProducts = instance(),
            )
        }
    }
}
