package com.taager.dukan.feature.checkout.payment

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.TextButtonLink
import com.taager.dukan.ui.component.paddingHorizontal
import com.taager.dukan.ui.component.paddingVertical
import com.taager.dukan.ui.theme.extraBold
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useTheme
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Stack
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import web.cssom.AlignItems
import web.cssom.FontWeight
import web.cssom.WhiteSpace
import web.cssom.pct

internal val CheckoutPaidError: FC<CheckoutErrorProps> = FC { props ->
    val theme = useTheme()
    Stack {
        sx {
            paddingVertical = theme.spacing(3)
            alignItems = AlignItems.center
        }
        spacing = responsive(2)

        img {
            src = "assets/images/checkout_error_image.svg"
        }
        Typography {
            sx {
                fontWeight = FontWeight.extraBold
            }
            variant = TypographyVariant.h3
            align = TypographyAlign.center
            +"checkout_error_headline".i18n()
        }
        Box {
            sx {
                backgroundColor = theme.palette.primary.light
                paddingVertical = theme.spacing(1)
                paddingHorizontal = theme.spacing(2)
            }
            Typography {
                sx {
                    whiteSpace = WhiteSpace.preLine
                    fontWeight = FontWeight.medium
                    color = theme.palette.text.secondary
                }
                variant = TypographyVariant.body2
                align = TypographyAlign.center
                +props.message
            }
        }
        props.email?.let { email ->
            Stack {
                sx {
                    alignItems = AlignItems.center
                }

                Typography {
                    sx {
                        fontWeight = FontWeight.medium
                        color = theme.palette.text.secondary
                    }
                    variant = TypographyVariant.body2
                    align = TypographyAlign.center
                    +"checkout_error_contact".i18n()
                }
                TextButtonLink {
                    +email
                    onClick = { props.onEmailClick(email) }
                }
            }
        }
        Button {
            sx {
                width = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to 50.pct)
            }
            variant = ButtonVariant.contained
            +"checkout_error_cta".i18n()
            onClick = { props.onCtaClick() }
        }
    }
}

internal external interface CheckoutErrorProps : Props {
    var message: String
    var email: String?
    var onEmailClick: (email: String) -> Unit
    var onCtaClick: () -> Unit
}
