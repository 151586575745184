package com.taager.dukan.feature.quicklink

import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.feature.quicklink.tracking.trackBackClick
import com.taager.dukan.feature.quicklink.tracking.trackEmailClick
import com.taager.dukan.feature.quicklink.tracking.trackScreenView
import com.taager.dukan.store.domain.interactor.GetQuickLinkUseCase
import com.taager.tracking.AppTracker
import kotlinx.coroutines.launch

class QuickLinkPresenter(
    private val appTracker: AppTracker,
    private val getQuickLink: GetQuickLinkUseCase
) : ViewStateHandler<QuickLinkViewState, QuickLinkSideEffect>(QuickLinkViewState.Initial),
    ViewEventHandler<QuickLinkViewEvent> {

    private lateinit var id: String

    override fun onEvent(event: QuickLinkViewEvent) {
        when (event) {
            is QuickLinkViewEvent.Init -> {
                id = event.id
                appTracker.trackScreenView(quickLink = id)
                loadQuickLink(id)
            }

            QuickLinkViewEvent.BackClick -> {
                appTracker.trackBackClick(quickLink = id)
                tryEmitEffect(QuickLinkSideEffect.GoToHome)
            }

            is QuickLinkViewEvent.EmailClick -> {
                appTracker.trackEmailClick(
                    quickLink = id,
                    email = event.email
                )
                tryEmitEffect(QuickLinkSideEffect.OpenMailTo(email = event.email))
            }
        }
    }

    private fun loadQuickLink(id: String) {
        scope.launch {
            try {
                val quickLink = getQuickLink(id)
                updateState(
                    newState = QuickLinkViewState.Loaded(quickLink),
                )
            } catch (error: Throwable) {
                appTracker.trackError(error)
                updateState(
                    newState = QuickLinkViewState.Error,
                )
            }
        }
    }
}
