package com.taager.dukan.ui.theme

import mui.material.PaletteMode
import mui.material.styles.Theme
import mui.material.useMediaQuery
import mui.system.Breakpoint

internal val Theme.isMobile: Boolean
    get() = useMediaQuery(breakpoints.down(Breakpoint.sm))

internal val Theme.isDesktop: Boolean
    get() = useMediaQuery(breakpoints.up(Breakpoint.sm))

internal val Theme.isLight: Boolean
    get() = palette.mode == PaletteMode.light

internal val Theme.isDark: Boolean
    get() = !isLight
