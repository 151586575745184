package com.taager.tracking.event

open class BaseTrackingEvent(
    screen: Screen? = null,
    event: String,
    extras: Map<String, Any> = emptyMap()
) : TrackingEvent {
    final override val name: String = (screen + event) ?: event
    final override val properties: Map<String, Any> = extras
}

private operator fun Screen?.plus(event: String) = this?.run { "${name}_$event" }
