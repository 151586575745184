package com.taager.checkout.payment

import com.taager.checkout.field.CheckoutForm
import com.taager.country.model.Currency
import com.taager.dukan.product.domain.entity.Product

private const val AMOUNT_FRACTION_CONVERTER = 100

fun CheckoutForm.toVisiblePaymentForm(
    storeName: String,
    productQuantities: Map<Product, Int>,
): PaymentFormVisibility.Visible {
    val amount = (productQuantities.totalPrice() * AMOUNT_FRACTION_CONVERTER).toLong()
    val metadata = toMetadata(
        storeName = storeName,
        productQuantities = productQuantities,
    )
    return PaymentFormVisibility.Visible(
        form = PaymentForm(
            amount = amount,
            currency = productQuantities.getCurrency().iso4217Code,
            description = productQuantities.toDescription(),
            storeName = storeName,
            metadata = metadata,
            methods = listOf("creditcard", "applepay"),
        ),
    )
}

private fun Map<Product, Int>.totalPrice(): Double =
    map { (product, quantity) ->
        product.pricingOptionForQuantity(quantity).price.value
    }.sum()

private fun Map<Product, Int>.toDescription(): String =
    map { (product, quantity) ->
        val pricingOptionName = product.pricingOptionForQuantity(quantity).name
        "${product.name}-$pricingOptionName}"
    }.joinToString(separator = " / ")

private fun Map<Product, Int>.getCurrency(): Currency =
    map { (product, quantity) ->
        product.pricingOptionForQuantity(quantity).price.currency
    }.first()

private fun CheckoutForm.toMetadata(
    storeName: String,
    productQuantities: Map<Product, Int>,
): PaymentForm.Metadata {
    val selectedCity = requireNotNull(city.value)
    val selectedDistrict = district?.value
    return PaymentForm.Metadata(
        orderLines = productQuantities.map { (product, quantity) ->
            PaymentForm.Metadata.OrderLine(
                sku = product.sku,
                quantity = quantity,
                totalPrice = product.pricingOptionForQuantity(quantity).price.value,
            )
        },
        shippingCost = 0.0,
        customer = PaymentForm.Metadata.Customer(
            fullName = fullName.value,
            provinceId = selectedCity.province.id,
            zoneId = selectedCity.id,
            districtId = selectedDistrict?.id,
            address1 = address.value,
            phoneNumber = phoneNumber.value,
            alternativePhoneNumber = alternativePhoneNumber?.value,
            email = email?.value,
            notes = notes?.value,
        ),
        orderReceivedBy = "dukan-$storeName"
    )
}
