package com.taager.dukan.preferences.data

import com.taager.dukan.preferences.domain.entity.Preferences
import kotlinx.browser.window

private val env = js("ENV")
private val isProduction = env.PRODUCTION.unsafeCast<Boolean>()
private val isDevelopment: Boolean = !isProduction

internal actual fun getInitialLanguage(): Preferences.Language {
    return if (isProduction) {
        Preferences.Language.Arabic
    } else {
        Preferences.Language.English
    }
}

internal actual fun getInitialColorMode(): Preferences.ColorMode =
    if (isDevelopment && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        Preferences.ColorMode.Dark
    } else {
        Preferences.ColorMode.Light
    }
