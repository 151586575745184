package com.taager.dukan.checkout.domain.entity

import com.taager.numbers.Money

data class Checkout(
    val itemsSubTotal: Money,
    val shippingCost: Money?,
    val codFee: Money?,
    val totalCost: Money,
) {

    data class Product(
        val sku: String,
        val price: Money,
        val quantity: Int,
    )
}
