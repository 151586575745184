package com.taager.dukan.order.domain.interactor

import com.taager.dukan.order.domain.OrderRepository

class PlacePaidOrderUseCase(
    private val repository: OrderRepository
) {

    suspend operator fun invoke(paymentId: String) {
        repository.placePaidOrder(paymentId)
    }
}
