package com.taager.dukan.i18n

internal fun String.i18n(vararg formatArgs: Pair<String, Any>): String {
    val strings by useStrings()
    require(strings != NotInitializedStrings) { "I18n is not initialized yet" }
    val string = requireNotNull(strings[this]) { "String not found for the key \"$this\"" }
        .toString()
    return if (formatArgs.isEmpty()) {
        string
    } else {
        string.format(formatArgs)
    }
}

private fun String.format(args: Array<out Pair<String, Any>>): String =
    args.fold(this) { accumulator, (key, value) ->
        accumulator.replace("{$key}", value.toString())
    }
