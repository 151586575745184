package com.taager.checkout.field

import com.taager.country.model.Country
import com.taager.dukan.customer.domain.entity.Customer

data class ExpressCheckoutForm(
    override val fullName: InputField.FullName,
    override val phoneNumber: InputField.PhoneNumber,
    override val city: InputField.City,
    override val hasDistrict: Boolean,
    override val district: InputField.District?,
    override val address: InputField.Address,
) : CheckoutForm {

    override val alternativePhoneNumber: InputField.AlternativePhoneNumber? = null
    override val email: InputField.Email? = null
    override val notes: InputField.Notes? = null

    companion object {
        fun initial(
            country: Country,
            cities: List<CheckoutForm.City>,
            districts: List<CheckoutForm.District>,
            customer: Customer?,
        ): ExpressCheckoutForm {
            val hasDistrict = hasDistricts(country)

            return ExpressCheckoutForm(
                fullName = InputField.FullName(
                    value = customer?.fullName ?: "",
                ),
                phoneNumber = InputField.PhoneNumber(
                    value = customer?.phoneNumber ?: "",
                    placeholder = country.phoneNumberHint,
                    regex = country.phoneRegex.toRegex(),
                    prefix = country.phoneNumPrefix.toString()
                ),
                city = InputField.City(
                    value = customer?.toFormCity(),
                    items = cities
                ),
                hasDistrict = hasDistrict,
                district = InputField.District(
                    value = customer?.toFormDistrict(),
                    items = districts
                ),
                address = InputField.Address(
                    value = customer?.address ?: "",
                ),
            )
        }
    }
}
