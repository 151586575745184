package com.taager.dukan.feature.home

sealed class HomeViewState {
    object Loading : HomeViewState()

    data class Loaded(val storeName: String) : HomeViewState()

    object Error : HomeViewState()

    companion object {
        val Initial: HomeViewState = Loading
    }
}
