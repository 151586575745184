package com.taager.dukan.ui.component

import com.taager.dukan.i18n.i18n
import mui.material.AlertColor
import react.FC
import react.Props

internal external interface ErrorAlertPanelProps : Props {
    var message: String?
}

internal val ErrorAlertPanel: FC<ErrorAlertPanelProps> = FC { props ->
    AlertPanel {
        color = AlertColor.error
        title = "error_alert_title".i18n()
        message = props.message ?: "error_alert_message".i18n()
    }
}

internal external interface ErrorAlertSnackbarProps : Props {
    var open: Boolean
    var message: String?
    var onClose: () -> Unit
}

internal val ErrorAlertSnackbar: FC<ErrorAlertSnackbarProps> = FC { props ->
    AlertSnackbar {
        open = props.open
        color = AlertColor.error
        title = "error_alert_title".i18n()
        message = props.message ?: "error_alert_message".i18n()
        actionText = "error_alert_close_button".i18n()
        onActionClick = props.onClose
        onClose = props.onClose
    }
}
