package com.taager.dukan.feature.checkout.payment

import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.feature.checkout.CheckoutLoading
import com.taager.dukan.feature.productdetails.ProductDetailsLoading
import com.taager.dukan.i18n.i18n
import com.taager.dukan.navigation.openMailTo
import com.taager.dukan.ui.component.BackButton
import com.taager.dukan.ui.component.ErrorAlertSnackbar
import js.core.jso
import react.FC
import react.Props
import react.router.dom.useSearchParams
import react.router.useLocation
import react.router.useNavigate
import react.router.useParams
import react.useEffect
import react.useState

internal val PostPaymentScreen: FC<Props> = FC {
    mainDI().composePresenter<PostPaymentPresenter, PostPaymentViewState> { presenter, viewState ->

        val location = useLocation()
        val source = location.pathname
        val isExpressCheckout = isExpressCheckout(source)

        val params = useParams()
        val sku = params["sku"].toString()

        val (searchParams) = useSearchParams()
        val paymentId = searchParams["id"]
        val encodedQuantity = searchParams["qt"]

        useEffect(paymentId) {
            presenter.onEvent(PostPaymentViewEvent.Init(sku, encodedQuantity, paymentId))
        }

        Content {
            isProductDetails = isExpressCheckout
            state = viewState
            onEvent = presenter::onEvent
        }

        val internetConnectionErrorMessage = "internet_connection_error_message".i18n()
        var errorMessage: String? by useState(null)
        val navigate = useNavigate()

        presenter.onSideEffect { effect ->
            when (effect) {
                PostPaymentSideEffect.GoToHome -> {
                    navigate(
                        to = "/",
                        options = jso {
                            replace = true
                        }
                    )
                }

                PostPaymentSideEffect.GoToProductDetails -> {
                    navigate(
                        to = "/products/$sku",
                        options = jso {
                            replace = true
                        }
                    )
                }

                PostPaymentSideEffect.ShowInternetConnectionError -> {
                    errorMessage = internetConnectionErrorMessage
                }

                is PostPaymentSideEffect.OpenMailTo -> {
                    openMailTo(effect.email)
                }

                PostPaymentSideEffect.GoToCheckoutSuccess -> {
                    navigate(
                        to = "/products/$sku" +
                            ("/checkout".takeUnless { isExpressCheckout } ?: "") +
                            // Using the p parameter to identify the prepaid checkout
                            // without exposing any data to the url
                            "/success?p="
                    )
                }
            }
        }

        ErrorAlertSnackbar {
            open = errorMessage != null
            message = errorMessage
            onClose = { errorMessage = null }
        }
    }
}

private external interface ContentProps :
    ViewStateProps<PostPaymentViewState>,
    ViewEventProps<PostPaymentViewEvent> {
    var isProductDetails: Boolean
}

private val Content: FC<ContentProps> = FC { props ->

    BackButton {
        text = "home_back_button".i18n()
        onClick = { props.onEvent(PostPaymentViewEvent.BackToHomeClick) }
    }

    when (val state = props.state) {

        is PostPaymentViewState.Loading -> {
            if (props.isProductDetails) {
                ProductDetailsLoading()
            } else {
                CheckoutLoading()
            }
        }

        is PostPaymentViewState.Error -> {
            CheckoutPaidError {
                message = "checkout_paid_error_message".i18n()
                email = state.contactEmail
                onEmailClick = { email ->
                    props.onEvent(PostPaymentViewEvent.ContactEmailClick(email))
                }
                onCtaClick = { props.onEvent(PostPaymentViewEvent.BackToProductClick) }
            }
        }
    }
}

private fun isExpressCheckout(source: String): Boolean =
    !source.contains("/checkout")
