package com.taager.dukan.preferences.data.di

import com.taager.dukan.preferences.data.PreferencesRepositoryImpl
import com.taager.dukan.preferences.data.local.PreferencesCache
import com.taager.dukan.preferences.data.local.model.PreferencesModel
import com.taager.dukan.preferences.domain.PreferencesRepository
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.bindSingleton
import org.kodein.di.instance

object PreferencesDataDI {
    val module = DI.Module("Preferences Data DI") {
        bindProvider {
            MemoryCache<PreferencesCache.Key, PreferencesModel>()
        }
        bindProvider {
            DiskCache<PreferencesCache.Key, PreferencesModel>(
                serializer = PreferencesModel.serializer()
            )
        }
        bindSingleton {
            PreferencesCache(
                memoryCache = instance(),
                diskCache = instance(),
            )
        }
        bindProvider<PreferencesRepository> {
            PreferencesRepositoryImpl(
                cache = instance(),
            )
        }
    }
}
