package com.taager.dukan.store.domain.interactor

import com.taager.dukan.store.domain.StoreRepository
import com.taager.dukan.store.domain.entity.Store
import com.taager.dukan.store.domain.tracking.trackStore
import com.taager.tracking.AppTracker

class GetStoreUseCase(
    private val repository: StoreRepository,
    private val appTracker: AppTracker,
) {

    suspend operator fun invoke(): Store =
        repository.getStore()
            .also(appTracker::trackStore)
}
