package com.taager.dukan.feature.productdetails

import com.taager.circuit.js.ViewEventProps
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.ui.theme.isMobile
import com.taager.dukan.ui.theme.useTheme
import mui.icons.material.ZoomOutMapRounded
import mui.material.Box
import mui.material.Card
import mui.material.CardActionArea
import mui.material.CardMedia
import mui.material.Fab
import mui.material.FabColor
import mui.material.PaperVariant
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import web.cssom.AlignSelf
import web.cssom.Border
import web.cssom.Display
import web.cssom.JustifySelf
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.ObjectFit
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.WhiteSpace
import web.cssom.number
import web.cssom.pct
import web.cssom.px

private const val IMAGE_LIST_ITEM_SIZE_SPACING = 10

internal val ProductDetailsMedias = FC<ProductDetailsMediasProps> { props ->
    val theme = useTheme()
    Stack {
        sx = props.sx
        direction = responsive(Breakpoint.xs to StackDirection.columnReverse, Breakpoint.sm to StackDirection.row)
        spacing = responsive(1)

        Box {
            component = ReactHTML.div
            sx {
                minWidth = responsive(Breakpoint.xs to 100.pct, Breakpoint.sm to Length.fitContent)
                overflow = Overflow.scroll
                whiteSpace = WhiteSpace.nowrap
            }

            props.medias.forEachIndexed { index, media ->
                Card {
                    sx {
                        if (theme.isMobile) {
                            display = Display.inlineBlock
                        }
                        margin = theme.spacing(1)
                        width = theme.spacing(IMAGE_LIST_ITEM_SIZE_SPACING)
                        aspectRatio = number(1.0)
                        if (props.selectedImageIndex == index) {
                            border = Border(
                                width = 1.px,
                                style = LineStyle.solid,
                                color = theme.palette.text.primary
                            )
                        }
                    }
                    variant = PaperVariant.outlined
                    key = media.url
                    CardActionArea {
                        CardMedia {
                            sx {
                                width = 100.pct
                                aspectRatio = number(1.0)
                                objectFit = ObjectFit.contain
                            }
                            component = when (media) {
                                is Product.Media.Image -> ReactHTML.img
                                is Product.Media.Video -> ReactHTML.video
                            }
                            this.image = when (media) {
                                is Product.Media.Image -> media.small
                                is Product.Media.Video -> media.url
                            }
                            asDynamic().alt = "" // We currently don't have descriptions for each media
                        }
                    }
                    onClick = {
                        props.onEvent(ProductDetailsViewEvent.MediaClick(index))
                    }
                }
            }
        }
        val selectedMedia = props.medias[props.selectedImageIndex]
        Card {
            sx {
                width = Length.fitContent
                aspectRatio = number(1.0)
                display = Display.flex
                position = Position.relative
            }
            key = selectedMedia.url
            variant = PaperVariant.outlined
            CardMedia {
                sx {
                    height = 100.pct
                    objectFit = ObjectFit.contain
                }
                when (selectedMedia) {
                    is Product.Media.Image -> {
                        component = ReactHTML.img
                    }

                    is Product.Media.Video -> {
                        component = ReactHTML.video
                        asDynamic().autoPlay = true
                        asDynamic().controls = true
                        asDynamic().muted = true
                    }
                }
                image = selectedMedia.url
                asDynamic().alt = "" // We currently don't have descriptions for each media
            }
            if (props.isFullscreenButtonVisible) {
                Fab {
                    sx {
                        alignSelf = AlignSelf.end
                        justifySelf = JustifySelf.end
                        position = Position.absolute
                        margin = theme.spacing(1)
                    }
                    color = FabColor.primary
                    size = Size.small
                    ZoomOutMapRounded()
                    onClick = { props.onEvent(ProductDetailsViewEvent.FullscreenImageClick(url = selectedMedia.url)) }
                }
            }
        }
    }
}

internal external interface ProductDetailsMediasProps : ViewEventProps<ProductDetailsViewEvent>, PropsWithSx {
    var medias: List<Product.Media>
    var selectedImageIndex: Int
    var isFullscreenButtonVisible: Boolean
}
