package com.taager.dukan.product.data.remote.mapper

import com.taager.country.model.Currency
import com.taager.dukan.product.data.remote.response.GetProductsResponse
import com.taager.dukan.product.data.remote.response.ProductDto
import com.taager.dukan.product.domain.entity.Product
import com.taager.numbers.toMoney
import com.taager.translator.Translator

internal fun GetProductsResponse.transform(
    currency: Currency,
    translator: Translator
): List<Product> =
    products.map { product ->
        product.transform(currency, translator)
    }

internal fun ProductDto.transform(
    currency: Currency,
    translator: Translator
): Product =
    Product(
        sku = sku,
        name = translator.translate(name),
        description = translator.translate(description),
        medias = images.transform(),
        availability = availability.transform(),
        pricingOptions = pricingOptions.transform(currency, translator)
    )

private fun ProductDto.Availability.transform(): Product.Availability =
    when (this) {
        ProductDto.Availability.InStock -> Product.Availability.InStock
        ProductDto.Availability.OutOfStock -> Product.Availability.OutOfStock
    }

private val String.isVideoFile: Boolean
    get() = endsWith(".mp4") || endsWith(".webm")

private fun List<String>.transform(): List<Product.Media> =
    map { media ->
        if (media.isVideoFile) {
            Product.Media.Video(media)
        } else {
            Product.Media.Image(
                url = media,
                small = media.resized("100x100"),
                medium = media.resized("160x160"),
                large = media.resized("360x360"),
            )
        }
    }

private fun String.resized(size: String): String {
    val id = this.substringAfterLast("/")
    val path = this.substringBeforeLast("/")
    return "$path/$size/$id"
}

private fun List<ProductDto.PricingOption>.transform(
    currency: Currency,
    translator: Translator
): List<Product.PricingOption> =
    map { pricingOption ->
        Product.PricingOption(
            name = translator.translate(pricingOption.name),
            description = pricingOption.description
                ?.let(translator::translate)
                ?.takeIf(String::isNotBlank),
            quantity = pricingOption.quantity,
            price = pricingOption.totalPrice.toMoney(currency),
            isDefault = pricingOption.isDefault,
        )
    }
