package com.taager.dukan.product.domain.interactor

import com.taager.dukan.product.domain.ProductRepository
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.store.domain.interactor.GetStoreUseCase

class GetProductsBySkusUseCase(
    private val getStore: GetStoreUseCase,
    private val repository: ProductRepository,
) {

    suspend operator fun invoke(skus: List<String>): List<Product> =
        repository.getProducts(
            currency = getStore().country.currency,
            skus = skus
        )
}
