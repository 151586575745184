package com.taager.dukan.customer.data.local.mapper

import com.taager.dukan.customer.data.local.model.CityModel
import com.taager.dukan.customer.data.local.model.ProvinceModel
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.Province

internal fun List<Province>.transform(): List<ProvinceModel> =
    map(Province::transform)

internal fun Province.transform(): ProvinceModel =
    ProvinceModel(
        id = id,
        name = name,
        cities = cities.map(City::transform)
    )

internal fun List<ProvinceModel>.transform(): List<Province> =
    map(ProvinceModel::transform)

internal fun ProvinceModel.transform(): Province =
    Province(
        id = id,
        name = name,
        cities = cities.map(CityModel::transform)
    )
