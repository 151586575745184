package com.taager.dukan.feature.checkout

import com.taager.checkout.field.DefaultCheckoutForm
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.feature.checkout.payment.CheckoutPaymentForm
import com.taager.dukan.feature.checkout.payment.CheckoutPaymentMethods
import com.taager.dukan.feature.checkout.payment.CheckoutPaymentMethodsProps
import com.taager.dukan.i18n.i18n
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.ui.component.Autocomplete
import com.taager.dukan.ui.component.BackButton
import com.taager.dukan.ui.component.ErrorAlertPanel
import com.taager.dukan.ui.component.ErrorAlertSnackbar
import com.taager.dukan.ui.component.InvisibleCaptcha
import com.taager.dukan.ui.component.LoadingModal
import com.taager.dukan.ui.component.SelectInput
import com.taager.dukan.ui.component.StickyBottomBar
import com.taager.dukan.ui.component.TextInput
import com.taager.dukan.ui.component.external.ReCaptchaComponent
import com.taager.dukan.ui.component.marginInlineHorizontal
import com.taager.dukan.ui.component.paddingHorizontal
import com.taager.dukan.ui.component.paddingVertical
import com.taager.dukan.ui.css.CssClass
import com.taager.dukan.ui.theme.isDesktop
import com.taager.dukan.ui.theme.isMobile
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.icons.material.ErrorOutlineRounded
import mui.icons.material.LocationOnOutlined
import mui.material.Box
import mui.material.CardMedia
import mui.material.Divider
import mui.material.FormControl
import mui.material.Icon
import mui.material.Paper
import mui.material.PaperVariant
import mui.material.Skeleton
import mui.material.SkeletonVariant
import mui.material.Stack
import mui.material.StackDirection.Companion.column
import mui.material.StackDirection.Companion.row
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.material.styles.TypographyVariant.Companion.body1
import mui.material.styles.TypographyVariant.Companion.body2
import mui.system.Breakpoint.Companion.sm
import mui.system.Breakpoint.Companion.xs
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.RefObject
import react.create
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.video
import react.router.dom.useSearchParams
import react.router.useNavigate
import react.router.useParams
import react.useEffect
import react.useMemo
import react.useRef
import react.useState
import web.cssom.AlignItems
import web.cssom.Direction
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.TextAlign
import web.cssom.important
import web.cssom.pct
import web.dom.Element
import web.dom.document

internal val CheckoutScreen: FC<Props> = FC {
    mainDI().composePresenter<CheckoutPresenter, CheckoutViewState> { presenter, viewState ->

        val params = useParams()
        val sku = params["sku"].toString()
        val (searchParams) = useSearchParams()
        val quantity = searchParams["quantity"]?.toInt()

        useEffect(sku) {
            presenter.onEvent(CheckoutViewEvent.Init(sku, quantity))
        }

        val recaptchaRef = useRef<ReCaptchaComponent>(null)
        Content {
            this.recaptchaRef = recaptchaRef
            state = viewState
            onEvent = presenter::onEvent
        }

        // Moving the calling of i18n strings out of the error component
        // to avoid this warning:
        // > React detected a change in the order of Hooks called by null.
        // > This will lead to bugs and errors if not fixed.
        val internetConnectionErrorMessage = "internet_connection_error_message".i18n()
        val placeOrderErrorMessage = "checkout_place_order_error".i18n()
        var errorMessage: String? by useState(null)
        val navigate = useNavigate()

        presenter.onSideEffect { effect ->
            when (effect) {
                CheckoutSideEffect.GoToProductDetails -> {
                    navigate(
                        to = "/products/$sku",
                        options = jso {
                            replace = true
                        }
                    )
                }

                CheckoutSideEffect.ShowInternetConnectionError -> {
                    errorMessage = internetConnectionErrorMessage
                }

                CheckoutSideEffect.ShowPlaceOrderError -> {
                    recaptchaRef.current?.reset()
                    errorMessage = placeOrderErrorMessage
                }

                CheckoutSideEffect.GoToHome -> {
                    navigate(
                        to = "/",
                        options = jso {
                            replace = true
                        }
                    )
                }

                CheckoutSideEffect.GoToCheckoutSuccess -> {
                    navigate(to = "success")
                }
            }
        }

        ErrorAlertSnackbar {
            open = errorMessage != null
            message = errorMessage
            onClose = { errorMessage = null }
        }
    }
}

private external interface ContentProps :
    ViewStateProps<CheckoutViewState>,
    ViewEventProps<CheckoutViewEvent> {
    var recaptchaRef: RefObject<ReCaptchaComponent>
}

private val Content: FC<ContentProps> = FC { props ->
    val state = props.state

    BackButton {
        text = "checkout_back_button".i18n()
        onClick = { props.onEvent(CheckoutViewEvent.BackClick) }
    }

    when (state) {

        is CheckoutViewState.Loading -> {
            CheckoutLoading()
        }

        is CheckoutViewState.Loaded -> {
            Loaded {
                this.recaptchaRef = props.recaptchaRef
                this.state = state
                onEvent = props.onEvent
            }

            val isPlacingOrder = state.isPlacingOrder
            LoadingModal {
                open = isPlacingOrder
            }
        }

        is CheckoutViewState.Error -> {
            ErrorAlertPanel()
        }
    }
}

private external interface LoadedProps : ViewEventProps<CheckoutViewEvent> {
    var recaptchaRef: RefObject<ReCaptchaComponent>
    var state: CheckoutViewState.Loaded
}

private val Loaded: FC<LoadedProps> = FC { props ->

    val state = props.state
    val theme = useTheme()

    FormControl {
        sx {
            width = 100.pct
            paddingTop = theme.spacing(1)
            paddingBottom = theme.spacing(3)
        }
        Stack {
            spacing = responsive(2)
            direction = responsive(xs to column, sm to row)

            ShippingDetails {
                sx {
                    width = responsive(xs to 100.pct, sm to 50.pct)
                }
                deliverTo = state.deliverTo
                form = state.form
                onEvent = props.onEvent
            }
            Stack {
                sx {
                    width = responsive(xs to 100.pct, sm to 50.pct)
                    justifyContent = JustifyContent.spaceBetween
                }
                spacing = responsive(2)

                Stack {
                    spacing = responsive(2)

                    OrderSummary {
                        orderDetails = state.orderDetails
                        onEvent = props.onEvent
                    }

                    if (theme.isMobile) {
                        Paper {
                            sx {
                                paddingVertical = theme.spacing(2)
                            }
                            variant = PaperVariant.outlined
                            PaymentMethods {
                                availablePaymentMethods = state.availablePaymentMethods
                                itemSelected = state.paymentMethod
                                hasError = state.hasPaymentMethodError
                                onEvent = props.onEvent
                            }
                        }
                    } else {
                        PaymentMethods {
                            availablePaymentMethods = state.availablePaymentMethods
                            itemSelected = state.paymentMethod
                            hasError = state.hasPaymentMethodError
                            onEvent = props.onEvent
                        }
                    }
                }

                Stack {
                    spacing = responsive(2)

                    val stickyAnchorRef = useRef<Element>(null)

                    if (theme.isDesktop) {
                        CheckoutPlaceOrderButton {
                            isEnabled = state.isPlaceOrderButtonEnabled
                            recaptchaRef = props.recaptchaRef
                            onRecaptchaSuccess = { props.onEvent(CheckoutViewEvent.PlaceOrderClick) }
                        }
                    } else {
                        StickyBottomBar {
                            anchorRef = stickyAnchorRef
                            CheckoutPlaceOrderButton {
                                isEnabled = state.isPlaceOrderButtonEnabled
                                recaptchaRef = props.recaptchaRef
                                onRecaptchaSuccess = { props.onEvent(CheckoutViewEvent.PlaceOrderClick) }
                            }
                        }
                    }

                    Box {
                        sx {
                            // Center the captcha horizontally
                            textAlign = TextAlign.center
                        }
                        ref = stickyAnchorRef
                        InvisibleCaptcha {
                            recaptchaRef = props.recaptchaRef
                            onChange = { token ->
                                props.onEvent(CheckoutViewEvent.CaptchaResponse(token))
                            }
                            onError = {
                                // Very likely to be network connectivity issues
                                props.onEvent(CheckoutViewEvent.CaptchaError)
                            }
                        }
                    }
                }
            }
        }
    }

    CheckoutPaymentForm {
        formVisibility = state.paymentFormVisibility
        onClosePaymentForm = { props.onEvent(CheckoutViewEvent.ClosePaymentForm) }
        onPaymentFormCompleted = { props.onEvent(CheckoutViewEvent.PaymentFormCompleted) }
    }

    val isFormHidden = state.paymentFormVisibility is PaymentFormVisibility.Hidden
    useEffect(isFormHidden) {
        if (isFormHidden) {
            props.recaptchaRef.current?.reset()
        }
    }
}

private external interface PaymentMethodsProps : CheckoutPaymentMethodsProps, ViewEventProps<CheckoutViewEvent>

private val PaymentMethods: FC<PaymentMethodsProps> = FC { props ->
    val theme = useTheme()
    Stack {
        spacing = responsive(1)

        Typography {
            sx {
                paddingHorizontal = theme.spacing(2)
                fontWeight = FontWeight.bold
            }
            variant = body1
            +"checkout_payment_methods_title".i18n()
        }
        Divider {
            sx {
                marginInlineHorizontal = important(theme.spacing(2))
            }
        }
        CheckoutPaymentMethods {
            availablePaymentMethods = props.availablePaymentMethods
            isBorderVisible = false
            itemSelected = props.itemSelected
            hasError = props.hasError
            onClick = { paymentMethod ->
                props.onEvent(CheckoutViewEvent.PaymentMethodClick(paymentMethod))
            }
        }
    }
}

private external interface ShippingDetailsProps : ViewEventProps<CheckoutViewEvent>, PropsWithSx {
    var deliverTo: String
    var form: DefaultCheckoutForm
}

private val ShippingDetails: FC<ShippingDetailsProps> = FC { props ->
    val form = props.form
    val theme = useTheme()
    Paper {
        sx = props.sx
        variant = PaperVariant.outlined

        Stack {
            sx {
                padding = theme.spacing(2)
            }
            spacing = responsive(2)

            Stack {
                direction = responsive(row)
                spacing = responsive(1)
                Typography {
                    sx {
                        color = theme.palette.text.secondary
                    }
                    variant = TypographyVariant.caption
                    +"checkout_deliver_to_label".i18n()
                }
                Typography {
                    sx {
                        fontWeight = FontWeight.bold
                    }
                    variant = TypographyVariant.caption
                    +props.deliverTo
                }
            }
            Title {
                text = "checkout_shipping_details_title".i18n()
            }
            TextInput {
                label = "checkout_full_name_label".i18n()
                inputField = form.fullName
                onChange = { inputField, newValue ->
                    props.onEvent(
                        CheckoutViewEvent.UpdateField(
                            inputField = inputField,
                            newValue = newValue
                        )
                    )
                }
            }
            TextInput {
                label = "checkout_address_label".i18n()
                placeholder = "checkout_address_placeholder".i18n()
                inputField = form.address
                onChange = { inputField, newValue ->
                    props.onEvent(
                        CheckoutViewEvent.UpdateField(
                            inputField = inputField,
                            newValue = newValue
                        )
                    )
                }
            }
            val hasDistrict = form.hasDistrict
            Stack {
                direction = responsive(xs to column, sm to row)
                spacing = responsive(2)

                Autocomplete {
                    sx {
                        width = responsive(
                            xs to 100.pct,
                            sm to if (hasDistrict) {
                                50.pct
                            } else {
                                100.pct
                            }
                        )
                    }
                    label = "checkout_city_label".i18n()
                    inputField = form.city
                    loadingText = "checkout_city_loading_text".i18n()
                    placeholderText = "checkout_city_start_typing_placeholder".i18n()
                    startAdornment = Icon.create { LocationOnOutlined() }
                    onQuery = { _, query ->
                        props.onEvent(CheckoutViewEvent.QueryCities(query))
                    }
                    onSelect = { inputField, item ->
                        props.onEvent(
                            CheckoutViewEvent.UpdateField(
                                inputField = inputField,
                                newValue = item
                            )
                        )
                    }
                }
                if (form.hasDistrict) {
                    val district = requireNotNull(form.district)
                    SelectInput {
                        sx {
                            width = responsive(xs to 100.pct, sm to 50.pct)
                        }
                        label = "checkout_district_label".i18n()
                        inputField = district
                        emptyText = "checkout_district_empty_text".i18n()
                        onChange = { inputField, newValue ->
                            props.onEvent(
                                CheckoutViewEvent.UpdateField(
                                    inputField = inputField,
                                    newValue = newValue
                                )
                            )
                        }
                    }
                }
            }
            TextInput {
                sx {
                    // TODO A hack to force always LTR direction. A better approach is needed
                    direction = if (document.dir == "rtl") {
                        Direction.rtl
                    } else {
                        Direction.ltr
                    }
                }
                label = "checkout_phone_number_label".i18n()
                inputField = form.phoneNumber
                onChange = { inputField, newValue ->
                    props.onEvent(
                        CheckoutViewEvent.UpdateField(
                            inputField = inputField,
                            newValue = newValue
                        )
                    )
                }
            }
            TextInput {
                label = "checkout_alternative_phone_number_label".i18n()
                inputField = form.alternativePhoneNumber
                onChange = { inputField, newValue ->
                    props.onEvent(
                        CheckoutViewEvent.UpdateField(
                            inputField = inputField,
                            newValue = newValue
                        )
                    )
                }
            }
            // TODO Hide it until we decide how to collect this info
            //  without harm the user experience
//            TextInput {
//                label = "checkout_email_label".i18n()
//                field = form.email
//                onChange = { inputField, newValue ->
//                    props.onEvent(
//                        CheckoutViewEvent.UpdateField(
//                            inputField = inputField,
//                            newValue = newValue
//                        )
//                    )
//                }
//            }
            TextInput {
                label = "checkout_notes_label".i18n()
                inputField = form.notes
                onChange = { inputField, newValue ->
                    props.onEvent(
                        CheckoutViewEvent.UpdateField(
                            inputField = inputField,
                            newValue = newValue
                        )
                    )
                }
            }
        }
    }
}

private external interface TitleProps : Props {
    var text: String
}

private val Title: FC<TitleProps> = FC { props ->
    Stack {
        spacing = responsive(1)
        Typography {
            sx {
                fontWeight = FontWeight.bold
            }
            variant = body1
            +props.text
        }
        Divider()
    }
}

private external interface OrderSummaryProps : ViewEventProps<CheckoutViewEvent>, Props {
    var orderDetails: CheckoutViewState.OrderDetails
}

private val OrderSummary: FC<OrderSummaryProps> = FC { props ->
    val orderDetails = props.orderDetails
    val theme = useTheme()
    Paper {
        variant = PaperVariant.outlined

        Stack {
            sx {
                padding = theme.spacing(2)
            }
            spacing = responsive(2)

            Title {
                text = "checkout_order_details_title".i18n()
            }
            for (product in orderDetails.products) {
                OrderSummaryRow {
                    media = product.media
                    primaryLabel = product.name
                    secondaryLabel = product.priceName
                    content = when (product.availability) {

                        CheckoutViewState.OrderDetails.Product.Availability.Available ->
                            Stack.create {
                                sx {
                                    alignItems = AlignItems.center
                                }
                                direction = responsive(row)
                                spacing = responsive(1)
                                Typography {
                                    variant = body2
                                    +product.priceContent
                                }
                            }

                        CheckoutViewState.OrderDetails.Product.Availability.SoldOut ->
                            Typography.create {
                                sx {
                                    fontWeight = FontWeight.bold
                                    color = theme.palette.text.secondary
                                }
                                variant = body2
                                +"product_availability_sold_out".i18n()
                            }
                    }
                }
            }
            Divider()
            OrderSummaryRow {
                primaryLabel = "checkout_subtotal_label".i18n()
                content = Calculation.create {
                    calculation = orderDetails.subtotal
                    onLoaded = { result ->
                        Typography.create {
                            variant = body2
                            +result.orEmpty()
                        }
                    }
                }
            }
            OrderSummaryRow {
                primaryLabel = "checkout_shipping_cost_label".i18n()
                content = Calculation.create {
                    calculation = orderDetails.shippingCost
                    onLoaded = { result ->
                        if (result == null) {
                            FreeShippingTag.create()
                        } else {
                            Typography.create {
                                variant = body2
                                +result
                            }
                        }
                    }
                }
            }
            val codFee = orderDetails.codFee as? CheckoutViewState.OrderDetails.Calculation.Loaded
            val codFeeLabel = codFee?.result?.let { "checkout_cod_fee_label".i18n() } ?: ""
            val codFeeContent = useMemo(orderDetails.codFee) {
                Typography.create {
                    sx {
                        fontWeight = FontWeight.bold
                    }
                    variant = body2
                    +codFee?.result.orEmpty()
                }
            }
            OrderSummaryRow {
                primaryLabel = codFeeLabel
                content = codFeeContent
            }
            OrderSummaryRow {
                primaryLabel = "checkout_total_label".i18n()
                content = Calculation.create {
                    calculation = orderDetails.total
                    onLoaded = { result ->
                        Typography.create {
                            sx {
                                fontWeight = FontWeight.bold
                            }
                            variant = body2
                            +result.orEmpty()
                        }
                    }
                }
            }
        }
    }
}

private val FreeShippingTag: FC<Props> = FC {
    val theme = useTheme()
    Typography {
        sx {
            fontWeight = FontWeight.bold
            color = theme.palette.success.main
        }
        variant = body2
        +"checkout_free_shipping_tag".i18n()
    }
}

private external interface CalculationProps : Props {
    var calculation: CheckoutViewState.OrderDetails.Calculation
    var onLoaded: (String?) -> ReactNode
}

private val Calculation: FC<CalculationProps> = FC { props ->
    when (val result = props.calculation) {
        CheckoutViewState.OrderDetails.Calculation.Idle -> {
            // no-op
        }

        CheckoutViewState.OrderDetails.Calculation.Loading -> {
            Skeleton {
                width = 25.pct
                variant = SkeletonVariant.text
            }
        }

        is CheckoutViewState.OrderDetails.Calculation.Loaded -> {
            +props.onLoaded(result.result)
        }

        CheckoutViewState.OrderDetails.Calculation.Error -> {
            Icon {
                ErrorOutlineRounded()
            }
        }
    }
}

private external interface OrderSummaryRowProps : Props {
    var media: Product.Media?
    var primaryLabel: String
    var secondaryLabel: String?
    var content: ReactNode
}

private val OrderSummaryRow: FC<OrderSummaryRowProps> = FC { props ->
    val theme = useTheme()
    Stack {
        sx {
            alignItems = AlignItems.center
            justifyContent = JustifyContent.spaceBetween
        }
        direction = responsive(row)

        Stack {
            sx {
                alignItems = AlignItems.center
            }
            direction = responsive(row)
            spacing = responsive(1)

            Typography {
                sx {
                    display = Display.flex
                    color = theme.palette.text.secondary
                }
                variant = body2
                props.media?.let { media ->
                    CardMedia {
                        sx {
                            marginInlineEnd = theme.spacing(1)
                            width = theme.spacing(3)
                            height = theme.spacing(3)
                        }
                        component = when (media) {
                            is Product.Media.Image -> img
                            is Product.Media.Video -> video
                        }
                        image = media.url
                        asDynamic().alt = "" // Media is described by the text element below
                    }
                }
                +props.primaryLabel
            }
            props.secondaryLabel?.let { label2 ->
                Typography {
                    sx { color = theme.palette.text.secondary }
                    variant = body2
                    +"-"
                }
                Typography {
                    sx {
                        color = theme.palette.text.secondary
                    }
                    className = CssClass.PreformattedText
                    variant = body2

                    dangerouslySetInnerHTML = jso {
                        __html = label2
                    }
                }
            }
        }
        child(props.content)
    }
}
