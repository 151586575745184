package com.taager.dukan.feature.productdetails.mapper

import com.taager.checkout.field.CheckoutForm
import com.taager.checkout.field.ExpressCheckoutForm
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.checkout.payment.getAvailablePaymentMethods
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.Customer
import com.taager.dukan.customer.domain.entity.District
import com.taager.dukan.customer.domain.entity.Province
import com.taager.dukan.feature.productdetails.ProductDetailsViewState
import com.taager.dukan.feature.productdetails.ProductDetailsViewState.ExpressCheckout
import com.taager.dukan.order.domain.entity.PlaceableOrder
import com.taager.dukan.product.domain.entity.Product
import com.taager.dukan.store.domain.entity.Store
import com.taager.translator.Translator

internal fun initialLoadedState(
    product: Product,
    expressCheckout: ExpressCheckout,
): ProductDetailsViewState.Loaded {
    val productModel = product.transform()
    val selectedImageIndex = 0
    val selectedMedia = productModel.medias[selectedImageIndex]
    val isProductAvailable = productModel.isAvailable
    return ProductDetailsViewState.Loaded(
        product = productModel,
        selectedImageIndex = selectedImageIndex,
        isOrderNowButtonEnabled = isProductAvailable,
        isFullscreenButtonVisible = selectedMedia is Product.Media.Image,
        fullscreenImage = null,
        expressCheckout = if (isProductAvailable) expressCheckout else null,
    )
}

internal fun transform(
    product: Product,
    quantity: Int,
    checkoutForm: ExpressCheckoutForm,
    captchaToken: String?
): PlaceableOrder {
    val selectedCity = requireNotNull(checkoutForm.city.value)
    val selectedDistrict = checkoutForm.district?.value
    return PlaceableOrder(
        fullName = checkoutForm.fullName.value,
        address = checkoutForm.address.value,
        phoneNumber = checkoutForm.phoneNumber.value,
        alternativePhoneNumber = null,
        email = null,
        products = listOf(
            PlaceableOrder.Products(
                sku = product.sku,
                quantity = quantity,
            )
        ),
        notes = null,
        provinceId = selectedCity.province.id,
        cityId = selectedCity.id,
        districtId = selectedDistrict?.id,
        captchaToken = requireNotNull(captchaToken),
    )
}

@Suppress("LongParameterList")
internal fun initialExpressCheckout(
    translator: Translator,
    store: Store,
    cities: List<CheckoutForm.City>,
    districts: List<CheckoutForm.District>,
    customer: Customer?,
): ExpressCheckout {
    val country = store.country
    val availablePaymentMethods = getAvailablePaymentMethods(
        payments = store.payments,
    )
    return ExpressCheckout(
        deliverTo = translator.translate(country),
        form = ExpressCheckoutForm.initial(
            country = country,
            cities = cities,
            districts = districts,
            customer = customer,
        ),
        paymentMethod = availablePaymentMethods.first(),
        hasPaymentMethodError = false,
        paymentFormVisibility = PaymentFormVisibility.Hidden,
        isPlacingOrder = false,
        availablePaymentMethods = availablePaymentMethods,
        codFee = if (availablePaymentMethods.contains(PaymentMethod.CashOnDelivery)) {
            store.payments.codFee?.toString()
        } else {
            null
        },
    )
}

private fun Product.transform(): ProductDetailsViewState.Product =
    ProductDetailsViewState.Product(
        sku = sku,
        name = name,
        description = description,
        medias = medias,
        availability = availability.transform(),
        pricingOptions = pricingOptions.transform(defaultPricingOption),
    )

private fun Product.Availability.transform(): ProductDetailsViewState.Product.Availability =
    when (this) {
        Product.Availability.InStock -> ProductDetailsViewState.Product.Availability.Available
        Product.Availability.OutOfStock -> ProductDetailsViewState.Product.Availability.SoldOut
    }

private val ProductDetailsViewState.Product.isAvailable: Boolean
    get() = availability == ProductDetailsViewState.Product.Availability.Available

private fun List<Product.PricingOption>.transform(
    defaultPricingOption: Product.PricingOption,
): List<ProductDetailsViewState.Product.PricingOption> =
    map { pricingOption ->
        val price = pricingOption.price
        ProductDetailsViewState.Product.PricingOption(
            name = pricingOption.name,
            description = pricingOption.description,
            quantity = pricingOption.quantity,
            price = price.toString(),
            internalPrice = price,
            isSelected = pricingOption == defaultPricingOption,
        )
    }

internal fun ExpressCheckoutForm.toCustomer(): Customer {
    val selectedCity = requireNotNull(city.value)
    val selectedDistrict = district?.value
    val province = Province(
        id = selectedCity.province.id,
        name = selectedCity.province.name,
        cities = emptyList(),
    )
    return Customer(
        fullName = fullName.value,
        address = address.value,
        phoneNumber = phoneNumber.value,
        alternativePhoneNumber = null,
        email = null,
        province = province,
        city = City(
            id = selectedCity.id,
            name = selectedCity.name,
            province = province,
            districts = emptyList(),
        ),
        district = selectedDistrict?.let {
            District(
                id = selectedDistrict.id,
                name = selectedDistrict.name,
            )
        },
    )
}
