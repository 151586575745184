package com.taager.dukan.ui.theme

import csstype.PropertiesBuilder
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant.Companion.body1
import mui.material.styles.TypographyVariant.Companion.body2
import mui.material.styles.TypographyVariant.Companion.button
import mui.material.styles.TypographyVariant.Companion.caption
import mui.material.styles.TypographyVariant.Companion.h1
import mui.material.styles.TypographyVariant.Companion.h2
import mui.material.styles.TypographyVariant.Companion.h3
import web.cssom.FontWeight
import web.cssom.None
import web.cssom.integer
import web.cssom.media.maxWidth
import web.cssom.px
import web.cssom.string

internal val FontWeight.Companion.medium: FontWeight
    get() = integer(500)

internal val FontWeight.Companion.extraBold: FontWeight
    get() = integer(800)

internal val Typography = TypographyOptions {
    fontFamily = string("Taager, Roboto, Helvetica, Arial, sans-serif")
    fontWeight = integer(400)

    // Display 1
    h1 {
        fontSize = 58.px
        onMobile {
            fontSize = 47.px
        }
    }
    // Heading 1
    h2 {
        fontSize = 33.px
        onMobile {
            fontSize = 23.px
        }
    }
    // Heading 2
    h3 {
        fontSize = 23.px
        onMobile {
            fontSize = 18.px
        }
    }
    body1 {
        fontSize = 18.px
        onMobile {
            fontSize = 16.px
        }
    }
    body2 {
        fontSize = 16.px
        onMobile {
            fontSize = 14.px
        }
    }
    button {
        fontSize = 18.px
        onMobile {
            fontSize = 16.px
        }
        textTransform = None.none
    }
    caption {
        fontSize = 14.px
        onMobile {
            fontSize = 12.px
        }
    }
}

private fun PropertiesBuilder.onMobile(block: PropertiesBuilder.() -> Unit) {
    media(maxWidth(600.px)) {
        block(this)
    }
}
