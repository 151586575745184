package com.taager.dukan.order.data.remote

import com.taager.dukan.order.data.remote.request.PlaceOrderRequest
import com.taager.dukan.order.data.remote.request.PlacePaidOrderRequest
import io.ktor.client.HttpClient
import io.ktor.client.request.post
import io.ktor.client.request.setBody

private const val ORDER_PATH = "api/orders"
private const val ORDER_PREPAID_PATH = "api/orders/prepaid"

internal class OrderApi(
    private val client: HttpClient,
) {

    suspend fun placeOrder(orderRequest: PlaceOrderRequest) =
        client.post(ORDER_PATH) {
            setBody(orderRequest)
        }

    suspend fun placePaidOrder(orderRequest: PlacePaidOrderRequest) =
        client.post(ORDER_PREPAID_PATH) {
            setBody(orderRequest)
        }
}
