package com.taager.tracking.gtm

import com.taager.tracking.Tracker
import com.taager.tracking.event.TrackingEvent
import com.taager.tracking.mapper.transform
import web.dom.document
import web.window.window

internal class GoogleTagManagerTracker : Tracker {

    private val gtm = js("gtm")
    private val initialize = js("initializeGtm")
    private var isInitialized = false

    override fun initialize() {
        // no-op
    }

    override fun trackEvent(event: TrackingEvent) {
        event.transform()?.let { gtmEvent ->
            gtm("event", gtmEvent.event, gtmEvent.properties.transform())
        }
    }

    override fun trackError(throwable: Throwable) {
        // no-op
    }

    override fun identifyUser(userId: String) {
        // no-op
    }

    override fun setUserProperty(key: String, value: String) {
        // no-op
    }

    override fun clearUserProperty(key: String) {
        // no-op
    }

    override fun setEventProperty(key: String, value: String) {
        when (key) {
            "gTag_pixel_ids" -> {
                if (!isInitialized) {
                    for (pixelId in value.split(",")) {
                        initialize(window, document, "script", "dataLayer", pixelId)
                    }
                    isInitialized = true
                }
            }
        }
    }

    override fun clearEventProperty(key: String) {
        // no-op
    }

    override fun reset() {
        // no-op
    }
}
