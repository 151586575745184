package com.taager.dukan.store.domain.interactor

import com.taager.dukan.store.domain.StoreRepository
import com.taager.dukan.store.domain.entity.QuickLink

class GetQuickLinkUseCase(
    private val repository: StoreRepository,
) {

    suspend operator fun invoke(id: String): QuickLink =
        repository.getQuickLink(id)
}
