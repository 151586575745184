package com.taager.dukan.feature.home

import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.feature.home.tracking.trackColorModeClick
import com.taager.dukan.feature.home.tracking.trackLanguageClick
import com.taager.dukan.feature.home.tracking.trackQuickLinkClick
import com.taager.dukan.feature.home.tracking.trackScreenView
import com.taager.dukan.feature.home.tracking.trackStoreNameClick
import com.taager.dukan.preferences.domain.interactor.GetPreferencesUseCase
import com.taager.dukan.preferences.domain.interactor.SwitchColorModeUseCase
import com.taager.dukan.preferences.domain.interactor.SwitchLanguageUseCase
import com.taager.dukan.store.domain.entity.Store
import com.taager.dukan.store.domain.interactor.GetStoreUseCase
import com.taager.tracking.AppTracker
import kotlinx.coroutines.launch

class HomePresenter(
    private val appTracker: AppTracker,
    private val getStore: GetStoreUseCase,
    private val getPreferences: GetPreferencesUseCase,
    private val switchLanguage: SwitchLanguageUseCase,
    private val switchColorMode: SwitchColorModeUseCase,
) : ViewStateHandler<HomeViewState, HomeSideEffect>(HomeViewState.Initial),
    ViewEventHandler<HomeViewEvent> {

    override fun onEvent(event: HomeViewEvent) {
        when (event) {
            HomeViewEvent.Init -> {
                appTracker.trackScreenView()
                loadStoreInfo()
            }

            is HomeViewEvent.QuickLinkClick -> {
                appTracker.trackQuickLinkClick(id = event.id)
                tryEmitEffect(HomeSideEffect.GoToQuickLink(event.id))
            }

            HomeViewEvent.StoreNameClick -> {
                appTracker.trackStoreNameClick()
                tryEmitEffect(HomeSideEffect.GoToHome)
            }

            is HomeViewEvent.SwitchLanguageClick -> {
                switchLanguage()
                val language = getPreferences().language
                appTracker.trackLanguageClick(language)
                tryEmitEffect(
                    HomeSideEffect.RefreshLanguage(
                        language = language
                    )
                )
            }

            HomeViewEvent.SwitchColorModeClick -> {
                switchColorMode()
                val colorMode = getPreferences().colorMode
                appTracker.trackColorModeClick(colorMode)
                tryEmitEffect(
                    HomeSideEffect.RefreshColorMode(
                        colorMode = colorMode
                    )
                )
            }
        }
    }

    private fun loadStoreInfo() {
        scope.launch {
            try {
                val storeName = getStore().name
                updateState(
                    newState = HomeViewState.Loaded(storeName),
                )
            } catch (cause: Throwable) {
                appTracker.trackError(cause)
                handleStoreErrors(cause)
            }
        }
    }

    private fun handleStoreErrors(cause: Throwable) {
        when (cause) {
            is Store.NotFoundError -> {
                tryEmitEffect(HomeSideEffect.GoToStoreNotFound)
            }

            else -> {
                updateState(
                    newState = HomeViewState.Error,
                )
            }
        }
    }
}
