package com.taager.dukan.order.data.remote.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PlaceOrderRequest(
    @SerialName("fullName")
    val fullName: String,
    @SerialName("address")
    val address: String,
    @SerialName("phoneNumber")
    val phoneNumber: String,
    @SerialName("alternativePhoneNumber")
    val alternativePhoneNumber: String?,
    @SerialName("email")
    val email: String?,
    @SerialName("products")
    val products: List<Products>,
    @SerialName("notes")
    val notes: String?,
    @SerialName("provinceId")
    val provinceId: String,
    @SerialName("cityId")
    val cityId: String,
    @SerialName("districtId")
    val districtId: String?,
    @SerialName("captchaToken")
    val captchaToken: String,
) {
    @Serializable

    data class Products(

        @SerialName("sku")
        val sku: String,

        @SerialName("quantity")
        val quantity: Int,
    )
}
