package com.taager.dukan.store.data.di

import com.taager.dukan.store.data.StoreRepositoryImpl
import com.taager.dukan.store.data.local.QuickLinkCache
import com.taager.dukan.store.data.local.StoreCache
import com.taager.dukan.store.data.local.model.StoreModel
import com.taager.dukan.store.data.remote.StoreApi
import com.taager.dukan.store.domain.StoreRepository
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import kotlin.time.Duration.Companion.minutes

object StoreDataDI {
    val module = DI.Module("Store Data DI") {
        bindProvider {
            StoreApi(
                client = instance()
            )
        }
        bindSingleton {
            StoreCache(
                memoryCache = instance(),
                diskCache = instance(),
            )
        }
        bindProvider {
            MemoryCache<StoreCache.Key, StoreModel>(
                maxAgeInMilliseconds = 30.minutes.inWholeMilliseconds
            )
        }
        bindProvider {
            DiskCache<StoreCache.Key, StoreModel>(
                maxAgeInMilliseconds = 0,
                serializer = StoreModel.serializer()
            )
        }
        bindSingleton {
            QuickLinkCache()
        }
        bindProvider<StoreRepository> {
            StoreRepositoryImpl(
                api = instance(),
                translator = instance(),
                storeCache = instance(),
                quickLinkCache = instance(),
            )
        }
    }
}
