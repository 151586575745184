package com.taager.dukan.feature.checkout.success.tracking

import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

private object CheckoutSuccessScreen : Screen(name = "checkout_success")

internal fun AppTracker.trackScreenView() {
    trackEvent(
        ScreenViewTrackingEvent(screen = CheckoutSuccessScreen)
    )
}

internal fun AppTracker.trackContactEmailClick(
    email: String,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = CheckoutSuccessScreen,
            event = "contact_email_click",
            extras = mapOf("email" to email)
        )
    )
}
