package com.taager.dukan.product.data.remote

import com.taager.dukan.product.data.remote.response.GetProductsResponse
import com.taager.dukan.product.data.remote.response.ProductDto
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.request.get

private const val PRODUCTS_PATH = "api/products"

internal class ProductApi(
    private val client: HttpClient,
) {

    suspend fun getProducts(): GetProductsResponse =
        client.get(PRODUCTS_PATH)
            .body()

    suspend fun getProduct(sku: String): ProductDto =
        client.get("$PRODUCTS_PATH/$sku")
            .body()
}
