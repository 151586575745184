package com.taager.dukan.i18n

import com.taager.dukan.language.useLanguage
import react.FC
import react.PropsWithChildren
import react.StateInstance
import react.createRequiredContext
import react.useEffect
import react.useRequiredContext
import react.useState
import kotlin.js.Json
import kotlin.js.json

internal val NotInitializedStrings = json()

private typealias StringsState = StateInstance<Json>

private val StringsContext = createRequiredContext<StringsState>()

internal val StringsProvider: FC<PropsWithChildren> = FC { props ->
    val state = useState(initialValue = NotInitializedStrings)
    val (strings, setStrings) = state
    StringsContext(state) {
        if (strings != NotInitializedStrings) {
            +props.children
        }
    }
    val language by useLanguage()
    useEffect(language) {
        fetchStrings(language)
            .then(setStrings::invoke)
    }
}

internal fun useStrings(): StringsState =
    useRequiredContext(StringsContext)
