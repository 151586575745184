package com.taager.dukan.di

import org.kodein.di.DI
import org.kodein.di.DIAware

object MainInjector : DIAware {
    override val di: DI by DI.lazy {
        import(MainDI.module)
    }
}

fun mainDI(): DI = MainInjector.di
