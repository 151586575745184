package com.taager.dukan.ui.component

import com.taager.dukan.env
import com.taager.dukan.language.useLanguage
import com.taager.dukan.ui.component.external.ReCaptcha
import com.taager.dukan.ui.component.external.ReCaptchaBadge
import com.taager.dukan.ui.component.external.ReCaptchaComponent
import com.taager.dukan.ui.component.external.ReCaptchaSize
import com.taager.dukan.ui.component.external.ReCaptchaTheme
import com.taager.dukan.ui.theme.isLight
import com.taager.dukan.ui.theme.useTheme
import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.RefObject
import web.cssom.Display
import web.cssom.Length

internal val InvisibleCaptcha: FC<InvisibleCaptchaProps> = FC { props ->
    val theme = useTheme()
    val language by useLanguage()
    Box {
        sx {
            width = Length.fitContent
            display = Display.inlineBlock
        }
        ReCaptcha {
            ref = props.recaptchaRef
            sitekey = env.RECAPTCHA_SITE_KEY.unsafeCast<String>()
            this.theme = if (theme.isLight) {
                ReCaptchaTheme.light
            } else {
                ReCaptchaTheme.dark
            }
            hl = language.id
            size = ReCaptchaSize.invisible
            badge = ReCaptchaBadge.inline
            onChange = { token ->
                props.onChange(token)
            }
            onErrored = {
                props.onError()
            }
        }
    }
}

internal external interface InvisibleCaptchaProps : Props {
    var recaptchaRef: RefObject<ReCaptchaComponent>
    var onChange: (token: String?) -> Unit
    var onError: () -> Unit
}
