package com.taager.dukan.feature.quicklink.di

import com.taager.dukan.feature.quicklink.QuickLinkPresenter
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object QuickLinkDI {
    val module = DI.Module("Quick Link DI") {
        bindProvider {
            QuickLinkPresenter(
                appTracker = instance(),
                getQuickLink = instance(),
            )
        }
    }
}
