package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.ExpressCheckoutForm
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.product.domain.entity.Product.Media
import com.taager.numbers.Money

sealed class ProductDetailsViewState {

    companion object {
        val Initial: ProductDetailsViewState = Loading
    }

    object Loading : ProductDetailsViewState()

    data class Loaded(
        val product: Product,
        val selectedImageIndex: Int,
        val isOrderNowButtonEnabled: Boolean,
        val isFullscreenButtonVisible: Boolean,
        val fullscreenImage: String?,
        val expressCheckout: ExpressCheckout?,
    ) : ProductDetailsViewState()

    object Error : ProductDetailsViewState()

    data class Product(
        val sku: String,
        val name: String,
        val description: String,
        val medias: List<Media>,
        val availability: Availability,
        val pricingOptions: List<PricingOption>,
    ) {
        internal val selectedPricingOption: PricingOption
            get() = pricingOptions.first(PricingOption::isSelected)

        enum class Availability {
            Available,
            SoldOut,
        }

        data class PricingOption(
            val name: String,
            val description: String?,
            val quantity: Int,
            val price: String,
            internal val internalPrice: Money,
            val isSelected: Boolean,
        )
    }

    data class ExpressCheckout(
        val deliverTo: String,
        val form: ExpressCheckoutForm,
        val paymentMethod: PaymentMethod,
        val availablePaymentMethods: List<PaymentMethod>,
        val codFee: String?,
        val hasPaymentMethodError: Boolean,
        val paymentFormVisibility: PaymentFormVisibility,
        val isPlacingOrder: Boolean,
    )
}
