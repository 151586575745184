package com.taager.dukan.ui.component

import mui.material.Box
import mui.material.Grid
import mui.material.GridProps
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import web.cssom.number

private const val GRID_SYSTEM_COLUMN_COUNT = 12

private val GridBreakpointSpans: Array<Pair<Breakpoint, Float>> = arrayOf(
    Breakpoint.xs to 2f,
    Breakpoint.sm to 3f,
    Breakpoint.md to 4f,
    Breakpoint.lg to 5f,
    Breakpoint.xl to 6f,
)

internal val GridContainer: FC<GridProps> = FC { props ->
    Box {
        sx {
            flexGrow = number(1.0)
        }
        Grid {
            sx = props.sx
            container = true
            spacing = props.spacing
            children = props.children
        }
    }
}

internal external interface GridItemProps : GridProps {
    var responsiveColumns: Boolean
}

internal val GridItem: FC<GridItemProps> = FC { props ->
    Grid {
        sx = props.sx
        item = true
        if (props.responsiveColumns) {
            for ((breakpoint, span) in GridBreakpointSpans) {
                asDynamic()[breakpoint] = GRID_SYSTEM_COLUMN_COUNT / span
            }
        }
        children = props.children
    }
}
