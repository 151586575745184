package com.taager.dukan.feature.home

import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.feature.attributes.useFeatureAttributes
import com.taager.dukan.feature.checkout.payment.SupportedPaymentMethods
import com.taager.dukan.i18n.i18n
import com.taager.dukan.language.useLanguage
import com.taager.dukan.ui.component.paddingVertical
import com.taager.dukan.ui.theme.isDesktop
import com.taager.dukan.ui.theme.isLight
import com.taager.dukan.ui.theme.isMobile
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useColorMode
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import kotlinx.browser.document
import mui.icons.material.BrokenImageRounded
import mui.icons.material.CloseRounded
import mui.icons.material.DarkModeOutlined
import mui.icons.material.LightModeOutlined
import mui.icons.material.MenuRounded
import mui.icons.material.MoreVertOutlined
import mui.material.AppBar
import mui.material.AppBarPosition
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Container
import mui.material.Divider
import mui.material.Icon
import mui.material.IconButton
import mui.material.IconButtonColor
import mui.material.IconButtonEdge
import mui.material.Size
import mui.material.Skeleton
import mui.material.SkeletonVariant.Companion.text
import mui.material.Stack
import mui.material.StackDirection.Companion.column
import mui.material.StackDirection.Companion.row
import mui.material.Toolbar
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant.Companion.caption
import mui.material.styles.TypographyVariant.Companion.h2
import mui.system.Breakpoint.Companion.sm
import mui.system.Breakpoint.Companion.xs
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.router.Outlet
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.FontWeight.Companion.bold
import web.cssom.Globals
import web.cssom.JustifyContent
import web.cssom.number
import web.cssom.pct
import web.cssom.vh
import web.dom.Element
import kotlin.js.Date

internal val HomeScreen = FC<Props> {
    mainDI().composePresenter<HomePresenter, HomeViewState> { presenter, viewState ->

        useEffectOnce {
            presenter.onEvent(HomeViewEvent.Init)
        }

        Content {
            state = viewState
            onEvent = presenter::onEvent
        }

        val navigate = useNavigate()
        var language by useLanguage()
        var colorMode by useColorMode()
        presenter.onSideEffect { effect ->
            when (effect) {
                is HomeSideEffect.GoToStoreNotFound -> {
                    navigate(
                        to = "/store_not_found",
                        options = jso {
                            replace = true
                        }
                    )
                }

                is HomeSideEffect.GoToQuickLink -> {
                    navigate(to = "/${effect.id}")
                }

                HomeSideEffect.GoToHome -> {
                    navigate(
                        to = "/",
                        options = jso {
                            replace = true
                        }
                    )
                }

                is HomeSideEffect.RefreshLanguage -> {
                    language = effect.language
                }

                is HomeSideEffect.RefreshColorMode -> {
                    colorMode = effect.colorMode
                }
            }
        }
    }
}

private external interface ContentProps :
    ViewStateProps<HomeViewState>,
    ViewEventProps<HomeViewEvent>,
    PropsWithSx

private val Content: FC<ContentProps> = FC { props ->
    Box {
        sx {
            // Properties needed to make the footer stick to the bottom of the page
            minHeight = 100.vh
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        val theme = useTheme()
        val state = props.state

        if (theme.isDesktop) {
            DesktopHeader {
                this.state = state
                onEvent = props.onEvent
            }
            DesktopTopBar {
                this.state = state
                onEvent = props.onEvent
            }
            Divider {
                sx {
                    width = 100.pct
                }
            }
        } else {
            MobileTopBar {
                this.state = state
                onEvent = props.onEvent
            }
        }
        Container {
            sx {
                paddingVertical = theme.spacing(2)
            }
            Outlet()
        }
        Footer {
            this.state = state
            onEvent = props.onEvent
        }
    }
}

private val DesktopHeader: FC<ContentProps> = FC { props ->
    val theme = useTheme()
    Box {
        sx {
            backgroundColor = theme.palette.primary.main
            paddingVertical = theme.spacing(1)
            color = theme.palette.primary.contrastText
        }
        Container {
            Stack {
                sx {
                    justifyContent = JustifyContent.spaceBetween
                }
                direction = responsive(row)

                Stack {
                    direction = responsive(row)
                    spacing = responsive(2)

                    QuickLink {
                        id = "about_us"
                        text = "home_quick_link_about_us".i18n()
                        onEvent = props.onEvent
                    }
                    QuickLink {
                        id = "shipping_policy"
                        text = "home_quick_link_shipping_policy".i18n()
                        onEvent = props.onEvent
                    }
                    QuickLink {
                        id = "contact_us"
                        text = "home_quick_link_contact_us".i18n()
                        onEvent = props.onEvent
                    }
                }
            }
        }
    }
}

private val MobileTopBar: FC<ContentProps> = FC { props ->
    val theme = useTheme()
    val featureAttributes = useFeatureAttributes()
    AppBar {
        position = AppBarPosition.static

        Toolbar {
            var hamburgerMenuAnchor by useState<Element?>(initialValue = null)
            IconButton {
                sx {
                    marginInlineEnd = theme.spacing(1)
                }
                edge = IconButtonEdge.start
                size = Size.large
                color = IconButtonColor.inherit

                if (hamburgerMenuAnchor == null) {
                    MenuRounded()
                } else {
                    CloseRounded()
                }

                onClick = { event ->
                    hamburgerMenuAnchor = event.currentTarget.parentElement
                }
            }

            Box {
                sx {
                    flexGrow = number(1.0)
                }
            }

            var moreMenuAnchor by useState<Element?>(initialValue = null)
            if (featureAttributes.isLanguageSwitcherEnabled || featureAttributes.isColorModeSwitcherEnabled) {
                IconButton {
                    color = IconButtonColor.inherit
                    MoreVertOutlined()
                    onClick = { event ->
                        moreMenuAnchor = event.currentTarget.parentElement
                    }
                }
            }

            HamburgerMenu {
                anchor = hamburgerMenuAnchor
                onClose = { hamburgerMenuAnchor = null }
                onQuickLinkClick = { id: String ->
                    props.onEvent(HomeViewEvent.QuickLinkClick(id))
                    hamburgerMenuAnchor = null
                }
            }

            MoreMenu {
                anchor = moreMenuAnchor
                onClose = { moreMenuAnchor = null }
                onSwitchLanguageClick = { props.onEvent(HomeViewEvent.SwitchLanguageClick) }
                onSwitchColorModeClick = {
                    props.onEvent(HomeViewEvent.SwitchColorModeClick)
                    moreMenuAnchor = null
                }
            }
        }
    }
}

private val DesktopTopBar: FC<ContentProps> = FC { props ->
    val theme = useTheme()
    val featureAttributes = useFeatureAttributes()
    Container {
        Stack {
            sx {
                paddingVertical = theme.spacing(2)
                justifyContent = JustifyContent.spaceBetween
            }
            direction = responsive(row)
            Box {
                sx {
                    width = 33.pct
                    display = Display.flex
                    justifyContent = JustifyContent.start
                }
                if (featureAttributes.isColorModeSwitcherEnabled) {
                    IconButton {
                        if (theme.isLight) {
                            DarkModeOutlined()
                        } else {
                            LightModeOutlined()
                        }
                        onClick = {
                            props.onEvent(HomeViewEvent.SwitchColorModeClick)
                        }
                    }
                }
            }

            Box {
                sx {
                    width = 33.pct
                    display = Display.flex
                    justifyContent = JustifyContent.end
                }
                if (featureAttributes.isLanguageSwitcherEnabled) {
                    LanguageSwitcher {
                        onClick = { props.onEvent(HomeViewEvent.SwitchLanguageClick) }
                    }
                }
            }
        }
    }
}

private val StoreName: FC<ContentProps> = FC { props ->
    Box {
        sx = props.sx
        when (val state = props.state) {

            HomeViewState.Loading -> {
                val theme = useTheme()
                Skeleton {
                    height = theme.spacing(6)
                    width = theme.spacing(25)
                    variant = text
                }
            }

            is HomeViewState.Loaded -> {
                updateDocument(
                    title = state.storeName,
                    description = "app_description".i18n(),
                )
                Button {
                    sx {
                        color = Globals.inherit
                    }
                    variant = ButtonVariant.text
                    Typography {
                        sx {
                            fontWeight = bold
                        }
                        noWrap = true
                        variant = h2

                        +state.storeName
                    }
                    onClick = { props.onEvent(HomeViewEvent.StoreNameClick) }
                }
            }

            HomeViewState.Error -> {
                Icon {
                    BrokenImageRounded()
                }
            }
        }
    }
}

private fun updateDocument(
    title: String,
    description: String,
) {
    document.title = title
    document.querySelector(selectors = "meta[name='description']")
        ?.setAttribute(
            qualifiedName = "content",
            value = description
        )
}

private val Footer: FC<ContentProps> = FC { props ->
    val theme = useTheme()
    Box {
        sx {
            // Make the footer stick to the bottom of the page but scroll when needed
            marginTop = Auto.auto
            paddingVertical = theme.spacing(3)
            backgroundColor = theme.palette.primary.light
        }
        Container {
            Stack {
                spacing = responsive(2)

                Stack {
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.center
                    }
                    spacing = responsive(2)
                    direction = responsive(xs to column, sm to row)

                    StoreName {
                        state = props.state
                        onEvent = props.onEvent
                    }

                    Stack {
                        direction = responsive(xs to column, sm to row)
                        spacing = responsive(xs to 0 as Number, sm to 2)

                        QuickLink {
                            id = "about_us"
                            text = "home_quick_link_about_us".i18n()
                            onEvent = props.onEvent
                        }
                        QuickLink {
                            id = "shipping_policy"
                            text = "home_quick_link_shipping_policy".i18n()
                            onEvent = props.onEvent
                        }
                        QuickLink {
                            id = "payment_methods"
                            text = "home_quick_link_payment_methods".i18n()
                            onEvent = props.onEvent
                        }
                        QuickLink {
                            id = "return_policy"
                            text = "home_quick_link_return_policy".i18n()
                            onEvent = props.onEvent
                        }
                        QuickLink {
                            id = "privacy_policy"
                            text = "home_quick_link_privacy_policy".i18n()
                            onEvent = props.onEvent
                        }
                        QuickLink {
                            id = "contact_us"
                            text = "home_quick_link_contact_us".i18n()
                            onEvent = props.onEvent
                        }
                    }
                }

                Divider()

                Stack {
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.center
                    }

                    direction = responsive(xs to column, sm to row)
                    spacing = responsive(2)

                    val year by useState { Date().getFullYear().toString() }
                    Typography {
                        sx {
                            fontWeight = FontWeight.medium
                            color = theme.palette.text.secondary
                        }
                        if (theme.isMobile) {
                            align = TypographyAlign.center
                        }
                        variant = caption

                        +"home_copyright".i18n("year" to year)
                    }

                    SupportedPaymentMethods()
                }
            }
        }
    }
}

private external interface QuickLinkProps : ContentProps {
    var id: String
    var text: String
}

private val QuickLink: FC<QuickLinkProps> = FC { props ->
    Button {
        sx {
            color = Globals.inherit
        }
        size = Size.small
        variant = ButtonVariant.text
        +props.text
        onClick = {
            props.onEvent(HomeViewEvent.QuickLinkClick(id = props.id))
        }
    }
}
