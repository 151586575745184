package com.taager.dukan.product.domain.di

import com.taager.dukan.product.domain.interactor.GetProductUseCase
import com.taager.dukan.product.domain.interactor.GetProductsBySkusUseCase
import com.taager.dukan.product.domain.interactor.GetProductsUseCase
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object ProductDomainDI {
    val module = DI.Module("Product Domain DI") {
        bindProvider {
            GetProductsUseCase(
                getStore = instance(),
                repository = instance(),
            )
        }
        bindProvider {
            GetProductsBySkusUseCase(
                getStore = instance(),
                repository = instance(),
            )
        }
        bindProvider {
            GetProductUseCase(
                getStore = instance(),
                repository = instance(),
            )
        }
    }
}
