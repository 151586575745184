package com.taager.dukan.feature.quicklink

import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.i18n.i18n
import com.taager.dukan.navigation.openMailTo
import com.taager.dukan.ui.component.BackButton
import com.taager.dukan.ui.component.ErrorAlertPanel
import com.taager.dukan.ui.component.Linkify
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.material.CircularProgress
import mui.material.Divider
import mui.material.Stack
import mui.material.Typography
import mui.material.styles.TypographyVariant.Companion.body2
import mui.material.styles.TypographyVariant.Companion.h3
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.router.useLocation
import react.router.useNavigate
import react.useEffect
import web.cssom.FontWeight
import web.cssom.FontWeight.Companion.bold
import web.cssom.WhiteSpace.Companion.preLine

internal val QuickLinkScreen: FC<Props> = FC {
    mainDI().composePresenter<QuickLinkPresenter, QuickLinkViewState> { presenter, viewState ->

        val location = useLocation()
        val id = location.pathname.split("/").last()

        useEffect(id) {
            presenter.onEvent(QuickLinkViewEvent.Init(id))
        }

        Content {
            state = viewState
            onEvent = presenter::onEvent
        }

        val navigate = useNavigate()
        presenter.onSideEffect { effect ->
            when (effect) {
                is QuickLinkSideEffect.GoToHome -> {
                    navigate(
                        to = "/",
                        options = jso {
                            replace = true
                        }
                    )
                }

                is QuickLinkSideEffect.OpenMailTo -> {
                    openMailTo(effect.email)
                }
            }
        }
    }
}

private external interface QuickLinkContextProps :
    ViewStateProps<QuickLinkViewState>,
    ViewEventProps<QuickLinkViewEvent>

private val Content: FC<QuickLinkContextProps> = FC { props ->
    val theme = useTheme()
    Stack {
        spacing = responsive(2)

        val navigate = useNavigate()
        BackButton {
            text = "home_back_button".i18n()
            onClick = {
                navigate(
                    to = "/",
                    options = jso {
                        replace = true
                    }
                )
            }
        }

        when (val state = props.state) {

            is QuickLinkViewState.Loading -> {
                CircularProgress()
            }

            is QuickLinkViewState.Loaded -> {
                val quickLink = state.quickLink
                Typography {
                    sx {
                        fontWeight = bold
                    }
                    variant = h3
                    +quickLink.title
                }
                Divider()
                Typography {
                    sx {
                        paddingTop = theme.spacing(2)
                        fontWeight = bold
                    }
                    variant = h3
                    +quickLink.headline
                }
                Typography {
                    sx {
                        whiteSpace = preLine
                        fontWeight = FontWeight.medium
                    }
                    variant = body2

                    Linkify {
                        +quickLink.content
                        onClick = { link ->
                            props.onEvent(QuickLinkViewEvent.EmailClick(link))
                        }
                    }
                }
            }

            is QuickLinkViewState.Error -> {
                ErrorAlertPanel()
            }
        }
    }
}
