package com.taager.dukan.feature.checkout

import com.taager.checkout.field.DefaultCheckoutForm
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.product.domain.entity.Product.Media
import com.taager.numbers.Money

sealed class CheckoutViewState {
    object Loading : CheckoutViewState()
    data class Loaded(
        val deliverTo: String,
        val form: DefaultCheckoutForm,
        val orderDetails: OrderDetails,
        val paymentMethod: PaymentMethod,
        val availablePaymentMethods: List<PaymentMethod>,
        val hasPaymentMethodError: Boolean,
        val paymentFormVisibility: PaymentFormVisibility,
        val isPlacingOrder: Boolean,
        val isPlaceOrderButtonEnabled: Boolean,
    ) : CheckoutViewState()

    data class OrderDetails(
        val products: List<Product>,
        val subtotal: Calculation,
        val shippingCost: Calculation,
        val codFee: Calculation,
        val total: Calculation,
        // Only used by the presentation layer
        internal val internalTotal: Money
    ) {

        // internal val is only used by the presentation layer
        data class Product(
            val sku: String,
            val media: Media,
            val name: String,
            val priceName: String,
            val priceContent: String,
            internal val internalPrice: Money,
            val quantity: Int,
            val availability: Availability,
        ) {
            enum class Availability {
                Available,
                SoldOut,
            }
        }

        sealed class Calculation {
            object Idle : Calculation()
            object Loading : Calculation()
            data class Loaded(val result: String?) : Calculation()
            object Error : Calculation()
        }
    }

    object Error : CheckoutViewState()

    companion object {
        val Initial: CheckoutViewState = Loading
    }
}
