package com.taager.checkout.field

import com.taager.dukan.customer.domain.entity.City
import com.taager.dukan.customer.domain.entity.Customer
import com.taager.dukan.customer.domain.entity.District

internal fun Customer.toFormCity(): CheckoutForm.City =
    CheckoutForm.City(
        id = city.id,
        name = city.name,
        province = CheckoutForm.Province(
            id = province.id,
            name = province.name,
        )
    )

internal fun Customer.toFormDistrict(): CheckoutForm.District? =
    district?.let {
        CheckoutForm.District(
            id = it.id,
            name = it.name,
        )
    }

fun List<City>.transform(): List<CheckoutForm.City> =
    map { city ->
        CheckoutForm.City(
            id = city.id,
            name = city.name,
            province = CheckoutForm.Province(
                id = city.province.id,
                name = city.province.name,
            )
        )
    }

fun List<District>.transform(): List<CheckoutForm.District> =
    map { district ->
        CheckoutForm.District(
            id = district.id,
            name = district.name,
        )
    }
