@file:Suppress("MatchingDeclarationName")

package com.taager.dukan.feature.productdetails.tracking

import com.taager.dukan.product.domain.entity.Product
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

internal object ProductDetailsScreen : Screen(name = "product_details")

internal fun AppTracker.trackScreenView(sku: String) {
    trackEvent(
        ScreenViewTrackingEvent(
            screen = ProductDetailsScreen,
            extras = mapOf("sku" to sku)
        )
    )
}

internal fun AppTracker.trackViewContent(product: Product) {
    val defaultPricingOption = product.defaultPricingOption
    val price = defaultPricingOption.price
    val quantity = defaultPricingOption.quantity
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "view_content",
            extras = mapOf(
                "sku" to product.sku,
                "product_name" to product.name,
                "product_price" to price.value,
                "currency" to price.currency.iso4217Code,
                "quantity" to quantity,
                "total" to price.value,
            )
        )
    )
}

internal fun AppTracker.trackBackClick(sku: String) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "back_click",
            extras = mapOf("sku" to sku)
        )
    )
}

internal fun AppTracker.trackMediaClick(
    sku: String,
    media: Product.Media,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "media_click",
            extras = mapOf(
                "sku" to sku,
                "media" to media.url,
            )
        )
    )
}

internal fun AppTracker.trackOrderNowClick(sku: String, quantity: Int) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "order_now_click",
            extras = mapOf("sku" to sku, "quantity" to quantity)
        )
    )
}

internal fun AppTracker.trackFullscreenImageClick(
    sku: String,
    image: String
) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "fullscreen_click",
            extras = mapOf(
                "sku" to sku,
                "image" to image
            )
        )
    )
}

internal fun AppTracker.trackExitFullscreenClick(sku: String) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "exit_fullscreen_click",
            extras = mapOf("sku" to sku)
        )
    )
}

internal fun AppTracker.trackExitFullscreenKeyDown(sku: String) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "exit_fullscreen_key_down",
            extras = mapOf("sku" to sku)
        )
    )
}
