package com.taager.dukan.feature.checkout.payment.tracking

import com.taager.dukan.product.domain.entity.Product
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

private object PostPaymentScreen : Screen(name = "post_payment")

internal fun AppTracker.trackScreenView(
    paymentId: String?,
    sku: String,
    quantity: Int?,
) {
    trackEvent(
        ScreenViewTrackingEvent(
            screen = PostPaymentScreen,
            extras = mapOf(
                "payment_id" to paymentId.toString(),
                "products" to listOf(
                    mapOf(
                        "sku" to sku,
                        "quantity" to quantity.toString(),
                    )
                ),
            )
        )
    )
}

internal fun AppTracker.trackCompletePayment(
    paymentId: String,
    product: Product,
    quantity: Int,
) {
    val pricingOption = product.pricingOptionForQuantity(quantity)
    trackEvent(
        BaseTrackingEvent(
            screen = PostPaymentScreen,
            event = "complete_payment",
            extras = mapOf(
                "payment_id" to paymentId,
                "products" to listOf(
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to pricingOption.price.value,
                        "quantity" to quantity,
                    )
                ),
                "currency" to pricingOption.price.currency.iso4217Code,
                "total" to pricingOption.price.value
            )
        )
    )
}

internal fun AppTracker.trackContactEmailClick(
    email: String,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = PostPaymentScreen,
            event = "contact_email_click",
            extras = mapOf("email" to email)
        )
    )
}
