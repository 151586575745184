package com.taager.dukan.feature.productlist.mapper

import com.taager.dukan.feature.productlist.ProductListViewState
import com.taager.dukan.product.domain.entity.Product

internal fun List<Product>.transform(): List<ProductListViewState.Product> =
    map { product ->
        val pricingOption = product.defaultPricingOption
        ProductListViewState.Product(
            sku = product.sku,
            name = product.name,
            pricing = ProductListViewState.Product.Pricing(
                name = pricingOption.name,
                price = pricingOption.price.toString(),
                description = pricingOption.description,
                internalPrice = pricingOption.price,
                internalQuantity = pricingOption.quantity,
            ),
            media = product.medias.first(),
            isSoldOut = product.availability == Product.Availability.OutOfStock,
        )
    }
