package com.taager.dukan.initializer

import com.taager.api.SlugProvider
import com.taager.dukan.appVersion
import com.taager.dukan.isProduction
import com.taager.tracking.AppTracker
import kotlinx.browser.window
import kotlin.js.Promise

internal class AppTrackerInitializer(
    private val appTracker: AppTracker,
    private val slugProvider: SlugProvider
) : Initializer {
    override fun initialize(): Promise<Boolean> {
        appTracker.initialize()
        slugProvider.provideSlug().let(appTracker::identifyUser)
        appTracker.setEventProperty("\$version", appVersion)
        appTracker.setEventProperty("\$project", project)
        appTracker.setEventProperty("system_dark_mode_enabled", isSystemDarkModeEnabled)
        appTracker.setEventProperty("system_language", systemLanguage)
        return Promise.resolve(true)
    }
}

private val project: String
    get() = if (isProduction) {
        "Dukan-prod"
    } else {
        "Dukan-dev"
    }

private val isSystemDarkModeEnabled: String
    get() = window.matchMedia("(prefers-color-scheme: dark)")
        .matches.toString()

private val systemLanguage: String
    get() = window.navigator.language
