package com.taager.dukan.preferences.domain.entity

data class Preferences(
    val language: Language,
    val colorMode: ColorMode,
) {

    enum class Language(
        val id: String,
        val direction: String
    ) {
        English(id = "en", direction = "ltr"),
        Arabic(id = "ar", direction = "rtl"),
    }

    enum class ColorMode { Light, Dark }
}
