package com.taager.numbers

import com.taager.country.model.Currency

data class Money(
    val currency: Currency,
    val value: Double
) {

    // Cache the formatted value
    // to avoid unnecessary calculations
    // when using toString()
    private val formatted: String = value.format()

    override fun toString(): String =
        "$formatted ${currency.arabicShortName}"

    operator fun compareTo(other: Money): Int =
        value.compareTo(other.value)

    operator fun plus(other: Money): Money =
        ensureSameCurrency(other).run {
            Money(
                currency = currency,
                value = value + other.value
            )
        }

    operator fun minus(other: Money): Money =
        ensureSameCurrency(other).run {
            Money(
                currency = currency,
                value = value - other.value
            )
        }

    operator fun times(other: Money): Money =
        ensureSameCurrency(other).run {
            Money(
                currency = currency,
                value = value * other.value
            )
        }

    operator fun div(other: Money): Money =
        ensureSameCurrency(other).run {
            Money(
                currency = currency,
                value = value / other.value
            )
        }

    operator fun times(other: Int): Money =
        Money(
            currency = currency,
            value = value * other
        )

    operator fun div(other: Int): Money =
        Money(
            currency = currency,
            value = value / other
        )

    private fun ensureSameCurrency(other: Money) {
        require(currency.iso4217Code == other.currency.iso4217Code) {
            "Operation not allowed for different currencies"
        }
    }
}

fun Number.toMoney(currency: Currency): Money =
    Money(
        currency = currency,
        value = toDouble()
    )
