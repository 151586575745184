package com.taager.dukan.product.data

import com.taager.country.model.Currency
import com.taager.dukan.product.data.remote.ProductApi
import com.taager.dukan.product.data.remote.mapper.transform
import com.taager.dukan.product.domain.ProductRepository
import com.taager.dukan.product.domain.entity.Product
import com.taager.experience.data.exception.ApiException
import com.taager.translator.Translator

internal class ProductRepositoryImpl(
    private val api: ProductApi,
    private val translator: Translator,
) : ProductRepository {

    override suspend fun getProducts(currency: Currency): List<Product> =
        api.getProducts()
            .transform(
                currency = currency,
                translator = translator
            )

    override suspend fun getProducts(
        currency: Currency,
        skus: List<String>
    ) =
        // TODO Use the right endpoint to fetch products by skus in one request
        skus.map { sku ->
            getProduct(currency, sku)
        }

    override suspend fun getProduct(
        currency: Currency,
        sku: String
    ): Product =
        runCatching {
            api.getProduct(sku)
                .transform(
                    currency = currency,
                    translator = translator
                )
        }
            .onFailure(::handleGetProductErrors)
            .getOrThrow()
}

private fun handleGetProductErrors(cause: Throwable) {
    throw when (cause) {
        is ApiException.Client.NotFound -> Product.NotFoundError
        else -> cause
    }
}
