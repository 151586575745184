package com.taager.circuit

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow

abstract class ViewStateHandler<State, SideEffect>(initialState: State) : SideEffectHandler<SideEffect>() {

    private val _state = MutableStateFlow(initialState)
    val state: StateFlow<State> = _state

    protected fun updateState(newState: State) {
        _state.value = newState
    }
}
