package com.taager.circuit

import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.SharedFlow

abstract class SideEffectHandler<SideEffect> : CoroutineScopeHolder() {

    private val _effect = MutableSharedFlow<SideEffect>(
        // We need a buffer to temporarily store values when using tryEmit() because it is not a suspend function
        extraBufferCapacity = 1,
        replay = 1,
    )
    val effect: SharedFlow<SideEffect> = _effect

    protected suspend fun emitEffect(newEffect: SideEffect) {
        _effect.emit(newEffect)
    }

    protected fun tryEmitEffect(newEffect: SideEffect) {
        _effect.tryEmit(newEffect)
    }
}
