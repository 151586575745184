package com.taager.dukan.checkout.data.remote.mapper

import com.taager.country.model.Currency
import com.taager.dukan.checkout.data.remote.request.CalculateCostRequest
import com.taager.dukan.checkout.data.remote.response.CalculateCostResponse
import com.taager.dukan.checkout.domain.entity.Checkout
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.numbers.toMoney

internal fun transform(
    provinceId: String?,
    products: List<Checkout.Product>,
    paymentMethod: PaymentMethod,
): CalculateCostRequest =
    CalculateCostRequest(
        province = provinceId,
        products = products.transform(),
        paymentMethod = paymentMethod.transform()
    )

internal fun CalculateCostResponse.transform(currency: Currency): Checkout =
    Checkout(
        itemsSubTotal = itemsSubTotal.toMoney(currency),
        shippingCost = shippingCost?.toMoney(currency),
        codFee = codFee?.toMoney(currency),
        totalCost = totalCost.toMoney(currency)
    )

private fun List<Checkout.Product>.transform(): List<CalculateCostRequest.Product> =
    map(Checkout.Product::transform)

private fun Checkout.Product.transform(): CalculateCostRequest.Product =
    CalculateCostRequest.Product(
        sku = sku,
        quantity = quantity,
    )

private fun PaymentMethod.transform(): CalculateCostRequest.PaymentMethod =
    when (this) {
        PaymentMethod.CashOnDelivery -> CalculateCostRequest.PaymentMethod.CashOnDelivery
        PaymentMethod.PayByCard -> CalculateCostRequest.PaymentMethod.Prepaid
    }
