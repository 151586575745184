package com.taager.dukan.feature.home.di

import com.taager.dukan.feature.home.HomePresenter
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object HomeDI {
    val module = DI.Module("Home DI") {
        bindProvider {
            HomePresenter(
                appTracker = instance(),
                getStore = instance(),
                getPreferences = instance(),
                switchLanguage = instance(),
                switchColorMode = instance(),
            )
        }
    }
}
