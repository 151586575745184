package com.taager.dukan.preferences.data

import com.taager.dukan.preferences.data.local.PreferencesCache
import com.taager.dukan.preferences.data.local.mapper.transform
import com.taager.dukan.preferences.domain.PreferencesRepository
import com.taager.dukan.preferences.domain.entity.Preferences

internal class PreferencesRepositoryImpl(
    private val cache: PreferencesCache,
) : PreferencesRepository {

    override fun getPreferences(): Preferences =
        cache.get()?.transform() ?: getInitialPreferences().also(::setPreferences)

    override fun setPreferences(preferences: Preferences) {
        cache.set(preferences.transform())
    }
}

private fun getInitialPreferences(): Preferences =
    Preferences(
        language = getInitialLanguage(),
        colorMode = getInitialColorMode()
    )

internal expect fun getInitialLanguage(): Preferences.Language
internal expect fun getInitialColorMode(): Preferences.ColorMode
