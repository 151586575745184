package com.taager.dukan.store.data.local.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class StoreModel(
    @SerialName("countryIsoCode3")
    val countryIsoCode3: String,
    @SerialName("name")
    val name: String,
    @SerialName("contactInfo")
    val contactInfo: ContactInfo?,
    @SerialName("hasEmbeddedCheckoutEnabled")
    val hasEmbeddedCheckoutEnabled: Boolean,
    @SerialName("integrations")
    val integrations: Integrations?,
    @SerialName("payments")
    val payments: Payments,
) {

    @Serializable
    data class ContactInfo(
        @SerialName("email")
        val email: String?,
        @SerialName("phoneNumber")
        val phoneNumber: String?,
    )

    @Serializable
    data class Integrations(
        @SerialName("tiktokPixel")
        val tiktokPixel: TiktokPixel?,
        @SerialName("gTag")
        val gTag: GTagPixel?,
    ) {

        @Serializable
        data class TiktokPixel(
            @SerialName("ids")
            val ids: List<String>,
        )

        @Serializable
        data class GTagPixel(
            @SerialName("ids")
            val ids: List<String>,
        )
    }

    @Serializable
    data class Payments(
        @SerialName("isPrepaidEnabled")
        val isPrepaidEnabled: Boolean,
        @SerialName("codFee")
        val codFee: Double?,
    )
}
