package com.taager.translator

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TranslatableString(
    @SerialName("ar")
    val ar: String,
    @SerialName("en")
    val en: String,
)
