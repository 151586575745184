package com.taager.dukan

import com.taager.dukan.feature.attributes.FeatureAttributesProvider
import com.taager.dukan.i18n.StringsProvider
import com.taager.dukan.language.LanguageProvider
import com.taager.dukan.navigation.Router
import com.taager.dukan.ui.rtl.SupportRTL
import com.taager.dukan.ui.theme.ColorPaletteProvider
import com.taager.dukan.ui.theme.Themed
import react.FC
import react.Props

internal val env: dynamic = js("ENV")
internal val isProduction: Boolean = env.PRODUCTION.unsafeCast<Boolean>()
internal val isDevelopment: Boolean = !isProduction
internal val appVersion: String = env.APP_VERSION.unsafeCast<String>()

internal val App = FC<Props> {
    FeatureAttributesProvider {
        LanguageProvider {
            StringsProvider {
                SupportRTL {
                    ColorPaletteProvider {
                        Themed {
                            Router()
                        }
                    }
                }
            }
        }
    }
}
