package com.taager.dukan.checkout.domain.interactor

import com.taager.dukan.checkout.domain.CheckoutRepository
import com.taager.dukan.checkout.domain.entity.Checkout
import com.taager.dukan.checkout.domain.entity.PaymentMethod

class CalculateCheckoutUseCase(
    private val repository: CheckoutRepository
) {

    suspend operator fun invoke(
        provinceId: String?,
        products: List<Checkout.Product>,
        paymentMethod: PaymentMethod,
    ): Checkout =
        repository.calculateCheckout(
            provinceId,
            products,
            paymentMethod
        )
}
