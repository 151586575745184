package com.taager.tracking.di

import com.taager.tracking.Tracker
import com.taager.tracking.gtm.GoogleTagManagerTracker
import com.taager.tracking.mixpanel.MixpanelTracker
import com.taager.tracking.tiktok.TiktokTracker
import org.kodein.di.DI
import org.kodein.di.inBindSet
import org.kodein.di.singleton

internal actual object InternalTrackingDI {
    actual val module = DI.Module("Internal Tracking DI") {
        inBindSet<Tracker> {
            add { singleton { MixpanelTracker() } }
            add { singleton { TiktokTracker() } }
            add { singleton { GoogleTagManagerTracker() } }
        }
    }
}
