package com.taager.dukan.ui.component

import com.taager.dukan.ui.theme.useTheme
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.PropsWithChildren
import web.cssom.Border
import web.cssom.FontWeight
import web.cssom.LineStyle
import web.cssom.px

internal val FakeOutlinedButton: FC<FakeOutlinedButtonProps> = FC { props ->
    val theme = useTheme()
    Typography {
        sx {
            +props.sx
            padding = theme.spacing(1)
            border = Border(1.px, LineStyle.solid, theme.palette.text.primary)
            borderRadius = 3.px
            fontWeight = FontWeight.bold
        }
        variant = TypographyVariant.button
        align = TypographyAlign.center
        +props.children
    }
}

internal external interface FakeOutlinedButtonProps : PropsWithSx, PropsWithChildren
