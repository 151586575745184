package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.InputField
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.ui.component.external.ReCaptchaComponent
import react.FC
import react.PropsWithChildren
import react.RefObject
import react.StateInstance
import react.createRequiredContext
import react.useRef
import react.useRequiredContext
import react.useState
import web.html.HTMLElement
import kotlin.reflect.KClass

private typealias ExpressCheckoutContextState = StateInstance<ExpressCheckoutContext>

private val RequiredContext = createRequiredContext<ExpressCheckoutContextState>()

internal val ExpressCheckoutContextProvider: FC<PropsWithChildren> = FC { props ->
    val state = useState(initialValue = ExpressCheckoutContext.Initial)
    RequiredContext(state) {
        +props.children
    }
}

internal data class ExpressCheckoutContext(
    val fieldRefs: Map<KClass<*>, RefObject<HTMLElement>>,
    val recaptchaRef: RefObject<ReCaptchaComponent>,
) {
    companion object {
        val Initial = ExpressCheckoutContext(
            fieldRefs = mapOf(
                InputField.FullName::class to useRef(),
                InputField.PhoneNumber::class to useRef(),
                InputField.City::class to useRef(),
                InputField.District::class to useRef(),
                InputField.Address::class to useRef(),
                PaymentMethod::class to useRef(),
            ),
            recaptchaRef = useRef()
        )
    }
}

internal fun useExpressCheckoutContext(): ExpressCheckoutContextState =
    useRequiredContext(RequiredContext)
