package com.taager.dukan.order.data.di

import com.taager.dukan.order.data.OrderRepositoryImpl
import com.taager.dukan.order.data.remote.OrderApi
import com.taager.dukan.order.domain.OrderRepository
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object OrderDataDI {
    val module = DI.Module("Order Data DI") {
        bindProvider {
            OrderApi(
                client = instance()
            )
        }
        bindProvider<OrderRepository> {
            OrderRepositoryImpl(
                api = instance()
            )
        }
    }
}
