package com.taager.dukan.feature.checkout.success

sealed class CheckoutSuccessViewState {

    companion object {
        val Initial: CheckoutSuccessViewState = Loading
    }

    object Loading : CheckoutSuccessViewState()

    data class Loaded(val contactEmail: String?) : CheckoutSuccessViewState()
}
