package com.taager.translator.di

import com.taager.translator.Translator
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object TranslatorDI {
    val module = DI.Module("Translator DI") {
        bindProvider {
            Translator(
                getPreferences = instance()
            )
        }
    }
}
