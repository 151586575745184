package com.taager.firebase.remoteconfig

import com.taager.firebase.external.FirebaseRemoteConfigSettings
import kotlin.js.Json

internal fun FirebaseRemoteConfigSettings.setUpRemoteConfigSettings() {
    fetchTimeoutMillis = FetchTimeoutInMillis
    minimumFetchIntervalMillis = MinimumFetchIntervalInMillis
}

internal fun Map<String, Any>.asJson(): Json {
    val jso = js("{}")
    forEach { (key, value) ->
        jso[key] = value
    }
    return jso.unsafeCast<Json>()
}
