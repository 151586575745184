package com.taager.dukan.store.data

import com.taager.dukan.store.data.local.QuickLinkCache
import com.taager.dukan.store.data.local.StoreCache
import com.taager.dukan.store.data.local.mapper.transform
import com.taager.dukan.store.data.remote.StoreApi
import com.taager.dukan.store.data.remote.mapper.transform
import com.taager.dukan.store.domain.StoreRepository
import com.taager.dukan.store.domain.entity.QuickLink
import com.taager.dukan.store.domain.entity.Store
import com.taager.experience.data.exception.ApiException
import com.taager.translator.Translator

internal class StoreRepositoryImpl(
    private val api: StoreApi,
    private val translator: Translator,
    private val storeCache: StoreCache,
    private val quickLinkCache: QuickLinkCache,
) : StoreRepository {

    override suspend fun getStore(): Store =
        getFromCache() ?: fetchFromApi().also { store ->
            storeCache.set(store.transform())
        }

    override suspend fun getQuickLink(id: String): QuickLink =
        quickLinkCache[id].transform(
            translator = translator,
            email = getStore().contactInfo?.email
        )

    private fun getFromCache(): Store? =
        storeCache.get()?.transform()

    private suspend fun fetchFromApi(): Store =
        runCatching<Store> {
            api.getInfo()
                .transform()
        }
            .onFailure(::handleGetInfoErrors)
            .getOrThrow()
}

private fun handleGetInfoErrors(cause: Throwable) {
    throw when (cause) {
        is ApiException.Client.NotFound -> Store.NotFoundError
        else -> cause
    }
}
