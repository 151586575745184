package com.taager.featuremanager

interface FeatureAttributes {
    val isLanguageSwitcherEnabled: Boolean
    val isColorModeSwitcherEnabled: Boolean

    companion object {
        const val IS_LANGUAGE_SWITCHER_ENABLED = "IS_LANGUAGE_SWITCHER_ENABLED"
        const val IS_COLOR_MODE_SWITCHER_ENABLED = "IS_COLOR_MODE_SWITCHER_ENABLED"

        val Default: Map<String, Any> =
            mapOf(
                IS_LANGUAGE_SWITCHER_ENABLED to false,
                IS_COLOR_MODE_SWITCHER_ENABLED to false,
            )
    }
}
