package com.taager.dukan.store.domain.entity

import com.taager.country.model.Country
import com.taager.numbers.Money

data class Store(
    val name: String,
    val country: Country,
    val contactInfo: ContactInfo?,
    val isExpressCheckoutEnabled: Boolean,
    val integrations: Integrations?,
    val payments: Payments,
) {

    data class ContactInfo(
        val email: String?,
        val phoneNumber: String?
    )

    data class Integrations(
        val tiktokPixel: Pixel.Tiktok?,
        val gTag: Pixel.GTag?,
    ) {
        sealed interface Pixel {

            val ids: List<String>

            data class Tiktok(
                override val ids: List<String>,
            ) : Pixel

            data class GTag(
                override val ids: List<String>,
            ) : Pixel
        }
    }

    data class Payments(
        val isPrepaidEnabled: Boolean,
        val codFee: Money?,
    )

    object NotFoundError : IllegalStateException()
}
