package com.taager.dukan.preferences.domain.interactor

import com.taager.dukan.preferences.domain.PreferencesRepository
import com.taager.dukan.preferences.domain.entity.Preferences.ColorMode

class SwitchColorModeUseCase(
    private val repository: PreferencesRepository,
) {

    operator fun invoke() {
        val preferences = repository.getPreferences()
        val newColorMode = when (preferences.colorMode) {
            ColorMode.Light -> ColorMode.Dark
            ColorMode.Dark -> ColorMode.Light
        }
        repository.setPreferences(preferences.copy(colorMode = newColorMode))
    }
}
