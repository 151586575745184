package com.taager.dukan.customer.data.local.mapper

import com.taager.dukan.customer.data.local.model.DistrictModel
import com.taager.dukan.customer.domain.entity.District

internal fun District.transform(): DistrictModel =
    DistrictModel(
        id = id,
        name = name,
    )

internal fun DistrictModel.transform(): District =
    District(
        id = id,
        name = name,
    )
