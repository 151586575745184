package com.taager.checkout.field

fun DefaultCheckoutForm.updateFieldValue(
    inputField: InputField<*>,
    newValue: Any?
): DefaultCheckoutForm {
    var fullName = fullName
    var phoneNumber = phoneNumber
    var alternativePhoneNumber = alternativePhoneNumber
    var email = email
    var city = city
    var district = district
    var address = address
    var notes = notes
    val filteredNewValue = inputField.filter(newValue)
    when (inputField) {
        is InputField.FullName -> {
            fullName = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.PhoneNumber -> {
            phoneNumber = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.AlternativePhoneNumber -> {
            alternativePhoneNumber = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.Email -> {
            email = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.City -> {
            city = inputField.copy(value = filteredNewValue as CheckoutForm.City?)
            // The district field depends on the city field.
            // So let's reset the district when a city is selected.
            district = district?.copy(value = null)
        }

        is InputField.District -> {
            district = inputField.copy(value = filteredNewValue as CheckoutForm.District?)
        }

        is InputField.Address -> {
            address = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.Notes -> {
            notes = inputField.copy(value = filteredNewValue as String)
        }
    }
    return copy(
        fullName = fullName,
        phoneNumber = phoneNumber,
        alternativePhoneNumber = alternativePhoneNumber,
        email = email,
        city = city,
        district = district,
        address = address,
        notes = notes,
    )
}

fun ExpressCheckoutForm.updateFieldValue(
    inputField: InputField<*>,
    newValue: Any?
): ExpressCheckoutForm {
    var fullName = fullName
    var phoneNumber = phoneNumber
    var city = city
    var district = district
    var address = address
    val filteredNewValue = inputField.filter(newValue)
    when (inputField) {
        is InputField.FullName -> {
            fullName = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.PhoneNumber -> {
            phoneNumber = inputField.copy(value = filteredNewValue as String)
        }

        is InputField.City -> {
            city = inputField.copy(value = filteredNewValue as CheckoutForm.City?)
            // The district field depends on the city field.
            // So let's reset the district when a city is selected.
            district = district?.copy(value = null)
        }

        is InputField.District -> {
            district = inputField.copy(value = filteredNewValue as CheckoutForm.District?)
        }

        is InputField.Address -> {
            address = inputField.copy(value = filteredNewValue as String)
        }

        else -> Unit
    }
    return copy(
        fullName = fullName,
        phoneNumber = phoneNumber,
        city = city,
        district = district,
        address = address,
    )
}
