package com.taager.tracking.mapper

import kotlin.js.Json

/** Convert the event properties map to a JS object */
internal fun Map<String, Any>.transform(): Json {
    val properties = js("{}")
    forEach { (key, value) ->
        when (value) {
            is List<*> -> {
                properties[key] = value.transform()
            }

            else -> {
                properties[key] = value
            }
        }
    }
    return properties.unsafeCast<Json>()
}

/** Convert a list of any to an array, so it can be used in JS */
private fun List<*>.transform(): Array<*> =
    map { item ->
        when (item) {
            is Map<*, *> -> item.transform()
            else -> item
        }
    }.toTypedArray()

/** Convert a map of values to a JS object */
private fun Map<*, *>.transform(): Json {
    val jso = js("{}")
    forEach { (key, value) ->
        jso[key] = value
    }
    return jso.unsafeCast<Json>()
}
