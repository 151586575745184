package com.taager.dukan.ui.theme

import com.taager.dukan.di.mainDI
import com.taager.dukan.preferences.domain.entity.Preferences
import com.taager.dukan.preferences.domain.interactor.GetPreferencesUseCase
import com.taager.tracking.AppTracker
import mui.material.styles.PaletteOptions
import org.kodein.di.instance
import react.FC
import react.PropsWithChildren
import react.StateInstance
import react.createRequiredContext
import react.useMemo
import react.useRequiredContext
import react.useState

private typealias ColorModeState = StateInstance<Preferences.ColorMode>

private val ColorModeContext = createRequiredContext<ColorModeState>()
private val ColorPaletteContext = createRequiredContext<PaletteOptions>()

internal fun useColorMode(): ColorModeState =
    useRequiredContext(ColorModeContext)

internal fun useColorPalette(): PaletteOptions =
    useRequiredContext(ColorPaletteContext)

internal val ColorPaletteProvider = FC<PropsWithChildren> { props ->
    val getPreferences: GetPreferencesUseCase by mainDI().instance()
    val appTracker: AppTracker by mainDI().instance()
    val colorModeState = useState(initialValue = getPreferences().colorMode)
    val (colorMode) = colorModeState
    val colorPalette = useMemo(colorMode) {
        appTracker.trackColorMode(colorMode)
        when (colorMode) {
            Preferences.ColorMode.Dark -> DarkPalette
            Preferences.ColorMode.Light -> LightPalette
        }
    }
    ColorModeContext(colorModeState) {
        ColorPaletteContext(colorPalette) {
            +props.children
        }
    }
}

private fun AppTracker.trackColorMode(colorMode: Preferences.ColorMode) {
    setEventProperty("color_mode", colorMode.name.lowercase())
}
