package com.taager.dukan.feature.main

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.ErrorAlertPanel
import com.taager.dukan.ui.component.paddingVertical
import com.taager.dukan.ui.theme.useTheme
import mui.material.Container
import mui.system.sx
import react.FC
import react.Props

internal val StoreNotFound: FC<Props> = FC {
    val theme = useTheme()
    // TODO Update the UI once we have any defined
    Container {
        sx {
            paddingVertical = theme.spacing(2)
        }
        ErrorAlertPanel {
            message = "error_store_not_found_message".i18n()
        }
    }
}
