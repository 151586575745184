package com.taager.dukan.feature.productdetails

import com.taager.checkout.field.InputField
import com.taager.circuit.js.ViewEventProps
import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.feature.checkout.payment.CheckoutPaymentMethods
import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.Autocomplete
import com.taager.dukan.ui.component.GridContainer
import com.taager.dukan.ui.component.SelectInput
import com.taager.dukan.ui.component.TextInput
import com.taager.dukan.ui.theme.useTheme
import mui.icons.material.LocationOnOutlined
import mui.material.Grid
import mui.material.GridProps
import mui.material.Icon
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useEffect
import web.cssom.Direction
import web.cssom.FontWeight
import web.cssom.div
import web.cssom.important
import web.cssom.pct
import web.dom.document

internal val ExpressCheckout: FC<ExpressCheckoutProps> = FC { props ->
    val theme = useTheme()
    val expressCheckout = props.expressCheckout
    val checkoutForm = expressCheckout.form
    val checkoutContext by useExpressCheckoutContext()
    val fieldRefs = checkoutContext.fieldRefs
    Stack {
        spacing = responsive(2)
        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(1)
            Typography {
                sx {
                    color = theme.palette.text.secondary
                }
                variant = TypographyVariant.caption
                +"checkout_deliver_to_label".i18n()
            }
            Typography {
                sx {
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.caption
                +expressCheckout.deliverTo
            }
        }
        GridContainer {
            spacing = responsive(2)

            GridItem {
                TextInput {
                    sx {
                        width = 100.pct
                    }
                    inputRef = fieldRefs.getValue(InputField.FullName::class)
                    label = "checkout_full_name_label".i18n()
                    inputField = checkoutForm.fullName
                    onChange = { inputField, newValue ->
                        props.onEvent(
                            ProductDetailsViewEvent.UpdateCheckoutField(
                                inputField = inputField,
                                newValue = newValue
                            )
                        )
                    }
                    onFocus = { props.onEvent(ProductDetailsViewEvent.CheckoutFieldFocus) }
                }
            }
            GridItem {
                TextInput {
                    sx {
                        width = 100.pct
                        // TODO A hack to force always LTR direction. A better approach is needed
                        direction = if (document.dir == "rtl") {
                            Direction.rtl
                        } else {
                            Direction.ltr
                        }
                    }
                    inputRef = fieldRefs.getValue(InputField.PhoneNumber::class)
                    label = "checkout_phone_number_label".i18n()
                    inputField = checkoutForm.phoneNumber
                    onChange = { inputField, newValue ->
                        props.onEvent(
                            ProductDetailsViewEvent.UpdateCheckoutField(
                                inputField = inputField,
                                newValue = newValue
                            )
                        )
                    }
                    onFocus = { props.onEvent(ProductDetailsViewEvent.CheckoutFieldFocus) }
                }
            }
            val hasDistrict = checkoutForm.hasDistrict
            GridItem {
                if (hasDistrict.not()) {
                    asDynamic().sm = 12
                }
                Autocomplete {
                    sx {
                        width = 100.pct
                    }
                    inputRef = fieldRefs.getValue(InputField.City::class)
                    label = "checkout_city_label".i18n()
                    inputField = checkoutForm.city
                    loadingText = "checkout_city_loading_text".i18n()
                    placeholderText = "checkout_city_start_typing_placeholder".i18n()
                    startAdornment = Icon.create { LocationOnOutlined() }
                    onQuery = { _, query ->
                        props.onEvent(ProductDetailsViewEvent.QueryCities(query))
                    }
                    onSelect = { inputField, item ->
                        props.onEvent(
                            ProductDetailsViewEvent.UpdateCheckoutField(
                                inputField = inputField,
                                newValue = item
                            )
                        )
                    }
                    onFocus = { props.onEvent(ProductDetailsViewEvent.CheckoutFieldFocus) }
                }
            }
            if (hasDistrict) {
                val district = requireNotNull(checkoutForm.district)
                GridItem {
                    SelectInput {
                        sx {
                            width = 100.pct
                        }
                        inputRef = fieldRefs.getValue(InputField.District::class)
                        label = "checkout_district_label".i18n()
                        inputField = district
                        emptyText = "checkout_district_empty_text".i18n()
                        onChange = { inputField, newValue ->
                            props.onEvent(
                                ProductDetailsViewEvent.UpdateCheckoutField(
                                    inputField = inputField,
                                    newValue = newValue
                                )
                            )
                        }
                        onFocus = { props.onEvent(ProductDetailsViewEvent.CheckoutFieldFocus) }
                    }
                }
            }
            GridItem {
                asDynamic().sm = 12
                TextInput {
                    sx {
                        width = 100.pct
                    }
                    inputRef = fieldRefs.getValue(InputField.Address::class)
                    label = "checkout_address_label".i18n()
                    placeholder = "checkout_address_placeholder".i18n()
                    inputField = checkoutForm.address
                    onChange = { inputField, newValue ->
                        props.onEvent(
                            ProductDetailsViewEvent.UpdateCheckoutField(
                                inputField = inputField,
                                newValue = newValue
                            )
                        )
                    }
                    onFocus = { props.onEvent(ProductDetailsViewEvent.CheckoutFieldFocus) }
                }
            }
        }
        CheckoutPaymentMethods {
            ref = fieldRefs.getValue(PaymentMethod::class)
            isBorderVisible = true
            availablePaymentMethods = expressCheckout.availablePaymentMethods
            itemSelected = expressCheckout.paymentMethod
            hasError = expressCheckout.hasPaymentMethodError
            onClick = { paymentMethod ->
                props.onEvent(ProductDetailsViewEvent.PaymentMethodClick(paymentMethod))
            }
        }
        expressCheckout.codFee?.let { codFee ->
            Stack {
                sx {
                    marginTop = important(theme.spacing(1) / 2)
                    marginBottom = theme.spacing(1) / 2
                }
                direction = responsive(StackDirection.row)
                spacing = responsive(0.5)

                Typography {
                    sx {
                        color = theme.palette.text.secondary
                    }
                    variant = TypographyVariant.caption
                    +"product_details_cod_fee_label".i18n()
                }
                Typography {
                    sx {
                        fontWeight = FontWeight.bold
                        color = theme.palette.info.main
                    }
                    variant = TypographyVariant.caption
                    +codFee
                }
            }
        }
        useEffect(expressCheckout.hasPaymentMethodError) {
            if (expressCheckout.hasPaymentMethodError) {
                fieldRefs[PaymentMethod::class]?.current?.focus()
            }
        }
    }
}

internal external interface ExpressCheckoutProps : ViewEventProps<ProductDetailsViewEvent>, Props {
    var expressCheckout: ProductDetailsViewState.ExpressCheckout
}

private val GridItem: FC<GridProps> = FC { props ->
    Grid {
        item = true
        asDynamic().xs = 12
        asDynamic().sm = 6
        +props
        +props.children
    }
}
