package com.taager.dukan.i18n

import com.taager.dukan.preferences.domain.entity.Preferences
import kotlinx.browser.window
import kotlin.js.Json
import kotlin.js.Promise

private const val STRINGS_URL = "assets/i18n/{language}/strings.json"

internal fun fetchStrings(language: Preferences.Language): Promise<Json> {
    val url = language.toUrl()
    return fetchJson(url)
        .then { json ->
            JSON.parse(JSON.stringify(json))
        }
}

private fun Preferences.Language.toUrl(): String =
    STRINGS_URL.replace("{language}", id)

private fun fetchJson(url: String): Promise<*> =
    window.fetch(url).then { response ->
        if (!response.ok) {
            error("Strings not found from \"${response.url}\"")
        }
        response.json()
    }
