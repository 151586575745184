package com.taager.dukan.customer.domain.interactor

import com.taager.dukan.customer.domain.CustomerRepository
import com.taager.dukan.customer.domain.entity.Customer

class SaveCustomerUseCase(
    private val repository: CustomerRepository
) {

    suspend operator fun invoke(customer: Customer) {
        repository.saveCustomer(customer)
    }
}
