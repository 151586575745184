package com.taager.dukan.feature.productlist

import com.taager.circuit.ViewEventHandler
import com.taager.circuit.ViewStateHandler
import com.taager.dukan.feature.productlist.mapper.transform
import com.taager.dukan.feature.productlist.tracking.trackProductClick
import com.taager.dukan.feature.productlist.tracking.trackScreenView
import com.taager.dukan.feature.productlist.tracking.trackViewContent
import com.taager.dukan.product.domain.interactor.GetProductsUseCase
import com.taager.tracking.AppTracker
import kotlinx.coroutines.launch

class ProductListPresenter(
    private val appTracker: AppTracker,
    private val getProducts: GetProductsUseCase,
) : ViewStateHandler<ProductListViewState, ProductListSideEffect>(ProductListViewState.Initial),
    ViewEventHandler<ProductListViewEvent> {

    override fun onEvent(event: ProductListViewEvent) {
        when (event) {
            ProductListViewEvent.Init -> {
                appTracker.trackScreenView()
                loadProducts()
            }

            is ProductListViewEvent.ProductClick -> {
                appTracker.trackProductClick(sku = event.sku)
                tryEmitEffect(ProductListSideEffect.GoToProductDetails(event.sku))
            }
        }
    }

    private fun loadProducts() {
        scope.launch {
            try {
                val products = getProducts().transform()
                updateState(
                    newState = if (products.isEmpty()) {
                        ProductListViewState.Empty
                    } else {
                        ProductListViewState.Loaded(products)
                    },
                )
                appTracker.trackViewContent(products)
            } catch (error: Throwable) {
                appTracker.trackError(error)
                updateState(
                    newState = ProductListViewState.Error,
                )
            }
        }
    }
}
