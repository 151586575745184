package com.taager.dukan.feature.checkout.payment

import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.marginVertical
import com.taager.dukan.ui.theme.isMobile
import com.taager.dukan.ui.theme.useTheme
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Radio
import mui.material.RadioColor
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.ForwardRef
import react.ForwardRefExoticComponent
import react.Props
import react.PropsWithRef
import react.dom.html.ReactHTML.img
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.pct
import web.cssom.px
import web.html.HTMLElement

internal val CheckoutPaymentMethods: ForwardRefExoticComponent<CheckoutPaymentMethodsProps> =
    ForwardRef { props ->
        val theme = useTheme()
        Stack {
            sx {
                minWidth = Length.fitContent
                width = 100.pct
            }
            if (props.isBorderVisible) {
                spacing = responsive(2)
            }

            for (entry in props.availablePaymentMethods.withIndex()) {
                val (index, paymentMethod) = entry
                val isSelected = props.itemSelected == paymentMethod
                Button {
                    sx {
                        justifyContent = JustifyContent.start
                        padding = 0.px
                    }
                    if (index == 0) {
                        ref = props.ref
                    }
                    variant = if (props.isBorderVisible) {
                        ButtonVariant.outlined
                    } else {
                        ButtonVariant.text
                    }
                    color = if (props.hasError) {
                        ButtonColor.error
                    } else if (isSelected) {
                        ButtonColor.success
                    } else {
                        ButtonColor.primary
                    }

                    Radio {
                        checked = isSelected
                        color = RadioColor.success
                    }

                    when (paymentMethod) {
                        PaymentMethod.CashOnDelivery -> {
                            PaymentMethodLabel {
                                text = "checkout_payment_method_cod".i18n()
                            }
                        }

                        PaymentMethod.PayByCard -> {
                            if (theme.isMobile) {
                                MobilePayByCardPaymentMethodContent()
                            } else {
                                PaymentMethodLabel {
                                    text = "checkout_payment_method_pay_by_card".i18n()
                                }
                                SupportedPaymentMethods {
                                    sx {
                                        marginInlineStart = Auto.auto
                                    }
                                }
                            }
                        }
                    }

                    onClick = { props.onClick(paymentMethod) }
                }
            }
        }
    }

internal external interface CheckoutPaymentMethodsProps : PropsWithRef<HTMLElement> {
    var availablePaymentMethods: List<PaymentMethod>
    var isBorderVisible: Boolean
    var itemSelected: PaymentMethod
    var hasError: Boolean
    var onClick: (PaymentMethod) -> Unit
}

private val MobilePayByCardPaymentMethodContent: FC<Props> = FC {
    val theme = useTheme()
    Stack {
        sx {
            width = 100.pct
            marginVertical = theme.spacing(1)
            marginInlineEnd = theme.spacing(1)
        }
        direction = responsive(StackDirection.column)
        spacing = responsive(1)

        Stack {
            sx {
                alignItems = AlignItems.center
                justifyContent = JustifyContent.spaceBetween
            }
            direction = responsive(StackDirection.row)

            PaymentMethodLabel {
                text = "checkout_payment_method_pay_by_card".i18n()
            }

            img {
                src = "assets/images/payment_method_moyasar.svg"
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(1)

            img {
                src = "assets/images/payment_method_amex.svg"
            }
            img {
                src = "assets/images/payment_method_mada.svg"
            }
            img {
                src = "assets/images/payment_method_visa.svg"
            }
            img {
                src = "assets/images/payment_method_mastercard.svg"
            }
        }
    }
}

private external interface PaymentMethodLabelProps : Props {
    var text: String
}

private val PaymentMethodLabel: FC<PaymentMethodLabelProps> = FC { props ->
    val theme = useTheme()
    Typography {
        sx {
            fontWeight = FontWeight.bold
            color = theme.palette.text.primary
        }
        variant = TypographyVariant.body1
        +props.text
    }
}
