package com.taager.dukan.store.data.remote.mapper

import com.taager.country.CountryResolver
import com.taager.dukan.store.data.remote.response.GetInfoResponse
import com.taager.dukan.store.domain.entity.Store
import com.taager.numbers.toMoney

internal fun GetInfoResponse.transform(): Store {
    val country = CountryResolver.retrieveMultitenancyEnabledCountriesFromIsoCode3(countryIsoCode3)
    return Store(
        name = name,
        country = country,
        contactInfo = contactInfo?.transform(),
        isExpressCheckoutEnabled = hasEmbeddedCheckoutEnabled,
        integrations = integrations?.transform(),
        payments = Store.Payments(
            isPrepaidEnabled = isPrepaidEnabled,
            codFee = codFee?.toMoney(country.currency),
        ),
    )
}

private fun GetInfoResponse.ContactInfo.transform(): Store.ContactInfo =
    Store.ContactInfo(
        email = email,
        phoneNumber = phoneNumber
    )

private fun GetInfoResponse.Integrations.transform(): Store.Integrations =
    Store.Integrations(
        tiktokPixel = tiktokPixel?.transform(),
        gTag = gTag?.transform(),
    )

private fun GetInfoResponse.Integrations.TiktokPixel.transform(): Store.Integrations.Pixel.Tiktok =
    Store.Integrations.Pixel.Tiktok(
        ids = ids,
    )

private fun GetInfoResponse.Integrations.GTagPixel.transform(): Store.Integrations.Pixel.GTag =
    Store.Integrations.Pixel.GTag(
        ids = ids,
    )
