package com.taager.circuit.js

import com.taager.circuit.SideEffectHandler
import com.taager.circuit.ViewStateHandler
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import org.kodein.di.DI
import org.kodein.di.Instance
import org.kodein.type.generic
import react.StateInstance
import react.useEffect
import react.useState

/**
 * Compose a [ViewStateHandler] [Presenter] provided from the [DI], emitting each of its [ViewState].
 */
inline fun <reified Presenter : ViewStateHandler<ViewState, *>, ViewState> DI.composePresenter(
    tag: Any? = null,
    compose: (presenter: Presenter, viewState: ViewState) -> Unit
) {
    /** `useInstance()` requires a `reified` generic [Presenter] type and this function as `inline`. */
    val presenter: Presenter by useInstance(tag)

    presenter.scope = MainScope()

    val viewState = presenter.useState()

    compose(presenter, viewState)
}

/**
 * Compose a [SideEffectHandler] [Presenter] provided from the [DI]
 */
inline fun <reified Presenter : SideEffectHandler<*>> DI.composePresenter(
    tag: Any? = null,
    compose: (presenter: Presenter) -> Unit
) {
    /** `useInstance()` requires a `reified` generic [Presenter] type and this function as `inline`. */
    val presenter: Presenter by useInstance(tag)

    presenter.scope = MainScope()

    compose(presenter)
}

fun <Presenter : ViewStateHandler<State, *>, State> Presenter.useState(): State {
    val (getState, setState) = useState(initialValue = state.value)

    useEffect(this) {
        val job = state
            .onEach(setState::invoke)
            .launchIn(scope)
        cleanup { job.cancel() }
    }

    return getState
}

inline fun <reified T> DI.useInstance(tag: Any?): StateInstance<T> {
    /** `generic()` requires a `reified` generic [T] and this function as `inline`. */
    val instance: T by Instance(generic(), tag)
    return useState { instance }
}

fun <SideEffect> SideEffectHandler<SideEffect>.onSideEffect(
    onEffect: (effect: SideEffect) -> Unit
) {
    useEffect(this) {
        val job = effect
            .onEach(onEffect)
            .launchIn(scope)
        cleanup { job.cancel() }
    }
}
