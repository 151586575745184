package com.taager.dukan.feature.checkout.payment

import com.taager.checkout.payment.PaymentForm
import com.taager.checkout.payment.PaymentFormVisibility
import com.taager.dukan.env
import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.external.Moyasar
import com.taager.dukan.ui.component.marginHorizontal
import com.taager.dukan.ui.component.marginVertical
import com.taager.dukan.ui.hooks.onEscapeKey
import com.taager.dukan.ui.theme.isDesktop
import com.taager.dukan.ui.theme.useTheme
import io.ktor.util.encodeBase64
import js.core.jso
import kotlinx.browser.window
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.Drawer
import mui.material.DrawerAnchor
import mui.system.sx
import org.w3c.dom.Window
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useEffect
import web.cssom.ClassName

private const val MOYASAR_FORM_CLASSNAME = "moyasar-form"

internal val CheckoutPaymentForm: FC<CheckoutPaymentFormProps> = FC { props ->

    val theme = useTheme()
    val formVisibility = props.formVisibility
    val isFormVisible = formVisibility is PaymentFormVisibility.Visible

    if (theme.isDesktop) {
        Dialog {
            // Keep mounted here is just a requirement for the Moyasar.init to work
            // due to the element query selector.
            keepMounted = true
            open = isFormVisible
            DialogContent {
                Box {
                    sx {
                        margin = theme.spacing(2)
                    }
                    component = ReactHTML.div
                    className = ClassName(MOYASAR_FORM_CLASSNAME)
                }
            }
            DialogActions {
                Button {
                    +"checkout_payment_form_close_button".i18n()
                    onClick = { props.onClosePaymentForm() }
                }
            }
            onClose = { _, _ -> props.onClosePaymentForm() }
        }
    } else {
        Drawer {
            // Keep mounted here is just a requirement for the Moyasar.init to work
            // due to the element query selector.
            keepMounted = true
            anchor = DrawerAnchor.bottom
            open = isFormVisible
            Box {
                sx {
                    marginHorizontal = theme.spacing(2)
                    marginVertical = theme.spacing(3)
                }
                component = ReactHTML.div
                className = ClassName(MOYASAR_FORM_CLASSNAME)
            }
            onClose = { _, _ -> props.onClosePaymentForm() }
        }
    }

    useEffect(formVisibility) {
        if (formVisibility is PaymentFormVisibility.Visible) {
            val paymentForm = formVisibility.form
            val encodedQuantity = paymentForm.metadata.orderLines.first().quantity.toString().encodeBase64()
            Moyasar.init(
                jso {
                    publishable_api_key = env.MOYASAR_SITE_KEY.unsafeCast<String>()
                    element = ".$MOYASAR_FORM_CLASSNAME"
                    amount = paymentForm.amount
                    currency = paymentForm.currency
                    description = paymentForm.description
                    metadata = paymentForm.getOrderMetadata()
                    methods = paymentForm.methods.toTypedArray()
                    on_completed = { props.onPaymentFormCompleted() }
                    callback_url = "${window.currentLocation}/post_payment?qt=$encodedQuantity"
                    apple_pay = jso {
                        country = "SA"
                        label = paymentForm.storeName
                        validate_merchant_url = "https://api.moyasar.com/v1/applepay/initiate"
                    }
                }
            )
        }
    }

    onEscapeKey {
        if (isFormVisible) {
            props.onClosePaymentForm()
        }
    }
}

private val Window.currentLocation: String
    get() = "${location.protocol}//${location.host}${location.pathname}"

internal external interface CheckoutPaymentFormProps : Props {
    var formVisibility: PaymentFormVisibility
    var onClosePaymentForm: () -> Unit
    var onPaymentFormCompleted: () -> Unit
}

private fun PaymentForm.getOrderMetadata(): OrderMetadata = jso {
    orderLines = metadata.orderLines.transform()
    shippingCost = metadata.shippingCost
    customer = jso {
        fullName = metadata.customer.fullName
        provinceId = metadata.customer.provinceId
        zoneId = metadata.customer.zoneId
        districtId = metadata.customer.districtId
        address1 = metadata.customer.address1
        phoneNumber = metadata.customer.phoneNumber
        email = metadata.customer.email
        notes = metadata.customer.notes
    }
    orderSource = jso {
        pageUrl = window.location.href
    }
    orderReceivedBy = metadata.orderReceivedBy
}

private fun List<PaymentForm.Metadata.OrderLine>.transform(): Array<OrderLine> =
    map { orderLine ->
        jso<OrderLine> {
            sku = orderLine.sku
            quantity = orderLine.quantity
            totalPrice = orderLine.totalPrice
        }
    }.toTypedArray()

private external interface OrderMetadata {
    var orderLines: Array<OrderLine>
    var shippingCost: Double
    var customer: Customer
    var orderSource: OrderSource
    var orderReceivedBy: String
}

private external interface OrderLine {
    var sku: String
    var quantity: Int
    var totalPrice: Double
}

private external interface Customer {
    var fullName: String
    var provinceId: String
    var zoneId: String
    var districtId: String?
    var address1: String
    var phoneNumber: String
    var email: String?
    var notes: String?
}

private external interface OrderSource {
    var pageUrl: String
}
