package com.taager.dukan.store.data.remote.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class GetInfoResponse(
    @SerialName("countryIsoCode3")
    val countryIsoCode3: String,
    @SerialName("name")
    val name: String,
    @SerialName("contactInfo")
    val contactInfo: ContactInfo?,
    @SerialName("isPrepaidEnabled")
    val isPrepaidEnabled: Boolean = false,
    @SerialName("codFee")
    val codFee: Double? = null,
    @SerialName("hasEmbeddedCheckoutEnabled")
    val hasEmbeddedCheckoutEnabled: Boolean = false,
    @SerialName("integrations")
    val integrations: Integrations?,
) {

    @Serializable
    data class ContactInfo(
        @SerialName("email")
        val email: String?,
        @SerialName("phoneNumber")
        val phoneNumber: String?,
    )

    @Serializable
    data class Integrations(
        @SerialName("tiktokPixel")
        val tiktokPixel: TiktokPixel?,
        @SerialName("googleTagCode")
        val gTag: GTagPixel?,
    ) {

        @Serializable
        data class TiktokPixel(
            @SerialName("ids")
            val ids: List<String>,
        )

        @Serializable
        data class GTagPixel(
            @SerialName("ids")
            val ids: List<String>,
        )
    }
}
