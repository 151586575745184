package com.taager.dukan.preferences.data.local

import com.taager.dukan.preferences.data.local.model.PreferencesModel
import com.taager.experience.cache.LocalCacheStrategy
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache

internal class PreferencesCache(
    override val memoryCache: MemoryCache<Key, PreferencesModel>,
    override val diskCache: DiskCache<Key, PreferencesModel>
) : LocalCacheStrategy<PreferencesCache.Key, PreferencesModel> {

    object Key {
        override fun toString(): String = "preferences"
    }

    fun get(): PreferencesModel? =
        super.get(key = Key)

    fun set(preferences: PreferencesModel) {
        super.set(
            key = Key,
            content = preferences
        )
    }
}
