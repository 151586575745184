package com.taager.featuremanager

import com.taager.featuremanager.FeatureAttributes.Companion.IS_COLOR_MODE_SWITCHER_ENABLED
import com.taager.featuremanager.FeatureAttributes.Companion.IS_LANGUAGE_SWITCHER_ENABLED
import com.taager.firebase.remoteconfig.InjectableFirebaseRemoteConfig
import com.taager.firebase.remoteconfig.getValue

internal class FeatureAttributesImpl(
    private val remoteConfig: InjectableFirebaseRemoteConfig
) : FeatureAttributes {

    override val isLanguageSwitcherEnabled: Boolean
        get() = remoteConfig.getValue(IS_LANGUAGE_SWITCHER_ENABLED).asBoolean()

    override val isColorModeSwitcherEnabled: Boolean
        get() = remoteConfig.getValue(IS_COLOR_MODE_SWITCHER_ENABLED).asBoolean()
}
