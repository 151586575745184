package com.taager.dukan.checkout.domain.di

import com.taager.dukan.checkout.domain.interactor.CalculateCheckoutUseCase
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object CheckoutDomainDI {
    val module = DI.Module("Checkout Domain DI") {
        bindProvider {
            CalculateCheckoutUseCase(
                repository = instance()
            )
        }
    }
}
