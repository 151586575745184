package com.taager.tracking.tiktok

import com.taager.tracking.Tracker
import com.taager.tracking.event.TrackingEvent
import com.taager.tracking.mapper.transform

internal class TiktokTracker : Tracker {

    private val tiktok = js("ttq")
    private var isInitialized = false

    override fun initialize() {
        // no-op
    }

    override fun trackEvent(event: TrackingEvent) {
        event.transform()?.let { tikTokEvent ->
            tiktok.track(tikTokEvent.event, tikTokEvent.properties.transform())
        }
    }

    override fun trackError(throwable: Throwable) {
        // no-op
    }

    override fun identifyUser(userId: String) {
        // no-op
    }

    override fun setUserProperty(key: String, value: String) {
        // no-op
    }

    override fun clearUserProperty(key: String) {
        // no-op
    }

    override fun setEventProperty(key: String, value: String) {
        when (key) {
            "tiktok_pixel_ids" -> {
                if (!isInitialized) {
                    for (pixelId in value.split(",")) {
                        tiktok.load(pixelId)
                    }
                    isInitialized = true
                }
            }
        }
    }

    override fun clearEventProperty(key: String) {
        // no-op
    }

    override fun reset() {
        // no-op
    }
}
