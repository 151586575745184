package com.taager.tracking.gtm

import com.taager.tracking.event.TrackingEvent

internal fun TrackingEvent.transform(): GoogleTagManagerEvent? =
    when (name) {
        "product_list_view_content" -> {
            GoogleTagManagerEvent.ViewItemList(
                items = properties["products"].asItems(),
            )
        }

        "product_details_view_content" -> {
            GoogleTagManagerEvent.ViewItem(
                currency = properties["currency"] as String,
                value = properties["total"] as Double,
                items = listOf(properties.asItem()),
            )
        }

        "product_details_initiate_checkout" -> {
            GoogleTagManagerEvent.BeginCheckout(
                currency = properties["currency"] as String,
                value = properties["total"] as Double,
                items = properties["products"].asItems(),
            )
        }

        "checkout_initiate_checkout" -> {
            GoogleTagManagerEvent.BeginCheckout(
                currency = properties["currency"] as String,
                value = properties["total"] as Double,
                items = properties["products"].asItems(),
            )
        }

        "checkout_add_payment_info",
        "product_details_add_payment_info" -> {
            GoogleTagManagerEvent.AddPaymentInfo(
                currency = properties["currency"] as String,
                value = properties["total"] as Double,
                paymentType = properties["payment_method"] as String,
                items = properties["products"].asItems(),
            )
        }

        "post_payment_complete_payment" -> {
            GoogleTagManagerEvent.Purchase(
                currency = properties["currency"] as String,
                value = properties["total"] as Double,
                transactionId = properties["payment_id"] as String,
                items = properties["products"].asItems(),
            )
        }

        else -> null
    }

// We want to fail fast if the types are not correctly mapped
@Suppress("UNCHECKED_CAST")
private fun Any?.asItems(): List<GoogleTagManagerEvent.Item> =
    (this as List<Map<String, *>>).map(Map<String, *>::asItem)

internal fun Map<String, *>.asItem(): GoogleTagManagerEvent.Item =
    GoogleTagManagerEvent.Item(
        id = this["sku"] as String,
        name = this["product_name"] as String,
        price = this["product_price"] as Double,
        quantity = this["quantity"] as Int,
    )
