package com.taager.dukan.order.data.remote.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class PlacePaidOrderRequest(
    @SerialName("paymentId")
    val paymentId: String,
)
