package com.taager.dukan.feature.home

import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.rtl.isRTL
import js.core.jso
import mui.material.Menu
import mui.material.MenuItem
import mui.material.PopoverReference
import react.FC
import react.Props
import web.dom.Element

internal val HamburgerMenu: FC<HamburgerMenuProps> = FC { props ->
    val anchor = props.anchor
    Menu {
        open = anchor != null
        onClose = { props.onClose() }

        anchorReference = PopoverReference.anchorEl
        anchorOrigin = jso {
            vertical = "bottom"
            horizontal = if (isRTL) {
                "right"
            } else {
                "left"
            }
        }
        anchorEl = if (anchor != null) {
            { anchor }
        } else {
            undefined
        }

        val onQuickLinkClick = { id: String ->
            props.onQuickLinkClick(id)
        }
        MenuItem {
            +"home_quick_link_about_us".i18n()
            onClick = { onQuickLinkClick("about_us") }
        }
        MenuItem {
            +"home_quick_link_shipping_policy".i18n()
            onClick = { onQuickLinkClick("shipping_policy") }
        }
        MenuItem {
            +"home_quick_link_contact_us".i18n()
            onClick = { onQuickLinkClick("contact_us") }
        }
    }
}

internal external interface HamburgerMenuProps : Props {
    var anchor: Element?
    var onClose: () -> Unit
    var onQuickLinkClick: (id: String) -> Unit
}
