package com.taager.dukan.feature.checkout

import com.taager.checkout.field.InputField
import com.taager.dukan.checkout.domain.entity.PaymentMethod

sealed interface CheckoutViewEvent {
    data class Init(val sku: String, val quantity: Int?) : CheckoutViewEvent

    data class UpdateField(
        val inputField: InputField<*>,
        val newValue: Any?
    ) : CheckoutViewEvent

    data class QueryCities(val query: String) : CheckoutViewEvent

    data class PaymentMethodClick(
        val paymentMethod: PaymentMethod
    ) : CheckoutViewEvent

    object PlaceOrderClick : CheckoutViewEvent

    object BackClick : CheckoutViewEvent

    data class CaptchaResponse(val token: String?) : CheckoutViewEvent

    object CaptchaError : CheckoutViewEvent

    object ClosePaymentForm : CheckoutViewEvent

    object PaymentFormCompleted : CheckoutViewEvent
}
