package com.taager.dukan.checkout.data

import com.taager.dukan.checkout.data.remote.CheckoutApi
import com.taager.dukan.checkout.data.remote.mapper.transform
import com.taager.dukan.checkout.domain.CheckoutRepository
import com.taager.dukan.checkout.domain.entity.Checkout
import com.taager.dukan.checkout.domain.entity.PaymentMethod

internal class CheckoutRepositoryImpl(
    private val api: CheckoutApi,
) : CheckoutRepository {

    override suspend fun calculateCheckout(
        provinceId: String?,
        products: List<Checkout.Product>,
        paymentMethod: PaymentMethod,
    ): Checkout =
        api.calculateCost(
            request = transform(
                provinceId,
                products,
                paymentMethod,
            )
        ).transform(currency = products.first().price.currency)
}
