package com.taager.dukan.order.domain.entity

data class PlaceableOrder(
    val fullName: String,
    val address: String,
    val phoneNumber: String,
    val alternativePhoneNumber: String?,
    val email: String?,
    val products: List<Products>,
    val notes: String?,
    val provinceId: String,
    val cityId: String,
    val districtId: String?,
    val captchaToken: String,
) {
    data class Products(
        val sku: String,
        val quantity: Int,
    )
}
