package com.taager.dukan.customer.domain.interactor

import com.taager.dukan.customer.domain.CustomerRepository
import com.taager.dukan.customer.domain.entity.Customer

class GetCustomerUseCase(
    private val repository: CustomerRepository
) {

    suspend operator fun invoke(): Customer? =
        repository.getCustomer()
}
