package com.taager.dukan.store.domain.di

import com.taager.dukan.store.domain.interactor.GetQuickLinkUseCase
import com.taager.dukan.store.domain.interactor.GetStoreUseCase
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object StoreDomainDI {
    val module = DI.Module("Store Domain DI") {
        bindProvider {
            GetStoreUseCase(
                repository = instance(),
                appTracker = instance(),
            )
        }
        bindProvider {
            GetQuickLinkUseCase(
                repository = instance(),
            )
        }
    }
}
