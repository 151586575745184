package com.taager.dukan.checkout.data.remote.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
internal data class CalculateCostResponse(
    @SerialName("itemsSubTotal")
    val itemsSubTotal: Double,
    @SerialName("shippingCost")
    val shippingCost: Double?,
    @SerialName("codFee")
    val codFee: Double?,
    @SerialName("totalCost")
    val totalCost: Double,
)
