package com.taager.dukan.feature.productlist

import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.ErrorAlertPanel
import com.taager.dukan.ui.component.GridContainer
import com.taager.dukan.ui.component.GridItem
import com.taager.dukan.ui.component.ProductCard
import com.taager.dukan.ui.component.ProductCardSkeleton
import com.taager.dukan.ui.icon.ProductListIcon
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useTheme
import mui.material.Divider
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import react.useEffectOnce
import web.cssom.FontWeight

internal val ProductListScreen: FC<Props> = FC {
    mainDI().composePresenter<ProductListPresenter, ProductListViewState> { presenter, viewState ->

        useEffectOnce {
            presenter.onEvent(ProductListViewEvent.Init)
        }

        Content {
            state = viewState
            onEvent = presenter::onEvent
        }

        val navigate = useNavigate()
        presenter.onSideEffect { effect ->
            when (effect) {
                is ProductListSideEffect.GoToProductDetails -> {
                    navigate(to = effect.sku)
                }
            }
        }
    }
}

private external interface ContentProps :
    ViewStateProps<ProductListViewState>,
    ViewEventProps<ProductListViewEvent>

private val Content: FC<ContentProps> = FC { props ->
    val theme = useTheme()
    Stack {
        sx {
            paddingTop = theme.spacing(1)
            paddingBottom = theme.spacing(3)
        }
        spacing = responsive(2)

        ProductListBanner()

        Stack {
            sx {
                paddingTop = theme.spacing(2)
            }
            direction = responsive(StackDirection.row)
            spacing = responsive(1)

            ProductListIcon()
            Typography {
                sx {
                    fontWeight = FontWeight.bold
                }
                variant = TypographyVariant.h3
                +"product_list_header".i18n()
            }
        }

        Divider()

        when (val state = props.state) {

            is ProductListViewState.Loading -> {
                Loading()
            }

            is ProductListViewState.Loaded -> {
                Loaded {
                    this.state = state
                    onEvent = props.onEvent
                }
            }

            is ProductListViewState.Empty -> {
                Empty()
            }

            is ProductListViewState.Error -> {
                ErrorAlertPanel()
            }
        }
    }
}

private val Loading: FC<Props> = FC {
    GridContainer {
        spacing = responsive(2)

        @Suppress("UnusedPrivateMember")
        for (i in 1..6) {
            GridItem {
                responsiveColumns = true
                ProductCardSkeleton()
            }
        }
    }
}

private external interface LoadedProps : ViewEventProps<ProductListViewEvent> {
    var state: ProductListViewState.Loaded
}

private val Loaded: FC<LoadedProps> = FC { props ->

    val state = props.state

    GridContainer {
        spacing = responsive(2)

        for (product in state.products) {
            GridItem {
                responsiveColumns = true
                ProductCard {
                    key = product.sku
                    name = product.name
                    pricing = product.pricing
                    media = product.media
                    isSoldOut = product.isSoldOut
                    onClick = { props.onEvent(ProductListViewEvent.ProductClick(product.sku)) }
                }
            }
        }
    }
}

private val Empty: FC<Props> = FC {
    val theme = useTheme()
    Typography {
        sx {
            paddingBottom = theme.spacing(4)
            fontWeight = FontWeight.medium
            color = theme.palette.text.secondary
        }
        variant = TypographyVariant.body1
        align = TypographyAlign.center
        +"product_list_empty".i18n()
    }
}
