package com.taager.dukan.feature.home.tracking

import com.taager.dukan.preferences.domain.entity.Preferences
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

private object HomeScreen : Screen(name = "home")

internal fun AppTracker.trackScreenView() {
    trackEvent(ScreenViewTrackingEvent(screen = HomeScreen))
}

internal fun AppTracker.trackQuickLinkClick(id: String) {
    trackEvent(
        BaseTrackingEvent(
            screen = HomeScreen,
            event = "${id}_click",
        ),
    )
}

internal fun AppTracker.trackStoreNameClick() {
    trackEvent(
        BaseTrackingEvent(
            screen = HomeScreen,
            event = "store_name_click",
        ),
    )
}

internal fun AppTracker.trackLanguageClick(language: Preferences.Language) {
    trackEvent(
        BaseTrackingEvent(
            screen = HomeScreen,
            event = "language_click",
            extras = mapOf("language" to language.name.lowercase()),
        ),
    )
}

internal fun AppTracker.trackColorModeClick(colorMode: Preferences.ColorMode) {
    trackEvent(
        BaseTrackingEvent(
            screen = HomeScreen,
            event = "color_mode_click",
            extras = mapOf("color_mode" to colorMode.name.lowercase()),
        ),
    )
}
