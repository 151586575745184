package com.taager.dukan.feature.productlist.tracking

import com.taager.dukan.feature.productlist.ProductListViewState
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

private object ProductListScreen : Screen(name = "product_list")

internal fun AppTracker.trackScreenView() {
    trackEvent(ScreenViewTrackingEvent(screen = ProductListScreen))
}

internal fun AppTracker.trackViewContent(products: List<ProductListViewState.Product>) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductListScreen,
            event = "view_content",
            extras = mapOf(
                "products" to products.map { product ->
                    val price = product.pricing.internalPrice
                    val quantity = product.pricing.internalQuantity
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to price.value,
                        "currency" to price.currency.iso4217Code,
                        "quantity" to quantity,
                    )
                }
            )
        )
    )
}

internal fun AppTracker.trackProductClick(sku: String) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductListScreen,
            event = "product_click",
            extras = mapOf("sku" to sku)
        )
    )
}
