package com.taager.dukan.store.data.local

import com.taager.dukan.store.data.local.model.StoreModel
import com.taager.experience.cache.LocalCacheStrategy
import com.taager.experience.cache.disk.DiskCache
import com.taager.experience.cache.memory.MemoryCache

internal class StoreCache(
    override val memoryCache: MemoryCache<Key, StoreModel>,
    override val diskCache: DiskCache<Key, StoreModel>
) : LocalCacheStrategy<StoreCache.Key, StoreModel> {

    object Key {
        override fun toString(): String = "store"
    }

    fun get(): StoreModel? =
        super.get(key = Key)

    fun set(store: StoreModel) {
        super.set(
            key = Key,
            content = store
        )
    }
}
