package com.taager.tracking.mixpanel

import com.taager.tracking.Tracker
import com.taager.tracking.event.BaseErrorTrackingEvent
import com.taager.tracking.event.TrackingEvent

private external fun require(module: String): dynamic
private val env = js("ENV")

internal class MixpanelTracker : Tracker {

    private val mixpanel: dynamic = require("mixpanel-browser")

    override fun initialize() {
        val config = js("{}")
        config["api_host"] = env.MIXPANEL_API_HOST
        mixpanel.init(env.MIXPANEL_PROJECT_TOKEN, config)
    }

    override fun trackEvent(event: TrackingEvent) {
        val properties = js("{}")
        for (property in event.properties) {
            properties[property.key] = property.value.toString()
        }
        mixpanel.track(event.name, properties)
    }

    override fun trackError(throwable: Throwable) {
        trackEvent(BaseErrorTrackingEvent(message = throwable.message ?: throwable.toString()))
    }

    override fun identifyUser(userId: String) {
        mixpanel.identify(userId)
        mixpanel.people.set("\$user_id", userId)
    }

    override fun setUserProperty(key: String, value: String) {
        mixpanel.people.set(key, value)
    }

    override fun clearUserProperty(key: String) {
        mixpanel.people.unset(key)
    }

    override fun setEventProperty(key: String, value: String) {
        val properties = js("{}")
        properties[key] = value
        mixpanel.register(properties)
    }

    override fun clearEventProperty(key: String) {
        mixpanel.unregister(key)
    }

    override fun reset() {
        mixpanel.reset()
    }
}
