package com.taager.dukan.feature.quicklink

import com.taager.dukan.store.domain.entity.QuickLink

sealed class QuickLinkViewState {
    object Loading : QuickLinkViewState()

    data class Loaded(val quickLink: QuickLink) : QuickLinkViewState()

    object Error : QuickLinkViewState()

    companion object {
        val Initial: QuickLinkViewState = Loading
    }
}
