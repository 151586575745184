package com.taager.dukan.ui.component

import com.taager.checkout.field.InputField
import com.taager.checkout.validation.hasError
import com.taager.dukan.ui.theme.useTheme
import js.core.Object
import js.core.jso
import mui.base.AutocompleteInputChangeReason
import mui.material.Autocomplete
import mui.material.CircularProgress
import mui.material.CircularProgressColor
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputProps
import mui.material.TextField
import react.ForwardRef
import react.ForwardRefExoticComponent
import react.Fragment
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div

private inline var mui.material.AutocompleteProps<*>.items: List<*>
    get() = asDynamic().options
    set(value) {
        asDynamic().options = value.toTypedArray()
    }

internal external interface AutocompleteProps : BaseInputProps {
    var placeholderText: String?
    var loadingText: String?
    var startAdornment: ReactNode?
    var onQuery: (inputField: InputField<*>, query: String) -> Unit
    var onSelect: (inputField: InputField<*>, item: Any?) -> Unit
}

internal val Autocomplete: ForwardRefExoticComponent<AutocompleteProps> = ForwardRef { props ->
    val field = props.inputField
    Autocomplete {
        sx = props.sx
        id = field.id
        value = field.value

        autoHighlight = true // Select the first option by default
        asDynamic().filterOptions = { options: Any, _: Any -> options } // Disable filtering by the UI component
        isOptionEqualToValue = { option, value ->
            option.toString() == value.toString()
        }
        loading = field.isLoading
        loadingText = props.loadingText?.let(::ReactNode) ?: undefined
        items = requireNotNull(field.items)
        noOptionsText = props.emptyText?.let(::ReactNode) ?: undefined
        getOptionLabel = { option -> option.toString() }
        renderInput = { params ->
            TextField.create {
                +params
                inputRef = props.inputRef
                label = ReactNode(props.label)
                required = field.isRequired
                error = field.hasError
                placeholder = props.placeholderText
                asDynamic().InputProps = jso<InputProps> {
                    Object.assign(this, params.InputProps)
                    autoComplete = "new-password" // Disable autocomplete and autofill
                    if (props.startAdornment != null) {
                        startAdornment = InputAdornment.create {
                            position = InputAdornmentPosition.start
                            +props.startAdornment
                        }
                    }
                    endAdornment = Fragment.create {
                        if (field.isLoading == true) {
                            CircularProgress {
                                color = CircularProgressColor.inherit
                                size = useTheme().spacing(2)
                            }
                        }
                        +(params.InputProps as InputProps).endAdornment
                    }
                }
            }
        }
        onChange = { _, newValue, _, _ ->
            props.onSelect(field, newValue)
        }
        onInputChange = { _, newInputValue, reason ->
            // The reset reason triggers onInputChange even at the time of selecting an item.
            // When an item is just selected, we don't want to trigger a query again.
            if (reason != AutocompleteInputChangeReason.reset) {
                props.onQuery(field, newInputValue)
            }
        }
    }
}
