package com.taager.checkout.field

import com.taager.country.model.Country
import com.taager.dukan.customer.domain.entity.Customer

data class DefaultCheckoutForm(
    override val fullName: InputField.FullName,
    override val phoneNumber: InputField.PhoneNumber,
    override val alternativePhoneNumber: InputField.AlternativePhoneNumber,
    override val email: InputField.Email,
    override val city: InputField.City,
    override val hasDistrict: Boolean,
    override val district: InputField.District?,
    override val address: InputField.Address,
    override val notes: InputField.Notes,
) : CheckoutForm {

    companion object {
        fun initial(
            country: Country,
            cities: List<CheckoutForm.City>,
            districts: List<CheckoutForm.District>,
            customer: Customer?,
        ): DefaultCheckoutForm {
            val hasDistrict = hasDistricts(country)
            return DefaultCheckoutForm(
                fullName = InputField.FullName(
                    value = customer?.fullName ?: "",
                ),
                phoneNumber = InputField.PhoneNumber(
                    value = customer?.phoneNumber ?: "",
                    prefix = country.phoneNumPrefix.toString(),
                    placeholder = country.phoneNumberHint,
                    regex = country.phoneRegex.toRegex(),
                ),
                alternativePhoneNumber = InputField.AlternativePhoneNumber(
                    value = customer?.alternativePhoneNumber ?: "",
                    prefix = country.phoneNumPrefix.toString(),
                    placeholder = country.phoneNumberHint,
                    regex = country.phoneRegex.toRegex(),
                ),
                email = InputField.Email(
                    value = customer?.email ?: "",
                ),
                city = InputField.City(
                    value = customer?.toFormCity(),
                    items = cities
                ),
                hasDistrict = hasDistrict,
                district = InputField.District(
                    value = customer?.toFormDistrict(),
                    items = districts
                ),
                address = InputField.Address(
                    value = customer?.address ?: "",
                ),
                notes = InputField.Notes(value = ""),
            )
        }
    }
}
