package com.taager.dukan.feature.productdetails

import com.taager.circuit.js.ViewEventProps
import com.taager.circuit.js.ViewStateProps
import com.taager.circuit.js.composePresenter
import com.taager.circuit.js.onSideEffect
import com.taager.dukan.di.mainDI
import com.taager.dukan.feature.checkout.payment.CheckoutPaymentForm
import com.taager.dukan.i18n.i18n
import com.taager.dukan.ui.component.BackButton
import com.taager.dukan.ui.component.ErrorAlertPanel
import com.taager.dukan.ui.component.ErrorAlertSnackbar
import com.taager.dukan.ui.component.InvisibleCaptcha
import com.taager.dukan.ui.component.LoadingModal
import com.taager.dukan.ui.component.StickyBottomBar
import com.taager.dukan.ui.component.flipOnRTL
import com.taager.dukan.ui.css.CssClass
import com.taager.dukan.ui.hooks.onEscapeKey
import com.taager.dukan.ui.icon.ProductDetailsBuyIcon
import com.taager.dukan.ui.icon.ProductDetailsFreeShippingIcon
import com.taager.dukan.ui.theme.extraBold
import com.taager.dukan.ui.theme.isDesktop
import com.taager.dukan.ui.theme.isMobile
import com.taager.dukan.ui.theme.medium
import com.taager.dukan.ui.theme.useTheme
import js.core.jso
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant.Companion.contained
import mui.material.Card
import mui.material.CardActionArea
import mui.material.CardContent
import mui.material.Divider
import mui.material.FormControl
import mui.material.Grid
import mui.material.Modal
import mui.material.Stack
import mui.material.StackDirection.Companion.column
import mui.material.StackDirection.Companion.row
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.Breakpoint.Companion.sm
import mui.system.Breakpoint.Companion.xs
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.router.useNavigate
import react.router.useParams
import react.useEffect
import react.useRef
import react.useState
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.TextAlign
import web.cssom.WhiteSpace
import web.cssom.pct
import web.dom.Element

internal val ProductDetailsScreenWithExpressCheckoutContext: FC<Props> = FC {
    ExpressCheckoutContextProvider {
        ProductDetailsScreen()
    }
}

internal val ProductDetailsScreen: FC<Props> = FC {
    mainDI().composePresenter<ProductDetailsPresenter, ProductDetailsViewState> { presenter, viewState ->

        val params = useParams()
        val sku = params["sku"].toString()

        useEffect(sku) {
            presenter.onEvent(ProductDetailsViewEvent.Init(sku))
        }

        Content {
            state = viewState
            onEvent = presenter::onEvent
        }

        val internetConnectionErrorMessage = "internet_connection_error_message".i18n()
        val placeOrderErrorMessage = "checkout_place_order_error".i18n()
        var errorMessage: String? by useState(null)
        val navigate = useNavigate()
        val checkoutContext by useExpressCheckoutContext()

        presenter.onSideEffect { effect ->
            when (effect) {
                is ProductDetailsSideEffect.GoToCheckout -> {
                    navigate(to = "checkout?quantity=${effect.quantity}")
                }

                ProductDetailsSideEffect.GoToHome -> {
                    navigate(
                        to = "/",
                        options = jso {
                            replace = true
                        }
                    )
                }

                ProductDetailsSideEffect.ShowInternetConnectionError -> {
                    errorMessage = internetConnectionErrorMessage
                }

                ProductDetailsSideEffect.ShowPlaceOrderError -> {
                    checkoutContext.recaptchaRef.current?.reset()
                    errorMessage = placeOrderErrorMessage
                }

                ProductDetailsSideEffect.ExecuteCaptcha -> {
                    checkoutContext.recaptchaRef.current
                        ?.executeAsync()
                        ?.then { presenter.onEvent(ProductDetailsViewEvent.ProceedWithCheckout) }
                        ?.catch { /* Already handled by the presenter */ }
                }

                is ProductDetailsSideEffect.FocusCheckoutField -> {
                    val fieldKey = effect.inputField::class
                    checkoutContext.fieldRefs[fieldKey]?.current?.focus()
                }

                ProductDetailsSideEffect.GoToCheckoutSuccess -> {
                    navigate(to = "success")
                }
            }
        }

        ErrorAlertSnackbar {
            open = errorMessage != null
            message = errorMessage
            onClose = { errorMessage = null }
        }
    }
}

private external interface ContentProps :
    ViewStateProps<ProductDetailsViewState>,
    ViewEventProps<ProductDetailsViewEvent>

private val Content: FC<ContentProps> = FC { props ->

    BackButton {
        text = "home_back_button".i18n()
        onClick = { props.onEvent(ProductDetailsViewEvent.BackClick) }
    }

    when (val state = props.state) {

        is ProductDetailsViewState.Loading -> {
            ProductDetailsLoading()
        }

        is ProductDetailsViewState.Loaded -> {
            Loaded {
                this.state = state
                onEvent = props.onEvent
            }

            val isPlacingOrder = state.expressCheckout?.isPlacingOrder == true
            LoadingModal {
                open = isPlacingOrder
            }
        }

        is ProductDetailsViewState.Error -> {
            ErrorAlertPanel()
        }
    }
}

private external interface LoadedProps : ViewEventProps<ProductDetailsViewEvent> {
    var state: ProductDetailsViewState.Loaded
}

private const val DESKTOP_IMAGES_PANEL_HEIGHT_SPACING = 50

private val Loaded: FC<LoadedProps> = FC { props ->

    val theme = useTheme()
    val state = props.state
    val expressCheckout = state.expressCheckout

    Stack {
        sx {
            paddingTop = theme.spacing(1)
            paddingBottom = theme.spacing(3)
        }
        spacing = responsive(2)
        direction = responsive(xs to column, sm to row)

        ProductDetailsMedias {
            sx {
                if (theme.isDesktop) {
                    height = theme.spacing(DESKTOP_IMAGES_PANEL_HEIGHT_SPACING)
                }
            }
            medias = state.product.medias
            selectedImageIndex = state.selectedImageIndex
            isFullscreenButtonVisible = state.isFullscreenButtonVisible
            onEvent = props.onEvent
        }

        Stack {
            sx {
                width = 100.pct
            }
            spacing = responsive(2)
            AttributesPanel {
                product = state.product
                onEvent = props.onEvent
            }
            Divider()
            PricingOptions {
                pricingOptions = state.product.pricingOptions
                onEvent = props.onEvent
            }
            if (theme.isMobile) {
                Divider()
            }
            Stack {
                spacing = responsive(2)
                direction = responsive(xs to column, sm to column)
                if (theme.isDesktop) {
                    Divider()
                }
                Stack {
                    spacing = responsive(1)

                    Typography {
                        sx {
                            fontWeight = FontWeight.medium
                            color = theme.palette.text.secondary
                        }
                        variant = TypographyVariant.body2

                        +"product_details_description_label".i18n()
                    }
                    Typography {
                        sx {
                            fontWeight = FontWeight.medium
                            whiteSpace = WhiteSpace.preLine
                        }
                        className = CssClass.PreformattedText
                        variant = TypographyVariant.body1

                        dangerouslySetInnerHTML = jso {
                            __html = state.product.description
                        }
                    }
                }
                Divider()
                if (expressCheckout == null) {
                    Button {
                        sx {
                            width = 100.pct
                        }
                        variant = contained
                        startIcon = ProductDetailsBuyIcon.create()
                        disabled = !state.isOrderNowButtonEnabled
                        +"product_details_order_now_button".i18n()
                        onClick = { props.onEvent(ProductDetailsViewEvent.OrderNowClick) }
                    }
                } else {
                    ExpressCheckoutForm {
                        this.state = state
                        onEvent = props.onEvent
                    }
                }
            }
        }
    }

    val fullscreenImage = state.fullscreenImage
    var zooming by useState(initialValue = false)

    onEscapeKey {
        if (fullscreenImage != null) {
            props.onEvent(ProductDetailsViewEvent.ExitFullscreenKeyDown)
        }
    }

    Modal {
        open = fullscreenImage != null
        if (fullscreenImage != null) {
            ZoomableImage {
                image = fullscreenImage
                this.zooming = zooming
                onZoom = { isZooming ->
                    zooming = isZooming
                }
                onExitClick = {
                    props.onEvent(ProductDetailsViewEvent.ExitFullscreenClick)
                }
            }
        } else {
            // We just need any transparent component to avoid error on rendering
            // when there is no fullscreen image
            Box()
        }
    }

    if (expressCheckout != null) {
        CheckoutPaymentForm {
            formVisibility = expressCheckout.paymentFormVisibility
            onClosePaymentForm = { props.onEvent(ProductDetailsViewEvent.ClosePaymentForm) }
            onPaymentFormCompleted = { props.onEvent(ProductDetailsViewEvent.PaymentFormCompleted) }
        }
    }
}

private external interface AttributesPanelProps : ViewEventProps<ProductDetailsViewEvent>, PropsWithSx {
    var product: ProductDetailsViewState.Product
}

private val AttributesPanel: FC<AttributesPanelProps> = FC { props ->
    val product = props.product
    val theme = useTheme()

    Stack {
        spacing = responsive(2)
        Typography {
            sx {
                fontWeight = FontWeight.extraBold
            }
            variant = TypographyVariant.h3

            +product.name
        }
        if (theme.isMobile) {
            ProductDetailsAvailability {
                availability = product.availability
            }
        }
        Stack {
            sx {
                justifyContent = JustifyContent.spaceBetween
            }
            direction = responsive(xs to row, sm to column)
            spacing = responsive(1)

            if (theme.isDesktop) {
                spacing = responsive(2)

                ProductDetailsAvailability {
                    availability = product.availability
                }
            }
        }
    }
}

private external interface PricingOptionsProps : ViewEventProps<ProductDetailsViewEvent>, PropsWithSx {
    var pricingOptions: List<ProductDetailsViewState.Product.PricingOption>
}

private val PricingOptions = FC<PricingOptionsProps> { props ->
    val theme = useTheme()

    Typography {
        sx {
            fontWeight = FontWeight.medium
            color = theme.palette.text.secondary
        }
        variant = TypographyVariant.body2
        +"product_details_pricing_option_header".i18n()
    }

    Grid {
        sx {
            width = 100.pct
        }
        container = true
        spacing = responsive(1)

        props.pricingOptions.forEach { pricingOption ->
            Grid {
                item = true
                asDynamic().xs = 6
                asDynamic().lg = 4

                Card {
                    sx {
                        minHeight = theme.spacing(12)
                        width = 100.pct
                        backgroundColor = if (pricingOption.isSelected) {
                            theme.palette.secondary.main
                        } else {
                            theme.palette.primary.light
                        }
                        borderRadius = theme.spacing(1)
                        textAlign = TextAlign.center
                        display = Display.flex
                    }
                    CardActionArea {
                        CardContent {
                            sx {
                                color = if (pricingOption.isSelected) {
                                    theme.palette.common.white
                                } else {
                                    theme.palette.text.secondary
                                }
                                padding = theme.spacing(1)
                            }
                            Typography {
                                className = CssClass.PreformattedTextSingleLine
                                variant = TypographyVariant.caption

                                dangerouslySetInnerHTML = jso {
                                    __html = pricingOption.name
                                }
                            }
                            Typography {
                                sx {
                                    fontWeight = FontWeight.bold
                                    if (!pricingOption.isSelected) {
                                        color = theme.palette.text.primary
                                    }
                                }
                                variant = TypographyVariant.h3

                                +pricingOption.price
                            }
                            Typography {
                                className = CssClass.PreformattedText
                                variant = TypographyVariant.caption

                                dangerouslySetInnerHTML = jso {
                                    __html = pricingOption.description ?: ""
                                }
                            }
                        }
                    }
                    onClick = {
                        props.onEvent(ProductDetailsViewEvent.PricingOptionClick(pricingOption))
                    }
                }
            }
        }
    }
    FreeShippingTag()
}

private val FreeShippingTag: FC<Props> = FC {
    val theme = useTheme()
    Stack {
        sx {
            color = theme.palette.info.main
            alignItems = AlignItems.center
        }
        direction = responsive(row)
        spacing = responsive(1)

        ProductDetailsFreeShippingIcon {
            sx {
                flipOnRTL()
            }
        }

        Typography {
            sx {
                fontWeight = FontWeight.medium
            }
            variant = TypographyVariant.body2
            noWrap = true

            +"product_details_free_shipping_tag".i18n()
        }
    }
}

private val ExpressCheckoutForm: FC<LoadedProps> = FC { props ->
    val theme = useTheme()
    val stickyAnchorRef = useRef<Element>(null)
    val checkoutContext by useExpressCheckoutContext()
    FormControl {
        Stack {
            spacing = responsive(2)
            ExpressCheckout {
                expressCheckout = requireNotNull(props.state.expressCheckout)
                onEvent = props.onEvent
            }
            if (theme.isDesktop) {
                ProductDetailsOrderNowButton {
                    isEnabled = props.state.isOrderNowButtonEnabled
                    onClick = { props.onEvent(ProductDetailsViewEvent.OrderNowClick) }
                }
            } else {
                StickyBottomBar {
                    anchorRef = stickyAnchorRef
                    ProductDetailsOrderNowButton {
                        isEnabled = props.state.isOrderNowButtonEnabled
                        onClick = { props.onEvent(ProductDetailsViewEvent.OrderNowClick) }
                    }
                }
            }
            Box {
                sx {
                    // Center the captcha horizontally
                    textAlign = TextAlign.center
                }
                ref = stickyAnchorRef
                InvisibleCaptcha {
                    recaptchaRef = checkoutContext.recaptchaRef
                    onChange = { token ->
                        props.onEvent(ProductDetailsViewEvent.CaptchaResponse(token))
                    }
                    onError = {
                        // Very likely to be network connectivity issues
                        props.onEvent(ProductDetailsViewEvent.CaptchaError)
                    }
                }
            }
        }
    }
}
