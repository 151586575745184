package com.taager.dukan.feature.productdetails.tracking

import com.taager.dukan.checkout.domain.entity.PaymentMethod
import com.taager.dukan.product.domain.entity.Product
import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent

internal fun AppTracker.trackPlaceOrderClick(sku: String, quantity: Int) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "place_order_click",
            extras = mapOf("sku" to sku, "quantity" to quantity)
        )
    )
}

internal fun AppTracker.trackCaptchaResponse(
    sku: String,
    captchaToken: String?,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "captcha_response",
            extras = mapOf(
                "sku" to sku,
                "captcha_token" to (captchaToken ?: "null")
            )
        )
    )
}

internal fun AppTracker.trackCaptchaError(
    sku: String,
) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "captcha_error",
            extras = mapOf(
                "sku" to sku,
            )
        )
    )
}

internal fun AppTracker.trackCheckoutSuccess(
    product: Product,
    quantity: Int
) {
    val selectedPricingOption = product.pricingOptionForQuantity(quantity)
    val price = selectedPricingOption.price
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "checkout_success",
            extras = mapOf(
                "products" to listOf(
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to price.value,
                        "quantity" to selectedPricingOption.quantity,
                    )
                ),
                "currency" to price.currency.iso4217Code,
                "total" to price.value
            ),
        )
    )
}

internal fun AppTracker.trackInitiateCheckout(
    product: Product,
    quantity: Int,
) {
    val selectedPricingOption = product.pricingOptionForQuantity(quantity)
    val price = selectedPricingOption.price
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "initiate_checkout",
            extras = mapOf(
                "products" to listOf(
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to price.value,
                        "quantity" to selectedPricingOption.quantity,
                    )
                ),
                "currency" to selectedPricingOption.price.currency.iso4217Code,
                "total" to selectedPricingOption.price.value,
            )
        )
    )
}

internal fun AppTracker.trackPricingOptionClick(
    sku: String,
    quantity: Int
) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "pricing_option_click",
            extras = mapOf(
                "sku" to sku,
                "quantity" to quantity
            )
        )
    )
}

internal fun AppTracker.trackPaymentMethodClick(
    sku: String,
    quantity: Int,
    paymentMethod: PaymentMethod
) {
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "payment_method_click",
            extras = mapOf(
                "sku" to sku,
                "quantity" to quantity,
                "payment_method" to paymentMethod.name
            )
        )
    )
}

internal fun AppTracker.trackAddPaymentInfo(
    product: Product,
    quantity: Int,
    paymentMethod: PaymentMethod
) {
    val selectedPricingOption = product.pricingOptionForQuantity(quantity)
    val price = selectedPricingOption.price
    trackEvent(
        BaseTrackingEvent(
            screen = ProductDetailsScreen,
            event = "add_payment_info",
            extras = mapOf(
                "products" to listOf(
                    mapOf(
                        "sku" to product.sku,
                        "product_name" to product.name,
                        "product_price" to price.value,
                        "quantity" to selectedPricingOption.quantity,
                    )
                ),
                "currency" to selectedPricingOption.price.currency.iso4217Code,
                "total" to selectedPricingOption.price.value,
                "payment_method" to paymentMethod.toString()
            )
        )
    )
}
