package com.taager.checkout.field

sealed class InputField<T>(
    val id: String,
    val type: Type,
    open val value: T,
    open val prefix: String? = null,
    open val items: List<T>? = null,
    open val isLoading: Boolean? = null,
    open val placeholder: String? = null,
    internal open val regex: Regex? = null,
    val isRequired: Boolean = true,
    val multiline: Boolean = false,
) {

    open fun filter(value: Any?): Any? = value

    enum class Type { Phone, Text, Email }

    data class FullName(
        override val value: String,
    ) : InputField<String>(
        id = "fullName",
        type = Type.Text,
        value = value,
    )

    data class PhoneNumber(
        override val value: String,
        override val prefix: String,
        override val placeholder: String,
        override val regex: Regex,
    ) : InputField<String>(
        id = "phoneNumber",
        type = Type.Phone,
        value = value,
        prefix = prefix,
    ) {
        override fun filter(value: Any?): String =
            (value as String).replace(Regex, "")

        companion object {
            internal val Regex = Regex("\\D")
        }
    }

    data class AlternativePhoneNumber(
        override val value: String,
        override val prefix: String,
        override val placeholder: String,
        override val regex: Regex,
    ) : InputField<String>(
        id = "alternativePhoneNumber",
        type = Type.Phone,
        value = value,
        prefix = prefix,
        isRequired = false,
    ) {
        override fun filter(value: Any?): String =
            (value as String).replace(PhoneNumber.Regex, "")
    }

    data class Email(
        override val value: String,
        override val regex: Regex = Regex,
    ) : InputField<String>(
        id = "email",
        type = Type.Email,
        value = value,
        isRequired = false,
    ) {
        companion object {
            val Regex = Regex(
                pattern = "[A-Z0-9+._%\\-]{1,256}" +
                    "@" +
                    "[A-Z0-9][A-Z0-9\\-]{0,64}" +
                    "(" +
                    "\\." +
                    "[A-Z0-9][A-Z0-9\\-]{0,25}" +
                    ")+",
                option = RegexOption.IGNORE_CASE
            )
        }
    }

    data class City(
        override val value: CheckoutForm.City?,
        override val items: List<CheckoutForm.City>,
        override val isLoading: Boolean = false,
    ) : InputField<CheckoutForm.City?>(
        id = "city",
        type = Type.Text,
        value = value,
    )

    data class District(
        override val value: CheckoutForm.District?,
        override val items: List<CheckoutForm.District>,
    ) : InputField<CheckoutForm.District?>(
        id = "district",
        type = Type.Text,
        value = value,
    )

    data class Address(
        override val value: String,
    ) : InputField<String>(
        id = "address",
        type = Type.Text,
        value = value,
        multiline = true,
    )

    data class Notes(
        override val value: String,
    ) : InputField<String>(
        id = "notes",
        type = Type.Text,
        value = value,
        isRequired = false,
        multiline = true,
    )
}
