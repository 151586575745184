package com.taager.dukan.feature.quicklink.tracking

import com.taager.tracking.AppTracker
import com.taager.tracking.event.BaseTrackingEvent
import com.taager.tracking.event.Screen
import com.taager.tracking.event.ScreenViewTrackingEvent

private object QuickLinkScreen : Screen(name = "quick_link")

internal fun AppTracker.trackScreenView(quickLink: String) {
    trackEvent(
        ScreenViewTrackingEvent(
            screen = QuickLinkScreen,
            extras = mapOf("quick_link" to quickLink)
        )
    )
}

internal fun AppTracker.trackBackClick(quickLink: String) {
    trackEvent(
        BaseTrackingEvent(
            screen = QuickLinkScreen,
            event = "back_click",
            extras = mapOf("quick_link" to quickLink)
        )
    )
}

internal fun AppTracker.trackEmailClick(
    quickLink: String,
    email: String
) {
    trackEvent(
        BaseTrackingEvent(
            screen = QuickLinkScreen,
            event = "email_click",
            extras = mapOf(
                "quick_link" to quickLink,
                "email" to email,
            )
        )
    )
}
