package com.taager.dukan.product.data.di

import com.taager.dukan.product.data.ProductRepositoryImpl
import com.taager.dukan.product.data.remote.ProductApi
import com.taager.dukan.product.domain.ProductRepository
import org.kodein.di.DI
import org.kodein.di.bindProvider
import org.kodein.di.instance

object ProductDataDI {
    val module = DI.Module("Product Data DI") {
        bindProvider {
            ProductApi(
                client = instance()
            )
        }
        bindProvider<ProductRepository> {
            ProductRepositoryImpl(
                api = instance(),
                translator = instance(),
            )
        }
    }
}
