package com.taager.dukan.order.data

import com.taager.dukan.order.data.remote.OrderApi
import com.taager.dukan.order.data.remote.mapper.transform
import com.taager.dukan.order.domain.OrderRepository
import com.taager.dukan.order.domain.entity.PlaceableOrder

internal class OrderRepositoryImpl(
    private val api: OrderApi,
) : OrderRepository {

    override suspend fun placeOrder(placeableOrder: PlaceableOrder) {
        api.placeOrder(orderRequest = placeableOrder.transform())
    }

    override suspend fun placePaidOrder(paymentId: String) {
        api.placePaidOrder(orderRequest = transform(paymentId))
    }
}
